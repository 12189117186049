/**
 * @fileoverview 管辖部门 -- 查询部门下人员
 * @author  蒋西园
 */
import React, { Component } from "react";
import { Table, Modal } from "antd";
import "./index.less";

class tableList extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "工号",
        dataIndex: "staffCode",
        render: (text, record) => (
          <span
            onClick={this.handleStaffDetail.bind(
              this,
              record.staffId,
              this.props.departmentIdList
            )}
            style={{ color: "#4D91FA", cursor: "pointer" }}
          >
            {text}
          </span>
        ),
      },
      {
        title: "姓名",
        dataIndex: "staffName",
      },
      {
        title: "部门",
        dataIndex: "departmentName",
        render: (text) => (text ? text : "--"),
      },
      {
        title: "岗位",
        dataIndex: "postName",
      },
      {
        title: "工作城市",
        dataIndex: "workCity",
      },
      {
        title: "是否试用期",
        dataIndex: "probation",
        render: (text) => (text ? "是" : "否"),
      },
      {
        title: "入职时间",
        dataIndex: "inductionTime",
        sorter: true,
        render: (text) =>
          text.replace("-", "/").replace("-", "/"),
      },
      {
        title: "操作",
        render: (record) => {
          return (
            <span>
              {record.probation &&
                !record.leaved &&
                !record.positive && (
                  <span
                    onClick={this.handleBecomeRegularWorker.bind(
                      this,
                      record.staffId
                    )}
                    className="option-btn"
                  >
                    转正
                  </span>
                )}
              {!record.leaved && !record.autoQuit && (
                <span
                  onClick={this.handleSelfSeparation.bind(
                    this,
                    record
                  )}
                  className="option-btn"
                >
                  自离
                </span>
              )}
            </span>
          );
        },
      },
    ];
  }
  // 跳转详情
  handleStaffDetail = (staffId, departmentIdList) => {
    sessionStorage.jurisdictionDepartmentIds = departmentIdList;
    window.open(
      `/personnelMatters/detail?staffId=${staffId}`
    );
    // http://localhost:8090/workbench/personnelMatters/detail?staffId=816675124957810688
    //localhost:8090/personnelMatters/detail
    // this.props.history.push({
    //   pathname: `/personnelMatters/detail`,
    //   state: { staffId },
    // });
  };
  /**
   * 函数描述: 转正
   * @param {object} record 该账号所有信息 -- 后期要精简参数更换为唯一标识
   * @return {Object} 二次确认后统一返回父组件处理请求
   */
  handleBecomeRegularWorker = (record) => {
    // this.props.history.push({ pathname: `becomeWorker`, state: { positiveProcId: record.staffId } });
    // this.props.history.push(`becomeWorker?staffId=${record}`);
    window.open(`becomeWorker?staffId=${record}`);
  };
  /**
   * 函数描述: 自离
   * @param {object} record 该账号所有信息 -- 后期要精简参数更换为唯一标识
   * @return {Object} 二次确认后统一返回父组件处理请求
   */
  handleSelfSeparation = (record) => {
    console.log("record ->", record);
    // this.props.history.push({ pathname: `autoQuit`, state: { quitProcId: record.staffId } });
    // this.props.history.push(`autoQuit?staffId=${record}`);
    window.open(`autoQuit?staffId=${record.staffId}`);
  };
  render() {
    return (
      <Table
        rowKey="staffId"
        loading={this.props.loading}
        columns={this.columns}
        dataSource={this.props.data}
        pagination={{
          hideOnSinglePage: true,
          current: this.props.pageNo,
          total: this.props.total,
          pageSize: this.props.pageSize || 10,
        }}
        locale={{
          emptyText: (
            <div>
              <img
                style={{ width: 182, height: 94 }}
                src={require("../../../../../assets/image/no-data.png")}
                alt=""
              />
              <p
                style={{
                  fontSize: 14,
                  color: "#717E99",
                  lineHeight: "20px",
                }}
              >
                暂无数据
              </p>
            </div>
          ),
        }}
        onChange={(pageNo, {}, sorter) =>
          this.props.handleChangePage(pageNo, sorter)
        }
      />
    );
  }
}
export default tableList;
