import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Form,
  Select,
  Row,
  Col,
  Button,
  Input,
  Upload,
  message,
  Radio,
  DatePicker,
  Modal,
  Alert,
  Icon,
  Tooltip,
} from "antd";
import "./index.less";
import ProcessGuide from "../../../components/processGuide";
import AddOfferApproval from "./component/addOfferApproval";
import AuditRecord from "../../../components/auditRecord";
import Withdraw from "../../../components/withdraw"; // 撤回
import { getQueryVariable } from "../../../utils/common";
import utils from "../../../utils/utils";
import moment from "moment";
const InputGroup = Input.Group;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { confirm } = Modal;

const onCampus = {
  0: "否",
  1: "是",
};
const companySigned = {
  0: "否",
  1: "是",
};
const deptHead = {
  0: "否",
  1: "是",
};
const UNIT = [
  {
    key: 1,
    label: "元",
  },
  {
    key: 2,
    label: "元/人天",
  },
];

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: utils.localDb.get("userData").staffId,
      staffName: utils.localDb.get("userData").staffName,
      avatarUrl: utils.localDb.get("userData").avatarUrl,

      showType: 1, //1、表单，2、详情
      // 利率字段显示 0-不显示 1-显示表单 2-显示内容
      showRate: 0,
      employeeInformation: {
        staffName: "姓名:",
        papersTypeName: "证件类型:",
        papersNumber: "证件号码:",
        telephone: "手机号:",
        sexName: "性别:",
        sourceOfDitchName: "渠道来源:",
        attachmentKey: "简历:",
        interviewEstimateCustom: "面试评价:",
        offerRemark: "备注:",
      },
      jobInformation: {
        departmentName: "部门名称:",
        positionName: "岗位:",
        rankName: "职级:",
        hireDateStr: "入职时间:",
        positiveTimeStr: "转正时间:",
        expireOfContractStr: "合同到期日:",
        workProvinceName: "工作城市:",
        contractName: "合同性质:",
        onCampus: "是否校招:",
        companySigned: "是否其他公司转签:",
        deptHead: "是否部门负责人:",
      },
      salaryAndWelfare: {
        salaryType: "薪资类型",
        trySalary: "试用期薪资:",
        regularSalary: "转正薪资:",
        durationProbation: "试用期时长:",
        socialProvinceName: "社保缴纳地:",
        socialInsuranceBase: "社保基数:",
        accumulationFundBase: "公积金基数:",
      },
      salaryAndWelfareIsLabor: {
        salaryType: "薪资类型",
        salaryNumber: "薪资",
      },
      interestRate: {
        rate: "Rate:",
        grossMargin: "当前毛利率:",
        outBonusGrossMargin: "不加年终奖毛利率:",
        socialGrossMargin: "全额社保毛利率:",
      },
      submitDisabled: false,
      offerDetail: {},
      logList: [],
    };
  }
  componentDidMount() {
    console.log(this.props.history.location.searchVale);
    if (getQueryVariable("offerProcId")) {
      this.getOfferByOfferId();
      this.queryProcessLogList();
    } else {
      this.setState({
        showType: 1,
        logList: [],
      });
    }
  }

  // offer详情
  getOfferByOfferId = () => {
    this.$http["getOfferByOfferId"]({
      offerProcId: getQueryVariable("offerProcId"),
    })
      .then((res) => {
        if (res.code == 200) {
          let showType = 2;
          let showWithdraw = false;
          let showOpinion = false;
          let showPass = false;
          let showComplete = false;
          let showRate = 0;

          let step2 = false;
          let step3 = false;
          let step4 = false;
          let step5 = false;

          let taskDefKey = res.data.processData
            ? res.data.processData.taskDefKey
            : "";
          // 保存状态
          if (res.data.procStatus === "STORAGE") {
            showType = 1;
          }
          if (
            res.data.procStatus == "REFUSE" ||
            res.data.procStatus == "RECALL"
          ) {
            if (res.data.creatorId == this.state.staffId) {
              showType = 1;
            }
          }
          if (res.data.procStatus == "AUDIT") {
            if (res.data.creatorId == this.state.staffId) {
              // 撤回操作
              showWithdraw = true;
            }
            // 审核状态
            if (res.data.auditId == this.state.staffId) {
              //
              if (taskDefKey === "OFF0002") {
                // 第二步
                step2 = true;
              }
              if (taskDefKey === "OFF0003") {
                // 第三步
                step3 = true;
              }
              if (taskDefKey === "OFF0004") {
                // 第四步
                step4 = true;
              }
              if (taskDefKey === "OFF0005") {
                // 第五步
                step5 = true;
                showComplete = true;
              }
            }
            if (res.data.showRate) {
              showRate = 1;
            }
          }
          if (res.data.procStatus == "COMPLETE") {
            // 完成状态，归档
            showComplete = false;
            if (res.data.showRate) {
              showRate = 1;
            }
          }

          // 抄送的详情没有操作权限
          if (getQueryVariable("copyToFlag") == 1) {
            step2 = false;
            step3 = false;
            step4 = false;
            step5 = false;
          }
          this.setState({
            offerDetail: res.data || {},
            showType,
            showWithdraw,
            showOpinion,
            showPass,
            showComplete,
            showRate,
            step2,
            step3,
            step4,
            step5,
          });
        } else {
        }
      })
      .catch((err) => {});
  };
  //流程日志
  queryProcessLogList = () => {
    this.$http["queryProcessLogList"]({
      businessKey: getQueryVariable("offerProcId"),
      procTemplate: getQueryVariable("procTemplate"),
    })
      .then((res) => {
        if (res.code == 200) {
          this.setState({
            logList: res.data || [],
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  handleDelete = () => {
    const self = this;
    confirm({
      title: "删除",
      content: "您确定要删除该工作流吗？",
      onOk() {
        self.$http["deleteByOfferId"]({
          offerProcId: getQueryVariable("offerProcId"),
        })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              self.props.history.go(-1);
            } else {
            }
          })
          .catch((err) => {});
      },
      onCancel() {},
    });
  };
  // 审核通过
  handlePass = () => {
    const { form } = this.props;
    console.log("handlePass");
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          submitDisabled: true,
        });
        values.rmbStatus =
          values.rmbStatus && values.rmbStatus.key;
        this.$http["pass"]({
          offerProcId: getQueryVariable("offerProcId"),
          taskId: this.state.offerDetail.processData.taskId,
          ...values,
        })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              this.props.history.go(-1);
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              submitDisabled: false,
            });
          });
      }
    });
  };
  // 不通过
  handleFail = () => {
    const self = this;
    const { form } = this.props;
    const { offerDetail } = this.state;
    if (!form.getFieldValue("auditOpinion").trim()) {
      message.destroy();
      message.warning("请在审核意见中填写退回原因");
      document.getElementById("auditOpinion").focus();
      return;
    }
    confirm({
      icon: (
        <Icon
          type="question-circle"
          style={{ color: "#4D91FA" }}
        />
      ),
      title: "您确定退回该工作流吗？",
      content: "",
      onOk() {
        self.$http["refuse"]({
          offerProcId: getQueryVariable("offerProcId"),
          taskId: offerDetail.processData.taskId,
          auditOpinion: form
            .getFieldValue("auditOpinion")
            .trim(),
        })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              self.props.history.go(-1);
            } else {
            }
          })
          .catch((err) => {});
      },
      onCancel() {},
    });
    // form.validateFieldsAndScroll((err, values) => {
    //   if (!err) {
    //     confirm({
    //       icon: <Icon type="question-circle" style={{ color: "#4D91FA" }} />,
    //       title: "您确定退回该工作流吗？",
    //       content: "",
    //       onOk() {
    //         self.$http["refuse"]({
    //           offerProcId: getQueryVariable("offerProcId"),
    //           taskId: offerDetail.processData.taskId,
    //           ...values
    //         })
    //           .then(res => {
    //             if (res.code === 200) {
    //               // window.close();
    //               self.props.history.go(-1);
    //             } else {
    //             }
    //           })
    //           .catch(err => {});
    //       },
    //       onCancel() {
    //         console.log("Cancel");
    //       }
    //     });
    //   }
    // });
  };

  // 提交、保存按钮
  handleSubmit = (type) => {
    this.setState(
      {
        submitType: type,
      },
      () => {
        // this.props.form.validateFieldsAndScroll((err, values) => {
        //   console.log(values);
        // });
        this.refs.addForm.handleValidate();
      }
    );
  };

  // offer提交、保存
  handlePush = (values) => {
    const { offerDetail } = this.state;
    this.setState({
      submitDisabled: true,
    });

    if (!values.workProvinceName || !values.workCityName) {
      values.workProvinceName =
        offerDetail.workProvinceName;
      values.workCityName = offerDetail.workCityName;
    }
    if (
      !values.socialProvinceName ||
      !values.socialCityName
    ) {
      values.socialProvinceName =
        offerDetail.socialProvinceName;
      values.socialCityName = offerDetail.socialCityName;
    }
    const params = {
      ...values,
      procStatus: this.state.submitType || "INITIATE",
    };
    if (getQueryVariable("offerProcId")) {
      params.offerProcId = getQueryVariable("offerProcId");
    }
    this.$http[
      getQueryVariable("offerProcId")
        ? "updateByOfferId"
        : "addOfferProcess"
    ](params)
      .then((res) => {
        if (res.code === 200) {
          this.setState({
            submitDisabled: false,
          });
          this.props.history.push("/workbench/launch");
          // if (this.state.submitType == "INITIATE") {
          //   this.props.history.push("/workbench/launch");
          // }
        } else {
          this.setState({
            submitDisabled: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          submitDisabled: false,
        });
      });
  };

  handleOk = () => {
    this.props.history.go(-1);
  };
  validatorOpinion = (rule, value, callback) => {
    if (value.trim().length > 128) {
      callback("长度限制128");
    } else {
      callback();
    }
  };
  validatorMoney = (rule, value, callback) => {
    const reg = /^-?[0-9]+(.[0-9]{1,2})?$/;
    if (value && value.trim() && !reg.test(value.trim())) {
      callback("小于999999 ，两位小数");
    } else {
      if (Number(value.trim()) > 999999) {
        callback("小于999999 ，两位小数");
      } else {
        callback();
      }
    }
  };

  render() {
    const {
      employeeInformation,
      jobInformation,
      salaryAndWelfare,
      salaryAndWelfareIsLabor,
      interestRate,
      offerDetail,
      logList,
    } = this.state;

    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    };
    // 显示审核结果，判断右侧记录第一个的状态operStatus，有的话就显示第一个，没有的话显示第二个
    let log = {};
    if (logList.length > 1) {
      if (logList[0].operStatus) {
        log = logList[0];
      } else {
        log = logList[1];
      }
    }

    return (
      <div className="offer-approval">
        <div className="approval-title">
          <span>
            offer审批流程
            {this.state.showType == 1 && <span>-新建</span>}
            {offerDetail.procStatus == "AUDIT" ? (
              offerDetail.processData ? (
                <span>{`-${offerDetail.processData.taskName}`}</span>
              ) : null
            ) : null}
          </span>
          <ProcessGuide
            title="offer审批流程"
            temp="OFFER_PROC"
          />
        </div>
        <div className="approval-wrap">
          <div className="wrap-left" style={{ flex: 1 }}>
            {log && log.operDescription ? (
              <div>
                {log.operStatus == "PASS" && (
                  <Alert
                    message={`${log.taskName}通过`}
                    description={log.operDescription}
                    type="success"
                    showIcon
                    style={{ marginBottom: 12 }}
                  />
                )}
                {log.operStatus == "REFUSE" && (
                  <Alert
                    message={
                      <span className="alert-process-refuse">{`${log.taskName}不通过`}</span>
                    }
                    description={
                      <span className="alert-process-desc">
                        {log.operDescription}
                      </span>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="close-circle"
                        style={{
                          fontSize: 18,
                          color: "#E5537B",
                          top: "18px",
                        }}
                      />
                    }
                    style={{ marginBottom: 12 }}
                  />
                )}
              </div>
            ) : null}

            {this.state.showType == 1 && (
              <AddOfferApproval
                offerDetail={offerDetail}
                form={form}
                ref="addForm"
                handlePush={this.handlePush}
              />
            )}
            {this.state.showType == 2 && (
              <Form
                {...formItemLayout}
                className="process-form-readonly"
                style={{ height: "100%" }}
              >
                <Row className="wrap-block">
                  <div className="block-title">
                    员工信息
                  </div>
                  <Row>
                    {Object.keys(employeeInformation).map(
                      (item) => {
                        if (item === "attachmentKey") {
                          return (
                            <Col span={24}>
                              <FormItem
                                label={
                                  employeeInformation[item]
                                }
                                labelCol={{ span: 2 }}
                                wrapperCol={{ span: 14 }}
                                className="attachmentKey-form-item"
                              >
                                <a
                                  onClick={() => {
                                    window.open(
                                      offerDetail.attachmentKeyUrl
                                    );
                                  }}
                                >
                                  {offerDetail[item].substr(
                                    offerDetail[
                                      item
                                    ].lastIndexOf("/") + 1
                                  )}
                                </a>
                              </FormItem>
                            </Col>
                          );
                        } else if (
                          item === "interviewEstimateCustom"
                        ) {
                          return (
                            <Col span={24}>
                              <FormItem
                                label={
                                  employeeInformation[item]
                                }
                                labelCol={{ span: 2 }}
                                wrapperCol={{ span: 20 }}
                                className="interview-form-item"
                              >
                                <div className="interview-wrap">
                                  {offerDetail.interviewEstimateDicList &&
                                    offerDetail.interviewEstimateDicList.map(
                                      (item) => {
                                        return (
                                          <span
                                            key={item.key}
                                            className="interview-block interview-active"
                                          >
                                            {item.value}
                                          </span>
                                        );
                                      }
                                    )}
                                </div>
                                <span
                                  id="interviewEstimateCustom"
                                  style={{
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {offerDetail[item] ||
                                    "--"}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        } else if (item == "offerRemark") {
                          return (
                            <Col span={24}>
                              <FormItem
                                label={
                                  employeeInformation[item]
                                }
                                labelCol={{ span: 2 }}
                                wrapperCol={{ span: 20 }}
                                className="remark-form-item"
                              >
                                <span
                                  id="offerRemark"
                                  style={{
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {offerDetail[item] ||
                                    "--"}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        } else if (item == "staffName") {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={
                                  employeeInformation[item]
                                }
                              >
                                <span>
                                  {offerDetail[item]
                                    .length > 10 ? (
                                    <Tooltip
                                      title={
                                        offerDetail[item]
                                      }
                                    >
                                      <span>
                                        {offerDetail[
                                          item
                                        ].substr(0, 10)}
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <span>
                                      {offerDetail[item]}
                                    </span>
                                  )}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        } else {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={
                                  employeeInformation[item]
                                }
                              >
                                <span>
                                  {offerDetail[item] ||
                                    "--"}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        }
                      }
                    )}
                  </Row>
                </Row>

                <Row className="wrap-block">
                  <div className="block-title">
                    岗位信息
                  </div>
                  <Row>
                    {Object.keys(jobInformation).map(
                      (item) => {
                        if (item == "companySigned") {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={jobInformation[item]}
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                              >
                                <span>
                                  {offerDetail[item] != null
                                    ? companySigned[
                                        offerDetail[item]
                                      ]
                                    : "--"}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        } else if (item == "onCampus") {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={jobInformation[item]}
                              >
                                <span>
                                  {offerDetail[item] != null
                                    ? onCampus[
                                        offerDetail[item]
                                      ]
                                    : "--"}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        } else if (item == "deptHead") {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={jobInformation[item]}
                              >
                                <span>
                                  {offerDetail[item] != null
                                    ? deptHead[
                                        offerDetail[item]
                                      ]
                                    : "--"}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        } else if (
                          item == "workProvinceName"
                        ) {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={jobInformation[item]}
                              >
                                <span>
                                  {offerDetail[item] != null
                                    ? `${offerDetail[item]}${offerDetail.workCityName}`
                                    : "--"}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        } else {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={jobInformation[item]}
                              >
                                <span>
                                  {offerDetail[item] ||
                                    "--"}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        }
                      }
                    )}
                  </Row>
                </Row>
                <Row className="wrap-block">
                  <div className="block-title">
                    薪资福利
                  </div>
                  {offerDetail.contractType === "LABOR" ? (
                    <Row>
                      {Object.keys(salaryAndWelfare).map(
                        (item) => {
                          if (item === "salaryType") {
                            return (
                              <Col span={8}>
                                <FormItem
                                  label={
                                    salaryAndWelfare[item]
                                  }
                                >
                                  <span>
                                    {offerDetail[item]
                                      ? `月薪`
                                      : "--"}
                                  </span>
                                </FormItem>
                              </Col>
                            );
                          } else if (
                            item == "trySalary" ||
                            item == "regularSalary" ||
                            item == "socialInsuranceBase" ||
                            item == "accumulationFundBase"
                          ) {
                            return (
                              <Col span={8}>
                                <FormItem
                                  label={
                                    salaryAndWelfare[item]
                                  }
                                >
                                  <span>
                                    {offerDetail[item]
                                      ? `¥ ${offerDetail[item]}元`
                                      : "--"}
                                  </span>
                                </FormItem>
                              </Col>
                            );
                          } else if (
                            item == "durationProbation"
                          ) {
                            return (
                              <Col span={8}>
                                <FormItem
                                  label={
                                    salaryAndWelfare[item]
                                  }
                                >
                                  <span>
                                    {offerDetail[item] ||
                                      "--"}{" "}
                                    个月
                                  </span>
                                </FormItem>
                              </Col>
                            );
                          } else if (
                            item == "socialProvinceName"
                          ) {
                            return (
                              <Col span={8}>
                                <FormItem
                                  label={
                                    salaryAndWelfare[item]
                                  }
                                >
                                  <span>
                                    {offerDetail[item]
                                      ? `${offerDetail[item]}${offerDetail.socialCityName}`
                                      : "--"}
                                  </span>
                                </FormItem>
                              </Col>
                            );
                          } else {
                            return (
                              <Col span={8}>
                                <FormItem
                                  label={
                                    salaryAndWelfare[item]
                                  }
                                >
                                  <span>
                                    {offerDetail[item] ||
                                      "--"}
                                  </span>
                                </FormItem>
                              </Col>
                            );
                          }
                        }
                      )}
                    </Row>
                  ) : (
                    <Row>
                      {Object.keys(
                        salaryAndWelfareIsLabor
                      ).map((item) => {
                        if (item === "salaryType") {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={
                                  salaryAndWelfareIsLabor[
                                    item
                                  ]
                                }
                              >
                                <span>
                                  {offerDetail[item] === 1
                                    ? `日薪`
                                    : "月薪"}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        } else {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={
                                  salaryAndWelfareIsLabor[
                                    item
                                  ]
                                }
                              >
                                <span>
                                  {offerDetail[item]
                                    ? `¥ ${offerDetail[item]}元`
                                    : "--"}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  )}
                </Row>
                {this.state.step3 ? (
                  this.state.showRate ? (
                    <Row className="wrap-block">
                      <div className="block-title">
                        利率
                      </div>
                      <Row>
                        <Col span={8}>
                          <FormItem
                            label="Rate"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                          >
                            <InputGroup compact>
                              {getFieldDecorator("rate", {
                                initialValue: "",
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message: "Rate必填",
                                  },
                                  {
                                    validator: this
                                      .validatorMoney,
                                  },
                                ],
                              })(
                                <Input
                                  placeholder="请输入Rate"
                                  allowClear
                                  style={{ width: "55%" }}
                                />
                              )}
                              {getFieldDecorator(
                                "rmbStatus",
                                {
                                  initialValue: UNIT[0],
                                  rules: [
                                    {
                                      required: true,
                                      message: "单位必填",
                                    },
                                  ],
                                }
                              )(
                                <Select
                                  placeholder="请选择单位"
                                  labelInValue
                                  style={{ width: "45%" }}
                                  getPopupContainer={(
                                    triggerNode
                                  ) =>
                                    triggerNode.parentElement
                                  }
                                >
                                  {UNIT.map((item) => {
                                    return (
                                      <Option
                                        key={item.key}
                                        value={item.key}
                                      >
                                        {item.label}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </InputGroup>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="当前毛利率(%)"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                          >
                            {getFieldDecorator(
                              "grossMargin",
                              {
                                initialValue: "",
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "当前毛利率必填",
                                  },
                                  {
                                    validator: this
                                      .validatorMoney,
                                  },
                                ],
                              }
                            )(
                              <Input
                                placeholder="请输入当前毛利率"
                                allowClear
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="不加年终奖毛利率(%)"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                          >
                            {getFieldDecorator(
                              "outBonusGrossMargin",
                              {
                                initialValue: "",
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "不加年终奖毛利率必填",
                                  },
                                  {
                                    validator: this
                                      .validatorMoney,
                                  },
                                ],
                              }
                            )(
                              <Input
                                placeholder="请输入不加年终奖毛利率"
                                allowClear
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <FormItem
                            label="全额社保毛利率(%)"
                            labelCol={{ span: 12 }}
                            wrapperCol={{ span: 12 }}
                          >
                            {getFieldDecorator(
                              "socialGrossMargin",
                              {
                                initialValue: "",
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "全额社保毛利率必填",
                                  },
                                  {
                                    validator: this
                                      .validatorMoney,
                                  },
                                ],
                              }
                            )(
                              <Input
                                placeholder="请输入全额社保毛利率"
                                allowClear
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Row>
                  ) : null
                ) : this.state.showRate ? (
                  <Row className="wrap-block">
                    <div className="block-title">利率</div>
                    <Row>
                      {Object.keys(interestRate).map(
                        (item) => {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={interestRate[item]}
                              >
                                {
                                  (console.log(
                                    "item ->",
                                    item
                                  ),
                                  console.log(
                                    "offerDetail ->",
                                    offerDetail
                                  ))
                                }
                                <span>
                                  {offerDetail[item] ||
                                    "--"}
                                  {item == "rate"
                                    ? offerDetail[
                                        "rmbStatus"
                                      ] &&
                                      offerDetail[
                                        "rmbStatus"
                                      ] === 2
                                      ? "元/人天"
                                      : "元"
                                    : "%"}
                                  {/* {item != "rate" && "%"} */}
                                  {/* {offerDetail[
                                    "rmbStatus"
                                  ] &&
                                    (offerDetail[
                                      "rmbStatus"
                                    ] === 2
                                      ? "元/人天"
                                      : "元")} */}
                                </span>
                              </FormItem>
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </Row>
                ) : null}

                {(this.state.step2 ||
                  this.state.step3 ||
                  this.state.step4) && (
                  <Row
                    className="wrap-block"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="block-title">
                      流程审核
                    </div>
                    <Row>
                      <FormItem
                        label="审核意见"
                        labelCol={{ span: 2 }}
                        style={{ marginLeft: 16 }}
                      >
                        {getFieldDecorator("auditOpinion", {
                          initialValue: "",
                          rules: [
                            {
                              validator: this
                                .validatorOpinion,
                            },
                          ],
                        })(
                          <TextArea
                            rows={4}
                            maxLength={128}
                            placeholder="请输入审核意见"
                            allowClear
                          />
                        )}
                      </FormItem>
                    </Row>
                  </Row>
                )}
              </Form>
            )}
          </div>
          <div className="wrap-right">
            <AuditRecord data={logList || []} />
          </div>
        </div>
        <div className="footer-fixed-wrap">
          <div className="footer-fixed-btn">
            {this.state.showType == 1 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // if (getQueryVariable("offerProcId")) {
                    //   window.close();
                    // }
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() =>
                    this.handleSubmit("INITIATE")
                  }
                  disabled={this.state.submitDisabled}
                >
                  提交
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() =>
                    this.handleSubmit("STORAGE")
                  }
                  disabled={this.state.submitDisabled}
                >
                  保存
                </Button>
                {(offerDetail.procStatus === "STORAGE" ||
                  offerDetail.procStatus === "REFUSE") && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={this.handleDelete}
                  >
                    删除
                  </Button>
                )}
              </div>
            )}
            {this.state.showType == 2 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // window.close();
                    if (
                      this.props.history.location.searchVale
                    ) {
                      this.props.history.push({
                        pathname: "/workbench/staffEntry",
                        searchVale: this.props.history
                          .location.searchVale,
                      });
                    } else {
                      this.props.history.go(-1);
                    }
                  }}
                >
                  返回
                </Button>
                {/* {this.state.showWithdraw && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      this.setState(
                        {
                          visible: true
                        },
                        () => {
                          this.refs.withdrawRef.handleShow();
                        }
                      );
                    }}
                  >
                    撤回
                  </Button>
                )} */}
                {(this.state.step2 ||
                  this.state.step3 ||
                  this.state.step4) && (
                  <span>
                    <Button
                      type="primary"
                      style={{ marginRight: 16 }}
                      onClick={this.handlePass}
                      disabled={this.state.submitDisabled}
                    >
                      通过
                    </Button>
                    <Button
                      type="danger"
                      style={{ marginRight: 16 }}
                      onClick={this.handleFail}
                    >
                      不通过
                    </Button>
                  </span>
                )}
                {this.state.step5 && (
                  <Button
                    type="primary"
                    style={{ marginRight: 16 }}
                    onClick={this.handlePass}
                    disabled={this.state.submitDisabled}
                  >
                    归档
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        {this.state.visible && (
          <Withdraw
            ref="withdrawRef"
            form={this.props.form}
            data={{
              offerProcId: offerDetail.offerProcId,
              taskId: offerDetail.processData.taskId,
            }}
            url="withdraw"
            handleOk={this.handleOk}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Form.create()(MainManagement));
