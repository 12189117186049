/**
 * @fileoverview 个人中心---个人信息
 * @author  蒋西园
 */
import React, { Component } from 'react';
import './index.less';
import { withRouter } from 'react-router-dom';
import { Upload, message, Input, Form } from 'antd';
import defaultHead from '../../../assets/image/defaultHead.png';
import editPersonal from '../../../assets/image/edit-personal.png';
import canclePersonal from '../../../assets/image/cancle-personal.png';
import surePersonal from '../../../assets/image/sure-personal.png';
import Api from '../../../api/apis';
import http from '../../../api/fetch';
import util from '../../../utils/utils';
const FormItem = Form.Item;

class PersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarHead: '', // 用户头像
      phoneInputShow: false, // 是否显示手机号输入框
      phoneInputValue: '', // 修改后的手机号
      emailInputShow: false, // 是否显示邮箱输入框
      emailInputValue: '', // 修改后的邮箱
      information: null,
    };
  }
  componentDidMount() {
    this.queryForPersonal();
  }
  queryForPersonal() {
    console.log(util.localDb.get('userData'));
    let userData = util.localDb.get('userData');
    Api.queryForPersonal({})
      .then(res => {
        if (res.code === 200) {
          console.log(res);
          this.setState({
            information: res.data,
            avatarHead: res.data.avatarUrl,
          });
          userData.staffId = res.data.staffId;
          userData.staffCode = res.data.staffCode;
          userData.staffName = res.data.staffName;
          userData.avatarUrl = res.data.avatarUrl;
          util.localDb.set('userData', userData);
          document.getElementById('aLink').click();
        } else {
          message.warning(res.message);
        }
        // this.setCookie('JSESSIONID', res.sessionKey, 7)
      })
      .catch(err => {});
  }

  beforeUpload = file => {
    const self = this;
  };
  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    let _bool = true;
    if (!isJpgOrPng) {
      message.error('图片格式只支持jpg、jpeg、png!');
      _bool = false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('图片大小不超过 2MB!');
      _bool = false;
    }
    if (!_bool) {
      return false;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // 使用ossupload覆盖默认的上传方法
      http.uploadFile(file, file.name).then(data => {
        let fileList = [{ url: data.url, uid: data.key, name: data.key }];
        this.setState({
          avatarHead: fileList[0].url,
          avatarHeadID: fileList[0].uid,
        });
        // this.triggerChange(fileList);//只返回id
        Api.updateStaffForPersonal({
          avatar: fileList[0].uid,
        })
          .then(res => {
            if (res.code === 200) {
              message.success('修改成功！');

              this.queryForPersonal();
            } else {
              message.warning(res.message);
            }
            // this.setCookie('JSESSIONID', res.sessionKey, 7)
          })
          .catch(err => {});
      });
    };
    return false; // 不调用默认的上传方法
  };
  handleShowInput = type => {
    const { information } = this.state;
    if (type === 'phone') {
      this.setState({
        phoneInputValue: information.phone,
        phoneInputShow: true,
      });
    } else if (type === 'email') {
      this.setState({
        emailInputValue: information.email,
        emailInputShow: true,
      });
    }
  };
  handleHideInput = type => {
    if (type === 'phone') {
      this.setState({
        phoneInputValue: '',
        phoneInputShow: false,
      });
    } else if (type === 'email') {
      this.setState({
        emailInputValue: '',
        emailInputShow: false,
      });
    }
  };

  handleSureInput = type => {
    const { information, phoneInputValue, emailInputValue } = this.state;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'phone') {
          information.phone = phoneInputValue;
          Api.updateStaffForPersonal({
            phone: phoneInputValue,
          })
            .then(res => {
              if (res.code === 200) {
                console.log(res);
                this.setState(
                  {
                    information,
                    phoneInputShow: false,
                  },
                  () => {
                    message.success('修改成功！');
                  }
                );
              } else {
                message.warning(res.message);
              }
              // this.setCookie('JSESSIONID', res.sessionKey, 7)
            })
            .catch(err => {});
        } else if (type === 'email') {
          information.email = emailInputValue;
          Api.updateStaffForPersonal({
            email: emailInputValue,
          })
            .then(res => {
              if (res.code === 200) {
                console.log(res);
                this.setState(
                  {
                    information,
                    emailInputShow: false,
                  },
                  () => {
                    message.success('修改成功！');
                  }
                );
              } else {
                message.warning(res.message);
              }
              // this.setCookie('JSESSIONID', res.sessionKey, 7)
            })
            .catch(err => {});
        }
      }
    });
  };
  onChangeInput = (e, type) => {
    if (type === 'phone') {
      this.setState({
        phoneInputValue: e.target.value,
      });
    } else if (type === 'email') {
      this.setState({
        emailInputValue: e.target.value,
      });
    }
  };
  render() {
    const {
      avatarHead,
      phoneInputShow,
      phoneInputValue,
      emailInputShow,
      emailInputValue,
      information,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="personal-information">
        <div className="crumbs">个人信息</div>
        <div className="information-wrap flex flex-v flex-align-center">
          {/* 头像 */}
          {/* defaultHead */}
          <div className="personal-head">
            <img
              src={avatarHead ? avatarHead : defaultHead}
              alt="personal-head"
            />
          </div>
          <Upload
            name="file"
            accept="image/png, image/jpeg, image/jpg"
            showUploadList={false}
            className="avatar-uploader"
            onChange={this.handleChange}
            beforeUpload={this.beforeUpload}
          >
            <a className="upload-text">上传头像</a>
          </Upload>
          <a href="#" style={{ display: 'none' }} id="aLink"></a>
          <div className="information">
            <li className="information-item">
              <div className="label">姓名：</div>
              <div className="value">
                {information == null ? '' : information.staffName}
              </div>
            </li>
            <li className="information-item">
              <span className="label">手机号码：</span>
              {phoneInputShow ? (
                <div className="input">
                  <FormItem label="" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('phone', {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '请输入手机号',
                        },
                        {
                          pattern: /^1[3456789]\d{9}$/,
                          message: '请输入正确的手机号',
                        },
                      ],
                    })(
                      <Input
                        value={phoneInputValue}
                        onChange={e => this.onChangeInput(e, 'phone')}
                      />
                    )}
                  </FormItem>
                  <img
                    src={canclePersonal}
                    onClick={this.handleHideInput.bind(this, 'phone')}
                    alt="canclePersonal"
                  />
                  <img
                    src={surePersonal}
                    onClick={this.handleSureInput.bind(this, 'phone')}
                    alt="surePersonal"
                  />
                </div>
              ) : (
                <div className="value">
                  {information == null ? '' : information.phone}
                  <img
                    src={editPersonal}
                    onClick={this.handleShowInput.bind(this, 'phone')}
                    alt="editPersonal"
                  />
                </div>
              )}
            </li>
            <li className="information-item">
              <div className="label">邮箱：</div>
              {emailInputShow ? (
                <div className="input">
                  <FormItem label="" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('phone', {
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: '请输入邮箱',
                        },
                        {
                          max: 32,
                          message: '邮箱最大长度为32个字符',
                        },
                        {
                          // pattern: new RegExp(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, "g"),
                          // eslint-disable-next-line no-useless-escape
                          pattern: new RegExp(
                            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi,
                            'g'
                          ),
                          message: '请输入正确的邮箱',
                        },
                      ],
                    })(
                      <Input
                        value={emailInputValue}
                        onChange={e => this.onChangeInput(e, 'email')}
                      />
                    )}
                  </FormItem>
                  <img
                    src={canclePersonal}
                    onClick={this.handleHideInput.bind(this, 'email')}
                    alt="canclePersonal"
                  />
                  <img
                    src={surePersonal}
                    onClick={this.handleSureInput.bind(this, 'email')}
                    alt="surePersonal"
                  />
                </div>
              ) : (
                <div className="value">
                  {information == null ? '' : information.email}
                  <img
                    src={editPersonal}
                    onClick={this.handleShowInput.bind(this, 'email')}
                    alt="editPersonal"
                  />
                </div>
              )}
            </li>
            <li className="information-item">
              <div className="label">账号：</div>
              <div className="value">
                {information == null ? '' : information.staffAccount}
              </div>
            </li>
            <li className="information-item">
              <div className="label">工号：</div>
              <div className="value">
                {information == null ? '' : information.staffCode}
              </div>
            </li>
            <li className="information-item">
              <div className="label">入职日期：</div>
              <div className="value">
                {information == null ? '' : information.onboardingTime}
              </div>
            </li>
            <li className="information-item">
              <div className="label">部门：</div>
              <div className="value">
                {information == null ? '' : information.departmentName}
              </div>
            </li>
            <li className="information-item">
              <div className="label">岗位：</div>
              <div className="value">
                {information == null ? '' : information.postName}
              </div>
            </li>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Form.create()(PersonalInformation));
