/**
 * @fileoverview 系统管理---账号设置 -- 数据列表
 * @author  蒋西园
 */
import React, { Component } from 'react';
import { Table, Modal, Icon, Tabs, Tag } from 'antd';
import './index.less';
const { confirm } = Modal;
const { TabPane } = Tabs;
const tags = {
  1: {
    color: 'green',
    text: '正式',
  },
  2: {
    color: 'gold',
    text: '劳务',
  },
  3: {
    color: 'gold',
    text: '实习',
  },
  4: {
    color: 'gold',
    text: '试用期',
  },
  5: {
    color: 'red',
    text: '未转正',
  },
  6: {
    color: 'gold',
    text: '待续签',
  },
  7: {
    color: 'gold',
    text: '信息未完善',
  },
  8: {
    color: 'red',
    text: '待离职',
  },
};

class tableList extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: '工号',
        dataIndex: 'staffCode',
        width: 80,
      },
      {
        title: '用工类型',
        dataIndex: 'status',
        width: 100,
        render: text =>
          text ? <Tag color={tags[text].color}>{tags[text].text}</Tag> : '--',
      },
      {
        title: '姓名',
        dataIndex: 'staffName',
        ellipsis: true,
        width: 100,
        render: (text, record) => (
          <span
            onClick={this.handleStaffDetail.bind(this, record.staffId)}
            style={{ color: '#4D91FA', cursor: 'pointer' }}
          >
            {text}
          </span>
        ),
      },
      // {
      //   title: '登录账号',
      //   dataIndex: 'staffAccount',
      //   ellipsis: true,
      //   width: 100,
      // },
      {
        title: '部门',
        dataIndex: 'departmentName',
        render: text => (text ? text : '--'),
        width: 120,
      },
      {
        title: '岗位',
        dataIndex: 'postName',
        width: 120,
        render: text => (text ? text : '--'),
      },
      {
        title: '联系方式',
        dataIndex: 'phone',
        width: 120,
        render: text => (text ? text : '--'),
      },
      // {
      //   title: '直属上级',
      //   dataIndex: 'belongDeliverManager',
      //   width: 120,
      //   render: text => (text ? text : '--'),
      // },
      // {
      //   title: '是否离职',
      //   dataIndex: 'isLeave',
      //   width: 100,
      //   render: text => (text ? '离职' : '未离职'),
      // },
      // {
      //   title: '入职时间',
      //   dataIndex: 'onboardingTime',
      //   width: 120,
      //   sorter: true,
      // },
      // {
      //   title: '离职时间',
      //   dataIndex: 'leaveTime',
      //   width: 120,
      //   sorter: true,
      // },
      // {
      //   title: '是否完善',
      //   dataIndex: 'isPerfectName',
      //   width: 100,
      //   render: (text, record) => (
      //     <span
      //       className={`border-span ${text === '0' ? 'isActive' : 'notActive'}`}
      //     >
      //       {text === '0' ? '已完善' : '未完善'}
      //     </span>
      //   ),
      // },
      {
        title: '操作',
        width: 150,
        fixed: 'right',
        render: record => {
          return (
            <span>
              <span
                style={{
                  color: '#4D91FA',
                  cursor: 'pointer',
                }}
                onClick={this.handleStaffDetail.bind(this, record.staffId)}
              >
                详情/编辑
              </span>
              <span
                onClick={this.handleDelete.bind(this, record.staffId)}
                style={{
                  marginLeft: '16px',
                  color: '#4D91FA',
                  cursor: 'pointer',
                }}
              >
                删除
              </span>
            </span>
          );
        },
      },
    ];
  }
  // 跳转详情
  handleStaffDetail = staffId => {
    var example = window.location.protocol;
    var example1 = window.location.host;
    let url =
      example +
      '//' +
      example1 +
      '/personnelMatters/detail' +
      '?staffId=' +
      staffId;
    window.open(url);
    // this.props.history.push({ pathname: `detail`, state: { staffId } });
  };
  handleAddSubmit = staffId => {
    // var example = window.location.protocol;
    // var example1 = window.location.host;
    // let url = example+'//'+example1+'/personnelMatters/editEmployee'+'?staffId='+staffId
    // window.open(url)
    this.props.history.push({
      pathname: 'editEmployee',
      state: { staffId, searchVale: this.props.searchVale },
    });
  };
  // 删除
  handleDelete = staffId => {
    confirm({
      title: '删除',
      content: '您确定要删除该员工吗？',
      icon: <Icon type="delete" style={{ color: '#E5537B' }} />,
      bodyStyle: {
        padding: '33px !important',
      },
      onOk: () => {
        this.props.handleDelete(staffId);
      },
    });
  };

  callback = key => {
    this.props.select(key);
  };
  render() {
    return (
      <>
        <Tabs defaultActiveKey={false} onChange={this.callback}>
          <TabPane tab="在职" key={false}></TabPane>
          <TabPane tab="离职" key={true}></TabPane>
        </Tabs>
        <Table
          rowKey={record => {
            return record.staffId;
          }}
          scroll={{ x: 1000 }}
          loading={this.props.loading}
          columns={this.columns}
          dataSource={this.props.data}
          pagination={{
            hideOnSinglePage: true,
            current: this.props.pageNo,
            total: this.props.total,
            pageSize: this.props.pageSize || 10,
          }}
          locale={{
            emptyText: (
              <div>
                <img
                  style={{ width: 182, height: 94 }}
                  src={require('../../../../../assets/image/no-data.png')}
                  alt=""
                />
                <p
                  style={{
                    fontSize: 14,
                    color: '#717E99',
                    lineHeight: '20px',
                  }}
                >
                  暂无数据
                </p>
              </div>
            ),
          }}
          onChange={(pageNo, {}, sorter) =>
            this.props.handleChangePage(pageNo, sorter)
          }
        />
      </>
    );
  }
}
export default tableList;
