import React, { Component } from "react";

import "./index.less";
import processArr from "./../../../../../assets/image/process-arr.png";
class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeIndex: '',
      configListState: null,
    };
  }
  componentDidMount() {}
  componentWillReceiveProps(next) {
    if (this.props.configListState != next.configList) {
      this.setState({
        configListState: next.configList,
      });
    }
  }
  // 流程节处理
  renderNode = () => {
    const {
      processList,
      checkedProcessItem,
      configList,
    } = this.props;
    const { configListState } = this.state;
    return (processList || []).map((item, index) => {
      if (index === 0) {
        // 配置开始节点
        return (
          <div
            className="piece-wrapper center"
            key={item.dicValue}
          >
            <div className="top ">
              {item.des}人： 不可配置
            </div>
            <div className="bottom">{item.des}</div>
            <img
              className="processArr"
              src={processArr}
              alt="arr"
            />
          </div>
        );
      } else {
        return (
          <div
            key={item.dicValue}
            className={`piece-wrapper center middle-wrapper ${
              checkedProcessItem.dicValue === item.dicValue
                ? "middle-active"
                : ""
            }`}
            onClick={() => this.props.togglePiece(item)}
          >
            <div className="top ">
              处理人：{" "}
              {configListState == null
                ? "未配置"
                : !configListState[item.dicValue]
                ? "未配置"
                : configListState[item.dicValue]
                    .assigneeEcho == "SUPERIOR"
                ? "直属上级"
                : configListState[item.dicValue]
                    .assigneeEcho == "STAFF"
                ? "员工"
                : configListState[item.dicValue]
                    .assigneeEcho == "USER_DEF"
                ? "自定义"
                : configListState[item.dicValue]
                    .assigneeEcho == "DEPT_HEAD"
                ? "指定部分负责人"
                : configListState[item.dicValue]
                    .assigneeEcho == "AGENT"
                ? "直属上级"
                : ""}
            </div>
            <div className="bottom">{item.des}</div>
            <img
              className="processArr"
              src={processArr}
              alt="arr"
            />
          </div>
        );
      }
    });
  };
  render() {
    return (
      <div className="process-wrapper">
        {this.renderNode()}
        <div className="piece-wrapper center">
          <div className="bottom borderTop">结束</div>
        </div>
      </div>
    );
  }
}
export default Process;
