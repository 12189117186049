
import React,{ Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Select, Row, Col } from 'antd';
import './index.less'

const FormItem = Form.Item;
const Option = Select.Option;


class SearchUserlist extends Component{
	constructor(props){
		super(props)
		this.state = {
			content:'Hello, World'
		}
	}
    // 提交表单
    handleSubmit = () => {
        this.props.form.validateFieldsAndScroll((err,values)=>{
            if(err){
                return false
            }
            const data = {};
            // 去除含有undefined的item
            Object.keys(values).forEach(res=>{
                if(values[res]){
                    data[res] = values[res]
                }
            })
            this.props.handleSearchBtn(data);
        })
    }
    handleReset = () => {
        this.props.form.resetFields();
        this.props.handleSearchBtn({});
    }
	render(){
		const { getFieldDecorator } = this.props.form;
		return (
            <div className="search-form">
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
                    <Row gutter={24}>
                        <Col span={5} style={{marginLeft:20}}>
                            <FormItem label="标题">
                                {
                                    getFieldDecorator('title')(
                                        <Input placeholder="请输入" allowClear/>                             
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col span={5} >
                            <FormItem label="状态">
                                {
                                    getFieldDecorator('notificationStatus')(
                                        <Select placeholder="请选择" allowClear>
                                            <Option value="0">待发布</Option>
                                            <Option value="1">已发布</Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col span={5} style={{marginLeft:'-34px'}}>
                            <Button onClick={this.handleReset}>重置</Button>
                            <Button type="primary"  onClick={this.handleSubmit} style={{ marginLeft: 14 }}>查询</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
		)
	}
}

export default  withRouter( Form.create()(SearchUserlist))
