import axios from 'axios';
import { message } from 'antd';
import history from './history';
import utils from '../utils/utils';

const baseUrl = process.env.NODE_ENV === 'development' ? '' : '';
const service = axios.create({
  baseURL: baseUrl,
  timeout: 10000000,
});
const service2 = axios.create({
  baseURL: baseUrl,
  timeout: 10000000,
});

class Http {
  /* eslint-disable */
  get(url, params) {
    // GET请求
    const newUrl = params ? this.build(url, params) : url;
    return this.request(newUrl, {
      method: 'GET',
    });
  }

  post(url, body, hasMessage, responseType, fileBol) {
    // POST请求
    let options = {
      method: 'POST',
    };
    if (body) {
      if (fileBol) {
        options.body = body;
      } else {
        options.body = JSON.stringify(body);
      }
    }
    return this.request(url, options, hasMessage, responseType);
  }
  put(url, body) {
    // PUT请求
    let options = {
      method: 'PUT',
    };
    if (body) options.body = JSON.stringify(body);
    return this.request(url, options);
  }
  delete(url, body) {
    // DELETE请求
    let options = {
      method: 'DELETE',
    };
    if (body) options.body = JSON.stringify(body);
    return this.request(url, options);
  }
  patch(url, body) {
    // PATCH请求
    let options = {
      method: 'patch',
    };
    if (body) options.body = JSON.stringify(body);
    return this.request(url, options);
  }

  downloadFile(url, params) {
    // 下载文件
    const newUrl = params ? this.build(url, params) : url;
    axios
      .get(newUrl, { responseType: 'blob' })
      .then(resp => {
        let headers = resp.headers;
        let contentType = headers['content-type'];
        if (!resp.data) {
          return false;
        } else {
          const blob = new Blob([resp.data], {
            type: contentType,
          });
          const contentDisposition = resp.headers['content-disposition'];
          let fileName = 'unknown';
          if (contentDisposition) {
            fileName = window.decodeURIComponent(
              resp.headers['content-disposition'].split('=')[1]
            );
          }
          this.downFile(blob, fileName);
        }
      })
      .catch(function(error) {});
  }
  downFile(blob, fileName) {
    if (window.navigator.msSaveOrOpenBlob) {
      // IE10+下载
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // 非IE下载
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob); // 创建下载的链接
      link.download = fileName; // 下载后文件名
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click(); // 点击下载
      window.URL.revokeObjectURL(link.href); // 释放掉blob对象
      document.body.removeChild(link); // 下载完成移除元素
    }
  }
  uploadFile(file, val) {
    let suffix = val.substr(val.indexOf('.'));
    let storeAs =
      'file/' +
      utils.get_uuid() +
      '/' +
      val.replace(/[:*|'"!@#$%&<>?\/\\]/g, '');
    // let storeAs = "file/" + new Date() * 1 + suffix;
    // let storeAs = "file/" + new Date() * 1 + suffix+Math.random().toString(36).substr(2);
    return client.multipartUpload(storeAs, file).then(result => {
      let obj = {};
      obj.key = result.name;
      obj.name = result.name.substr(result.name.lastIndexOf('/') + 1);
      obj.uid = result.name;
      obj.url = client.signatureUrl(result.name);
      return Promise.resolve(obj);
    });
  }

  request(url, options, hasMessage, responseType = 'json') {
    console.log('url', url);
    options.headers = this.defaultHeader(); //默认headers
    let serviceSure = service;
    if (url == '/personnel-bsm/pc/staffRoster/import') {
      serviceSure = service2;
    }
    return serviceSure({
      responseType,
      method: options.method,
      url: url,
      data: options.body,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then(function(res) {
        if (res && res.data && res.status === 200) {
          // 未登录跳转到首页
          if (res.data.code === 401) {
            // history.push('/login');
            window.location.href = '/login';
          }
          if (res.data.code === 1002) {
            // history.push('/login');
            window.location.href = '/';
          }
          if (res.data.code === 303) {
            // history.push('/');
            message.warning(res.data.message);
          } else {
            if (hasMessage) {
              if (res.data.code == 200) {
                message.destroy();
                message.success(res.data.message);
              } else {
                message.destroy();
                message.error(res.data.message);
              }
            }
            return Promise.resolve(res.data);
          }
        } else if (responseType === 'blob') {
          return Promise.resolve(res);
        } else {
          return Promise.reject({
            message: '服务器返回错误',
          });
        }
      })
      .catch(err => {
        let data = {};
        if (err.response && err.response.status == 401) {
          history.push('/login');
        }

        if (err.response && err.response.data && err.response.data.message) {
          data = { message: err.response.data.message };
        } else if (err.message) {
          data = err.message;
        } else {
          data = { message: '未知错误' };
        }
        if (err.response && err.response.status == 500) {
          data = { message: '服务暂不可用!' };
          message.destroy();
          message.error(data.message);
        }
        if (err.response && err.response.status == 502) {
          data = { message: '服务暂不可用!' };
          message.destroy();
          message.error(data.message);
        }
        message.destroy();
        // message.error(data.message)
        return Promise.reject(data);
      });
  }

  defaultHeader() {
    // 默认头
    const header = {
      Accept: '*/*',
      'Content-Type': 'application/json',
    };
    return header;
  }

  build(url, params) {
    // URL构建方法
    const ps = [];
    if (params) {
      for (let p in params) {
        if (p) {
          ps.push(p + '=' + encodeURIComponent(params[p]));
        }
      }
    }
    return url + '?' + ps.join('&');
  }

  buildFormData(params) {
    if (params) {
      const data = new FormData();
      for (let p in params) {
        if (p) {
          data.append(p, params[p]);
        }
      }
      return data;
    }
  }
}
/* eslint-disable */
export default new Http();
