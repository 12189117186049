/**
 * @fileoverview 系统管理---组织架构设置
 * @author  蒋西园
 */
import React, { Component } from "react";
import "./index.less";

import { Button, message } from "antd";
import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表
import AddModal from "./component/addAccount"; // 新增或修改组织架构
import apis from "../../../api/apis";

class OrganizationalStructure extends Component {
  constructor(props) {
    super(props);
    // this.child = React.createRef()
    this.state = {
      loading: false,
      dataList: [], // 组织架构列表数据
      total: 100, // 组织架构总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      searchVale: {}, // 搜索条件
      addModalShow: false, // 新增弹框显隐
      modalType: 0, // 0：添加组织架构弹框 1：修改组织架构弹框
      organizationalItem: {}, // 编辑组织架构弹框回显数据
      disabled: false, // 防重复点击
      departmentIdList: [], // 上级部门
      addParentDepartmentList: [],
    };
  }
  componentDidMount() {
    this.queryTableList();
  }
  /**
   * 函数描述: 查询上级部门
   * @param {string} level   层级
   * @param {string} type 	类型（1：项目 2：部门）
   * @return {Object}
   */
  departmentListByParent = (level = "") => {
    this.$http
      .departmentListByParent({
        level,
      })
      .then((res) => {
        this.setState({
          addParentDepartmentList: res.data,
          parentDepartmentList: res.data,
        });
      });
  };
  /**
   * 函数描述: 查询账号数据
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} departmentName   部门名称
   * @param {string} departmentPrincipalName   部门负责人姓名
   * @param {string} parentDepartmentId   上级部门id
   * @param {string} type   类型（1：项目 2：部门）
   * @return {Object}
   */
  queryTableList = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const params = {
          ...this.state.searchVale,
        };
        this.$http
          .listForTreePage(params)
          .then((res) => {
            if (res.code == 200) {
              this.setState({
                loading: false,
                // pageNo,
                // pageSize,
                dataList: res.data || [],
                // total: res.data.total,
              });
            } else {
              // debugger
              this.setState({
                loading: false,
                dataList: [],
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };
  /**
   * 函数描述: 高级搜索
   * @param {Object} value   高级搜索条件
   * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
   */
  handleSearchBtn = (value) => {
    this.setState(
      {
        searchVale: { ...value },
      },
      () => {
        this.queryTableList();
      }
    );
  };
  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @return {Object} 请求pageNo页列表数据
   */
  // handleChangePage = (pageNo, pageSize) => {
  //   this.queryTableList(pageNo, pageSize);
  // };
  /**
   * 函数描述: 新增组织架构
   * @return 打开新增弹框
   */
  handleAddSubmit = () => {
    this.setState(
      {
        addModalShow: true,
        modalType: 0,
        organizationalItem: {},
      },
      () => {
        this.refs.addModal.resetFields(); // 避免数据闪现
      }
    );
  };
  // 关闭弹框
  handleCancelModal = () => {
    this.setState({
      addModalShow: false,
      organizationalItem: {},
    });
  };
  /**
   * 函数描述: 禁用组织架构
   * @param {Object} record 该组织架构所有信息 -- 后期要精简参数更换为唯一标识
   * @return {Object} 刷新列表数据 --- 需要跟后端确认修改状态后该数据的排序方式：1.刷新当前页数据 2.刷新第一页数据
   */
  handleProhibit = (item) => {
    console.log(item, "-----------");
    message.success("操作成功");
    // const { pageNo, pageSize } = this.state;
    this.queryTableList();
  };
  /**
   * 函数描述: 删除组织架构
   * @param {Object} departmentId 部门id
   * @return {Object} 刷新当前页数据
   */
  handleDelete = (departmentId) => {
    this.$http
      .deleteDepartment({ departmentId })
      .then((res) => {
        if (res.code === 200) {
          // const { pageNo, pageSize } = this.state;
          this.queryTableList();
          this.child.querydepartmentId()
        }
      })
      .catch((err) => {});
  };
  /**
   * 函数描述: 编辑组织架构
   * @return 打开新增弹框
   */
  handleAddNext = (item) => {
    console.log(item);
    if (item.level < 5) {
      item.disabled = true;
      const value = JSON.parse(JSON.stringify(item));
      value.parentDepartmentId = item.departmentId;
      // value.departmentPrincipalId = parseInt(item.departmentPrincipalId)
      value.departmentId = 0;
      value.departmentName = "";
      value.level = String(parseInt(item.level) + 1);
      console.log(value);
      this.setState(
        {
          addModalShow: true,
          modalType: 0,
          organizationalItem: value,
        }
        // () => {
        //   this.refs.addModal.resetFields() // 避免数据闪现
        // }
      );
    } else {
      message.error("当前部门为最终级别，不可添加下级部门");
    }
  };
  handleEditSubmit = async(item) => {
    const res = await apis.queryOne({departmentId:item.departmentId})
    if (res.code === 200) {
      item.remark  = res.data.remark ||''
    }
    setTimeout(() => {
      this.setState(
        {
          addModalShow: true,
          modalType: 1,
          organizationalItem: item,
        }
        );
    }, 500);
      // () => {
      //   this.refs.addModal.resetFields() // 避免数据闪现
      // }
  };
  /**
   * 函数描述: 修改或新增确认
   * @param {string} type 			弹框类型 0：新增 1：修改
   * @param {Object} value.departmentName 	部门名称
   * @param {Object} value.level 		层级
   * @param {Object} value.parentDepartmentId 		上级部门id
   * @param {Object} value.departmentPrincipalName 	部门负责人姓名
   * @param {Object} value.type 		类型（1：项目 2：部门）
   * @param {Object} value.remark 	岗位职责
   * @return
   */
  handleAddSure = (type, value) => {
    const { disabled } = this.state;
    console.log(disabled, type);
    if (type === 0) {
      // this.setState(
      //   {
      //     disabled: true,
      //   },
      // () => {
      // 新增弹框
      this.$http.addDepartment(value).then((res) => {
        this.setState(
          {
            disabled: false,
          },
          () => {
            if (res.code === 200) {
              this.handleCancelModal();
              this.queryTableList();
              this.child.querydepartmentId()
            }
          }
        );
      });
      // }
      // );
    } else {
      let params = {
        ...value,
        departmentId: this.state.organizationalItem.departmentId,
      };
      if (params.level === "1") {
        params.parentDepartmentId = 0;
      }
      console.log(params);
      this.$http.updateDepartment(params).then((res) => {
        this.setState(
          {
            disabled: false,
          },
          () => {
            if (res.code === 200) {
              this.handleCancelModal();
              // const { pageNo, pageSize } = this.state;
              this.queryTableList();
              this.child.querydepartmentId()
            }
          }
        );
      });
    }
  };
  render() {
    const {
      dataList,
      loading,
      addModalShow,
      modalType,
      organizationalItem,
      parentDepartmentList,
      addParentDepartmentList,
    } = this.state;
    return (
      <div className="organizational-structure">
        <div className="crumbs">组织架构</div>
        {/* 搜索框 */}
        <SearchForm
          parentDepartmentList={parentDepartmentList}
          handleSearchBtn={this.handleSearchBtn}
          onRef={ node => this.child = node }
        />
        {/* 组织架构数据 */}
        <div className="organizational-box wrap-card">
          <Button
            className="addButton"
            type="primary"
            onClick={this.handleAddSubmit}
          >
            添加
          </Button>
          <Table
            loading={loading}
            data={dataList}
            // total={total}
            // pageNo={pageNo}
            // pageSize={pageSize}
            // handleChangePage={this.handleChangePage} // 切换页码
            handleProhibit={this.handleProhibit} // 禁用组织架构
            handleDelete={this.handleDelete} // 删除组织架构
            handleEditSubmit={this.handleEditSubmit} // 编辑组织架构
            handleAddNext={this.handleAddNext} // 添加下一层级
          />
        </div>
        {/* 新增或修改组织架构 */}
        {addModalShow && (
          <AddModal
            ref="addModal"
            addModalShow={addModalShow}
            type={modalType}
            addParentDepartmentList={addParentDepartmentList}
            organizationalItem={organizationalItem}
            handleCancel={this.handleCancelModal}
            handleAddSure={this.handleAddSure}
            listForDropDown={this.departmentListByParent}
          />
        )}
      </div>
    );
  }
}

export default OrganizationalStructure;
