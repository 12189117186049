import React, { Component } from "react";
import { render } from "react-dom";

import CalendarHeader from "./calendarHeader";
import CalendarMain from "./calendarMain";
import CalendarFooter from "./calendarFooter";
import "./index.less";

const displayDaysPerMonth = year => {
  //定义每个月的天数，如果是闰年第二月改为29天
  let daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    daysInMonth[1] = 29;
  }
  //   console.log(daysInMonth)

  //以下为了获取一年中每一个月在日历选择器上显示的数据，
  //从上个月开始，接着是当月，最后是下个月开头的几天

  //定义一个数组，保存上一个月的天数
  let daysInPreviousMonth = [].concat(daysInMonth);
  //   console.log(daysInPreviousMonth);
  daysInPreviousMonth.unshift(daysInPreviousMonth.pop());
  //   console.log(daysInPreviousMonth);

  //获取每一个月显示数据中需要补足上个月的天数
  let addDaysFromPreMonth = new Array(12).fill(null).map((item, index) => {
    //   获取当前年份每个月第一天是星期几，
    let day = new Date(year, index, 1).getDay();
    //  是星期六的话，就要补足上个月的6天，其余就要补足day-1的天数
    if (day === 0) {
      return 6;
    } else {
      return day - 1;
    }
  });

  //   console.log(addDaysFromPreMonth);

  //已数组形式返回一年中每个月的显示数据,每个数据为6行*7天
  return new Array(12).fill([]).map((month, monthIndex) => {
    let addDays = addDaysFromPreMonth[monthIndex],
      daysCount = daysInMonth[monthIndex],
      daysCountPrevious = daysInPreviousMonth[monthIndex],
      monthData = [];
    //补足上一个月
    for (; addDays > 0; addDays--) {
      monthData.unshift(daysCountPrevious--);
    }
    //添入当前月
    for (let i = 0; i < daysCount; ) {
      monthData.push(++i);
    }
    //补足下一个月
    for (let i = 42 - monthData.length, j = 0; j < i; ) {
      monthData.push(++j);
    }
    // console.log(monthData);
    return monthData;
  });
};

export default class Calendar extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    this.state = {
      year: now.getFullYear(),
      month: now.getMonth(),
      day: now.getDate(),
      picked: false
    };
  }

  //切换到下一个月
  nextMonth = () => {
    if (this.state.month === 11) {
      this.setState(
        {
          year: ++this.state.year,
          month: 0
        },
        () => {
          this.props.nextMonth();
        }
      );
    } else {
      this.setState({
        month: ++this.state.month
      });
    }
  };
  //切换到上一个月
  prevMonth() {
    if (this.state.month === 0) {
      this.setState({
        year: --this.state.year,
        month: 11
      });
    } else {
      this.setState({
        month: --this.state.month
      });
    }
  }
  //选择日期
  datePick(day) {
    this.setState({ day });
  }
  //标记日期已经选择
  picked() {
    this.state.picked = true;
  }

  render() {
    let props = {
      viewData: displayDaysPerMonth(this.state.year),
      datePicked: `${this.state.year} 年
                   ${this.state.month + 1} 月
                   ${this.state.day} 日`,
      dateList: this.props.dateList
    };
    return (
      <div className="output">
        <div className="main" ref="main">
          <CalendarHeader
            prevMonth={this.prevMonth.bind(this)}
            nextMonth={this.props.nextMonth}
            year={this.state.year}
            month={this.state.month}
            day={this.state.day}
          />
          <CalendarMain
            {...props}
            prevMonth={this.prevMonth.bind(this)}
            nextMonth={this.nextMonth.bind(this)}
            datePick={this.datePick.bind(this)}
            year={this.state.year}
            month={this.state.month}
            day={this.state.day}
          />
          <CalendarFooter picked={this.picked.bind(this)} />
        </div>
      </div>
    );
  }
}
