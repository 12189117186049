import React, { Component } from 'react';
import { Divider, Button } from 'antd';
import './index.less';
import AddForm from './addForm';
import { personalInformation, postSalary } from '../../../data';
import Api from '../../../../../api/apis';
import moment from 'moment';

class AddEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formType: 1, // 1: 个人信息 2: 岗位薪资
      formItemData: personalInformation, // 表单数据
      // formItemData: postSalary,
      selectList: {}, // 数据字典关系
      jobDetail: { isLeave: '0', signTotalFrequency: 1 },
      cityData: [],
    };
  }
  componentDidMount() {
    this.querydepartmentId(true);
    this.querypostId(true);
    this.queryoccupationId(true);
    this.queryProvinceList();
  }
  querySelectList = (type, open) => {
    this[type](open);
  };
  // 查询民族s
  async querynation(type = true) {
    try {
      const res = await this.$http.nationData();
      const { code, data } = res;
      if (code === 200) {
        const { selectList } = this.state;
        selectList.nationList = data.map(item => {
          return {
            dicKey: item.tbNationId,
            dicValue: item.nationName,
          };
        });
        this.setState({
          selectList,
        });
      }
    } catch {
      this.setState({
        selectList: [],
      });
    }
  }
  findDep = (data, key) => {
    if (data.length) {
      for (let item of data) {
        item.departmentId = item.departmentSonId;
        // item.value = item.id
        // item[child] = item[child] || []
        if (item.dstree && item.dstree.length) {
          this.findDep(item.dstree, key);
        }
      }
    }
    return data;
  };
  // 查询部门名称
  querydepartmentId = open => {
    if (open) {
      this.$http['departmentPermissions']({}).then(res => {
        if (res.code === 200) {
          const { selectList } = this.state;
          selectList.departmentIdList = res.data;
          // .map(item => {
          //   return {
          //     dicKey: item.departmentSonId + '&' + item.departmentSonName,
          //     dicValue: item.departmentSonName
          //   }
          // })
          this.setState({
            selectList,
          });
        }
      });
    }
  };
  // 查询岗位
  querypostId = open => {
    if (open) {
      this.$http['listForDropDownPost']().then(res => {
        if (res.code === 200) {
          const { selectList } = this.state;
          selectList.postIdList = res.data.map(item => {
            return {
              dicKey: item.postId,
              dicValue: item.postName,
            };
          });
          this.setState({
            selectList,
          });
        }
      });
    }
  };
  // 查询职级
  queryoccupationId = open => {
    if (open) {
      this.$http['selectLevel']().then(res => {
        if (res.code === 200) {
          const { selectList } = this.state;
          selectList.occupationIdList = res.data.map(item => {
            return {
              dicKey: item.occupationId,
              dicValue: item.occupationName,
            };
          });
          this.setState({
            selectList,
          });
        }
      });
    }
  };
  // 下拉框数据查询
  selectListByGroup = (open, key, dicGroup) => {
    if (open) {
      this.$http['selectListByGroup']({
        dicGroup,
      }).then(res => {
        if (res.code === 200) {
          const { selectList } = this.state;
          selectList[`${key}List`] = res.data;
          if (dicGroup == 'CONTRACT_NATURE') {
            selectList[`${key}List`].map((v, i) => {
              if (v.dicKey == 'LABOR') {
                v.dicKey = v.dicKey + '&FULL_TIME';
              } else if (v.dicKey == 'SERVICE') {
                v.dicKey = v.dicKey + '&PART_TIME';
              } else if (v.dicKey == 'INTERNSHIP') {
                v.dicKey = v.dicKey + '&INTERNSHIP';
              }
            });
          }
          this.setState({
            selectList,
          });
        }
      });
    }
  };
  // 查询省市区
  queryProvinceList = () => {
    this.$http['queryProvinceList']({}).then(res => {
      if (res.code === 200) {
        res.data.provinceList.map((v, index) => {
          v.key = v.addressCode + '&' + v.addressName;
          if (v.cityList) {
            v.cityList.map((l, i) => {
              l.key = l.addressCode + '&' + l.addressName;
            });
          }
        });
        this.setState({
          cityData: res.data.provinceList || [],
        });
      }
    });
  };
  onRef = ref => {
    this.formData = ref;
  };
  handleComeBack = () => {
    // window.close();
    // this.props.history.go(-1)
    const searchVale = this.props.history.location.state.searchVale;
    this.props.history.push({
      pathname: '/personnelMatters/employeeRoster',
      state: { searchVale },
    });
  };
  //点击下一步
  handleClickNext = () => {
    if (this.formData.handleAddSure()) {
      const value = this.formData.handleAddSure();
      this.setState(
        {
          formType: 2,
          formItemData: postSalary,
          jobDetail: { ...this.state.jobDetail, ...value },
        },
        () => {}
      );
    }
  };
  //点击上一步
  handleClickPrev = () => {
    const value = this.formData.handleAddSure();
    this.setState(
      {
        formType: 1,
        formItemData: personalInformation,
        jobDetail: { ...this.state.jobDetail, ...value },
      },
      () => {
        console.log(this.state.jobDetail, '-----Prev');
      }
    );
  };
  //点击保存
  handleClickSave = () => {
    if (this.formData.handleAddSure()) {
      const value = this.formData.handleAddSure();
      let param = { ...this.state.jobDetail, ...value };
      console.log(param.turnTime1, 555556556555);
      param.nativePlaceProvinceName = param.nativePlaceProvince;
      let socialSecurityfundPayLocal0 =
        param.socialSecurityfundPayLocalProvince[0];
      let socialSecurityfundPayLocal0ARR = socialSecurityfundPayLocal0.split(
        '&'
      );
      let socialSecurityfundPayLocal1 =
        param.socialSecurityfundPayLocalProvince[1];
      let socialSecurityfundPayLocal1ARR = socialSecurityfundPayLocal1.split(
        '&'
      );
      param.socialSecurityfundPayLocalProvince =
        socialSecurityfundPayLocal0ARR[0];
      param.socialSecurityfundPayLocalProvinceName =
        socialSecurityfundPayLocal0ARR[1];
      param.socialSecurityfundPayLocalCity = socialSecurityfundPayLocal1ARR[0];
      param.socialSecurityfundPayLocalCityName =
        socialSecurityfundPayLocal1ARR[1];
      let work0 = param.work[0];
      let work0ARR = work0.split('&');
      let work1 = param.work[1];
      let work1ARR = work1.split('&');
      param.workProvince = work0ARR[0];
      param.workProvinceName = work0ARR[1];
      param.workCity = work1ARR[0];
      param.workCityName = work1ARR[1];

      let department = param.departmentId.split('&');
      param.departmentId = department[0];
      param.departmentName = department[1];
      // param.belongDeliverManagerId = department[2]
      // param.belongDeliverManager = department[3]

      let contract = param.contractType.split('&');
      param.contractType = contract[0];
      param.hireNature = contract[1];
      param.staffName = param.staffName1;
      param.staffWorkExperience = param.workExperience;
      param.isOtherCompanyTransfer =
        param.isOtherCompanyTransfer == 0 ? false : true;
      param.isLeave = param.isLeave == 0 ? false : true;
      param.isProbation = param.isProbation == 0 ? false : true;
      param.isSchoolRecruitment = param.isSchoolRecruitment == 0 ? false : true;
      param.onboardingTime =
        param.onboardingTime == null
          ? ''
          : moment(param.onboardingTime).format('YYYY/MM/DD');
      param.birthday =
        param.birthday == undefined
          ? ''
          : moment(param.birthday).format('YYYY/MM/DD');
      param.actualBirthday =
        param.actualBirthday == undefined
          ? ''
          : moment(param.actualBirthday).format('YYYY/MM/DD');
      param.firstContractStartday =
        param.firstContractStartday == undefined
          ? ''
          : moment(param.firstContractStartday).format('YYYY/MM/DD');
      param.firstContractMaturityday =
        param.firstContractMaturityday == undefined
          ? ''
          : moment(param.firstContractMaturityday).format('YYYY/MM/DD');
      param.graduationTime =
        param.graduationTime == undefined
          ? ''
          : moment(param.graduationTime).format('YYYY/MM');
      param.lastCompanyTime =
        param.lastCompanyTime == undefined
          ? ''
          : moment(param.lastCompanyTime).format('YYYY/MM/DD');
      param.leaveTime =
        param.leaveTime == undefined
          ? ''
          : moment(param.leaveTime).format('YYYY/MM/DD');
      param.turnTime = param.turnTime1 || '';
      // param.turnTime = param.turnTime == undefined ? '' : moment(param.turnTime).format('YYYY/MM/DD')
      param.contractStartday =
        param.contractStartday == undefined
          ? ''
          : moment(param.contractStartday).format('YYYY/MM/DD');
      param.contractMaturityday =
        param.contractMaturityday == undefined
          ? ''
          : moment(param.contractMaturityday).format('YYYY/MM/DD');
      param.postId = param.postIdOK;
      if (param.staffWorkExperience != undefined) {
        param.staffWorkExperience.map((v, index) => {
          v.startWorkTime = moment(v.time[0]).format('YYYY/MM/DD');
          v.endWorkTime = moment(v.time[1]).format('YYYY/MM/DD');
        });
      }

      param.staffAnnexs = param.staffAnnexs.slice(0, 4);
      param.staffAnnexs.push(...param.fileList);
      param.attachment = param.contracts.map(item => item.annexKey).toString();
      Api.addStaffRoster(param).then(res => {
        if (res.code == '200') {
          // this.props.history.go(-1)
          const searchVale = this.props.history.location.state.searchVale;
          this.props.history.push({
            pathname: '/personnelMatters/employeeRoster',
            state: { searchVale },
          });
        }
      });
    }
  };
  render() {
    const {
      selectList,
      jobDetail,
      cityData,
      formItemData,
      formType,
    } = this.state;
    // console.log(jobDetail,'4444444')
    return (
      <div className="add-employee">
        <div className="crumbs">员工信息-新增</div>
        <div className="if-card tip-line flex flex-align-center">
          <div className={formType === 1 ? 'circular blue-border' : 'circular'}>
            1
          </div>
          <div className={formType === 1 ? 'blue-text' : 'black-text'}>
            个人信息
          </div>
          <Divider dashed />
          <div className={formType === 2 ? 'circular blue-border' : 'circular'}>
            2
          </div>
          <div className={formType === 2 ? 'blue-text' : 'black-text'}>
            岗位&薪资
          </div>
        </div>
        <AddForm
          onRef={this.onRef}
          selectList={selectList}
          formItemData={formItemData}
          staffDetail={jobDetail}
          cityData={cityData}
          selectListByGroup={this.selectListByGroup}
          querySelectList={this.querySelectList}
          querydepartmentId={this.querydepartmentId}
        />
        <div className="footer flex flex-align-center flex-justify-center">
          <Button onClick={this.handleComeBack}>返回</Button>
          {formType === 1 && (
            <Button
              type="primary"
              style={{ marginLeft: 16 }}
              onClick={this.handleClickNext}
            >
              下一步
            </Button>
          )}
          {formType === 2 && (
            <Button
              type="primary"
              style={{ marginLeft: 16 }}
              onClick={this.handleClickPrev}
            >
              上一步
            </Button>
          )}
          {formType === 2 && (
            <Button
              type="primary"
              style={{ marginLeft: 16 }}
              onClick={this.handleClickSave}
            >
              保存
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default AddEmployee;
