import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./index.less";

let templateName = {
  leave: "请假",
  monthlyAttendance: "月度考勤确认",
  becomeWorker: "转正",
  quit: "离职",
  autoQuit: "自动离职",
  offerApproval: "offer审批"
};

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processList: []
    };
  }
  componentDidMount() {
    this.$http["menulistForUser"]({}).then(res => {
      if (res.code === 200) {
        let processList = [];
        if (res.data) {
          let arr = res.data.filter(item => item.menuCode == "workbench");
          if (arr.length && arr[0].chirdren.length) {
            let brr = arr[0].chirdren.filter(
              item => item.menuCode == "newProcess"
            );
            if (brr.length && brr[0].chirdren.length) {
              let crr = brr[0].chirdren;
              crr.map(item => {
                item.templateName = templateName[item.menuCode];
                item.templateCode = item.menuIcon;
              });
              processList = crr;
            }
          }
        }
        this.setState({
          processList
        });
      }
    });
    // this.queryProcessTemplateByRole();
  }

  queryProcessTemplateByRole = () => {
    this.$http["queryProcessTemplateByRole"]({}).then(res => {
      if (res.code === 200) {
        this.setState({
          processList: res.data || []
        });
      } else {
      }
    });
  };

  handleLink = item => {
    if (item.templateCode === "LEAVE_PROC") {
      this.props.history.push("/workbench/leave");
    } else if (item.templateCode === "ATTE_PROC") {
      this.props.history.push("/workbench/monthlyAttendance");
    } else if (item.templateCode === "DIMI_PROC") {
      this.props.history.push("/workbench/quit");
    } else if (item.templateCode === "OFFER_PROC") {
      this.props.history.push("/workbench/offerApproval");
    } else if (item.templateCode === "BECOME_PROC") {
      this.props.history.push("/workbench/becomeWorker");
    } else if (item.templateCode === "SPON_DIMI_PROC") {
      this.props.history.push("/workbench/autoQuit");
    } else {
      this.props.history.push("/workbench/checkin");
    }
  };

  render() {
    const { processList } = this.state;
    return (
      <div className="workbench-process">
        <div className="process-title">新建流程</div>
        <div className="top-wrap">
          {processList.map(item => {
            return (
              <div
                key={item.templateCode}
                className="top-item"
                onClick={() => this.handleLink(item)}
                style={{
                  backgroundImage:
                    "url(" +
                    require(`../../../assets/image/processBg.png`) +
                    ")"
                }}
              >
                <img
                  src={require(`../../../assets/image/${item.templateCode}.png`)}
                  className="item-img"
                  alt=""
                />
                <span className="item-text">{item.templateName}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(MainManagement);
