/**
 * @fileoverview 系统管理---账号设置
 * @author  蒋西园
 */
import React, { Component } from "react";
import "./index.less";

import { message } from "antd";
import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表
import AddModal from "./component/addAccount"; // 新增或修改账号
import { map, values } from "lodash";
import treeData from "./treeData.json";
class AccountManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataList: [], // 账号列表数据
      total: 0, // 账号总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      searchVale: {}, // 搜索条件
      addModalShow: false, // 新增弹框显隐
      modalType: 0, // 0：添加账号弹框 1：修改账号弹框
      accountItem: {}, // 编辑账号弹框回显数据
      disabled: false, // 防重复点击
      sorter: "DESC", // 排序方式 ASC： 正序 DESC: 倒叙
      roleList: [], // 角色下拉框
      postList: [], // 岗位下拉框
      departmentList: [], // 部门下拉框
      dataRightsList: [], // 下拉树数据
      dataRightsListInitValues: [], // 下拉树回填的数据
    };
  }
  componentDidMount() {
    this.queryTableList();
    this.querydepartmentId();
    this.listForDropDownPost();
    this.listForDropDownRole();
    this.getDataRightsTree();
  }
  /***
   * @method 获取数据权限下拉树
   */
  getDataRightsTree = () => {
    console.log(
      "treeData ->",
      this.getTreeData(treeData.data, [])
    );
    this.$http
      .getTreeData({
        level: null,
        departmentId: null,
        departmentPrincipalId: null,
        type: null,
      })
      .then((res) => {
        console.log("树数据", res);
        if (res.code == 200) {
          const treeDataSource = this.getTreeData(
            res.data,
            []
          );
          this.setState({
            dataRightsList: treeDataSource,
          });
        }
      });
  };
  /**
   * @method 处理树数据
   */
  getTreeData = (data, treeData) => {
    treeData = map(data, (item) => {
      const {
        departmentName,
        departmentId,
        chirdren,
        level,
      } = item;
      return {
        title: departmentName,
        value: departmentId,
        key: departmentId,
        level,
        children:
          chirdren && Array.isArray(chirdren)
            ? this.getTreeData(chirdren, treeData)
            : null,
      };
    });
    return treeData;
  };

  /**
   * 函数描述: 查询角色下拉数据
   */
  listForDropDownRole = () => {
    this.$http.listForDropDownRole().then((res) => {
      if (res.code === 200) {
        this.setState({
          roleList: res.data || [],
        });
      }
    });
  };
  /**
   * 函数描述: 查询岗位下拉数据
   */
  listForDropDownPost = () => {
    this.$http.listForDropDownPost().then((res) => {
      if (res.code === 200) {
        this.setState({
          postList: res.data,
        });
      }
    });
  };

  /**
   * 函数描述: 查询部门名称
   */
  querydepartmentId = () => {
    this.$http.getJurisdictionTree({}).then((res) => {
      console.log("res ->", res);
      if (res.code === 200) {
        this.setState({
          departmentList: res.data || [],
        });
      }
    });
  };

  /**
   * 函数描述: 查询账号数据
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} staffName   账号
   * @param {string} departmentId   部门
   * @param {string} postId   岗位
   * @param {string} status   状态 true: 使用中 false: 禁用
   * @param {string} sortParam    排序方式 ASC： 正序 DESC: 倒叙
   * @return {Object}
   */
  queryTableList = (pageNo = 1, pageSize = 10) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const params = {
          ...this.state.searchVale,
          pageNo,
          pageSize,
          sortParam: this.state.sorter,
        };
        this.$http
          .accountList(params)
          .then((res) => {
            console.log("res ->", res);
            if (res.code == 200) {
              this.setState({
                loading: false,
                pageNo,
                pageSize,
                dataList: res.data.list,
                total: res.data.total,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };
  /**
   * 函数描述: 高级搜索
   * @param {Object} value   高级搜索条件
   * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
   */
  handleSearchBtn = (value) => {
    console.log("value ->", value);
    this.setState(
      {
        searchVale: { ...value },
      },
      () => {
        this.queryTableList();
      }
    );
  };
  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} sorter 	排序方式 ASC： 正序 DESC: 倒叙
   * @return {Object} 请求pageNo页列表数据
   */
  handleChangePage = (page, sorter) => {
    let sorterOrder = "DESC";
    if (sorter.order) {
      sorterOrder =
        sorter.order === "ascend" ? "ASC" : "DESC";
    }
    this.setState(
      {
        sorter: sorterOrder,
      },
      () => {
        this.queryTableList(page.current, page.pageSize);
      }
    );
  };
  /**
   * 函数描述: 禁用/启用账号
   * @param {String}  userId 账号id
   * @param {Boolean} status 状态（true：启用 false：禁用）
   * @return {Object} 刷新列表数据 --- 需要跟后端确认修改状态后该数据的排序方式：1.刷新当前页数据 2.刷新第一页数据
   */
  handleProhibit = ({ userId, status }) => {
    this.$http
      .updateUserState({
        userId,
        status: !status,
      })
      .then((res) => {
        if (res.code === 200) {
          message.success("操作成功");
          const { pageNo, pageSize } = this.state;
          this.queryTableList(pageNo, pageSize);
        }
      })
      .catch((err) => {});
  };
  /**
   * 函数描述: 编辑账号
   * @return 打开新增弹框
   */
  handleEditSubmit = (item) => {
    console.log("item ->", item);
    this.listForDropDownRole();
    this.refs.addModal.resetFields(); // 避免数据闪现
    const treeInitialValue = this.getTreeInitialValue(
      item.departmentPermissions
    );
    console.log("treeInitialValue ->", treeInitialValue);
    this.setState({
      addModalShow: true,
      modalType: 1,
      accountItem: item,
      dataRightsListInitValues: treeInitialValue,
    });
  };
  /**
   * @method 初始化树initValue
   */
  getTreeInitialValue = (values) => {
    const arr = [];
    Array.isArray(values) &&
      map(
        values,
        (item) => item && arr.push(item.departmentSonId)
      );
    return arr;
  };
  // 关闭弹框
  handleCancelModal = () => {
    this.setState({
      addModalShow: false,
    });
  };
  /**
   * 函数描述: 修改或新增确认
   * @param {Object} value 弹框各项值
   * @return
   */
  handleAddSure = ({
    passWord,
    rolesIds,
    departmentPermissionsPos,
  }) => {
    const { disabled, accountItem } = this.state;
    console.log("accountItem ->", accountItem);
    if (!disabled) {
      this.setState(
        {
          disabled: true,
        },
        () => {
          if (departmentPermissionsPos.length == 0) {
            departmentPermissionsPos = null;
          }
          this.$http
            .updateUser({
              userId: accountItem.userId,
              staffId: accountItem.staffId,
              staffCode: accountItem.staffCode,
              staffName: accountItem.staffName,
              departmentId: accountItem.departmentId,
              postId: accountItem.postId,
              gmtCreated: "",
              departmentPermissionsPos,
              password:
                passWord == undefined
                  ? ""
                  : this.$md5(passWord),
              rolesIds: rolesIds,
              personalOpenid: null,
              publicOpenid: null,
              oldPassword: "null",
              status: true,
              isDelete: false,
              creator: "null",
              creatorId: 0,
              modifier: "null",
              modifierId: 0,
              gmtModified: "",
              avatar: "null",
              avatarUrl: "null",
            })
            .then((res) => {
              if (res.code === 200) {
                this.handleCancelModal();
                this.setState(
                  {
                    disabled: false,
                  },
                  () => {
                    const { pageNo, pageSize } = this.state;
                    this.queryTableList(pageNo, pageSize);
                  }
                );
              }
            });
        }
      );
    }
  };
  render() {
    const {
      dataList,
      total,
      pageNo,
      pageSize,
      loading,
      addModalShow,
      modalType,
      accountItem,
      roleList,
      postList,
      departmentList,
      dataRightsList,
      dataRightsListInitValues,
    } = this.state;
    return (
      <div className="account-management">
        <div className="crumbs">账号管理</div>
        {/* 搜索框 */}
        <SearchForm
          handleSearchBtn={this.handleSearchBtn}
          postList={postList}
          departmentList={departmentList}
          listForDropDownPost={this.listForDropDownPost}
          querydepartmentId={this.querydepartmentId}
        />
        {/* 账号数据 */}
        <div className="account-box wrap-card">
          {/* <Button className="addButton" type="primary"  onClick={this.handleAddSubmit}>添加</Button> */}
          <Table
            loading={loading}
            data={dataList}
            total={total}
            pageNo={pageNo}
            pageSize={pageSize}
            handleChangePage={this.handleChangePage} // 切换页码
            handleProhibit={this.handleProhibit} // 禁用账号
            handleEditSubmit={this.handleEditSubmit} // 编辑账号
          />
        </div>
        {/* 新增或修改账号 */}
        <AddModal
          ref="addModal"
          addModalShow={addModalShow}
          type={modalType}
          roleList={roleList}
          dataRightsList={dataRightsList}
          dataRightsListInitValues={
            dataRightsListInitValues
          }
          accountItem={accountItem}
          listForDropDownRole={this.listForDropDownRole}
          handleCancel={this.handleCancelModal}
          handleAddSure={this.handleAddSure}
        />
      </div>
    );
  }
}

export default AccountManagement;
