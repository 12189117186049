/**
 * 系统设置
 */
import http from "./fetch";
const baseUrl = process.env.API_URL
  ? process.env.API_URL
  : "/personnel-bsm";
const baseUrlbus = process.env.API_URL
  ? process.env.API_URL
  : "/personnel-business";
export default {
  // 角色下拉框
  listForDropDownRole(param, callbak) {
    return http.post(
      `${baseUrl}/pc/role/listForDropDown`,
      param
    );
  },
  // 岗位下拉框
  listForDropDownPost(param, callbak) {
    return http.post(
      `${baseUrl}/pc/post/listForDropDown`,
      param
    );
  },
  // 根据父级查询组织机构
  departmentListByParent(param, callbak) {
    return http.post(
      `${baseUrl}/pc/department/listByParent`,
      param
    );
  },
  // 根据查部门
  departmentListAll(param, callbak) {
    return http.post(
      `${baseUrl}/pc/department/departmentPermissions`,
      param
    );
  },

  // ------------------------ 组织架构 ------------------------
  // 查询列表
  departmentListForPage(param, callbak) {
    return http.post(
      `${baseUrl}/pc/department/listForPage`,
      param
    );
  },
  // 删除流程配置
  deleteDepartment(param, callbak) {
    return http.post(
      `${baseUrl}/pc/department/deleteDepartment`,
      param,
      true
    );
  },
  // 新增流程
  addDepartment(param, callbak) {
    return http.post(
      `${baseUrl}/pc/department/addDepartment`,
      param,
      true
    );
  },
  // 修改流程
  updateDepartment(param, callbak) {
    return http.post(
      `${baseUrl}/pc/department/updateDepartment`,
      param,
      true
    );
  },

  // ------------------------ 流程配置 ------------------------
  // 分页查询部门流程
  pageConfigByDeptId(param, callbak) {
    return http.post(
      `${baseUrlbus}/pc/process/config/pageConfigByDeptId`,
      param
    );
  },
  // 删除流程配置
  deleteConfigByDeptId(param, callbak) {
    return http.post(
      `${baseUrlbus}/pc/process/config/deleteConfigByDeptId`,
      param,
      true
    );
  },
  // 流程模板查询
  queryTemplate(param, callbak) {
    return http.get(
      `${baseUrl}/pc/system/dictionary/process/queryTemplate`,
      param
    );
  },
  // 新增流程配置
  addProcConfig(param, callbak) {
    return http.post(
      `${baseUrlbus}/pc/process/config/addProcConfig`,
      param,
      true
    );
  },
  // 流程配置详情
  queryConfigDetails(param, callbak) {
    return http.post(
      `${baseUrlbus}/pc/process/config/queryConfigDetails`,
      param
    );
  },
  // 修改流程配置
  updateProcConfig(param, callbak) {
    return http.post(
      `${baseUrlbus}/pc/process/config/updateProcConfig`,
      param,
      true
    );
  },

  // ------------------------ 工资发放设置 --------------------
  // 查询薪资单发放日设置
  pageSalarySendDay(param, callbak) {
    return http.post(
      `${baseUrl}/dept/pageSalarySendDay`,
      param
    );
  },
  // 修改薪资单发放日
  updateSalarySendDay(param, callbak) {
    return http.post(
      `${baseUrl}/dept/updateSalarySendDay`,
      param,
      true
    );
  },

  // ------------------------ 账号管理 ------------------------

  // 账号管理 --- 账号列表
  accountList(param, callbak) {
    return http.post(
      `${baseUrl}/pc/user/listForPage`,
      param
    );
  },
  // 账号管理 --- 启用/禁用账号 状态（true：启用 false：禁用）
  updateUserState(param, callbak) {
    return http.post(
      `${baseUrl}/pc/user/updateUserState`,
      param
    );
  },
  // 账号管理 --- 编辑
  updateUser(param, callbak) {
    return http.post(
      `${baseUrl}/pc/user/updateUser`,
      param,
      true
    );
  },
  // 系统设置-账号管理-编辑
  getTreeData(param) {
    return http.post(
      `${baseUrl}/pc/department/listForTree`,
      param
    );
  },
  // ------------------------ 假期管理 ------------------------

  // 假期管理 --- 账号列表
  holidayList(param, callbak) {
    return http.post(
      `${baseUrl}/pc/holiday/pageHoliday`,
      param
    );
  },
  // 假期管理 --- 新增
  addHoliday(param, callbak) {
    return http.post(
      `${baseUrl}/pc/holiday/addHoliday`,
      param,
      true
    );
  },
  // 假期管理 --- 编辑
  updataHoliday(param, callbak) {
    return http.post(
      `${baseUrl}/pc/holiday/updateHoliday`,
      param,
      true
    );
  },
  // 假期管理 --- 删除
  removeHoliday(param, callbak) {
    return http.post(
      `${baseUrl}/pc/holiday/deleteHoliday`,
      param,
      true
    );
  },

  // ------------------------ 角色管理 ------------------------
  // 角色管理 --- 角色列表
  roleList(param, callbak) {
    return http.post(
      `${baseUrl}/pc/role/listForPage`,
      param
    );
  },
  // 角色管理 --- 新增角色
  addRoles(param, callbak) {
    return http.post(
      `${baseUrl}/pc/role/addRoles`,
      param,
      true
    );
  },
  // 角色管理 --- 编辑角色
  updateRoles(param, callbak) {
    return http.post(
      `${baseUrl}/pc/role/updateRoles`,
      param,
      true
    );
  },
  // 角色管理 --- 编辑角色
  selectByPrimaryKey(param, callbak) {
    return http.post(
      `${baseUrl}/pc/role/selectByPrimaryKey`,
      param
    );
  },
  // 角色管理 --- 删除角色
  deleteRoles(param, callbak) {
    return http.post(
      `${baseUrl}/pc/role/deleteRoles`,
      param,
      true
    );
  },
  // 查询菜单树
  menuListForTree(param, callbak) {
    return http.post(
      `${baseUrl}/pc/menu/listForTree`,
      param
    );
  },

  // ------------------------ 岗位管理 ------------------------

  // 岗位管理 --- 岗位列表
  postList(param, callbak) {
    return http.post(
      `${baseUrl}/pc/post/listForPage`,
      param
    );
  },
  // 岗位管理 --- 新增
  postAdd(param, callbak) {
    return http.post(
      `${baseUrl}/pc/post/addPost`,
      param,
      true
    );
  },
  // 岗位管理 --- 编辑
  postUpdate(param, callbak) {
    return http.post(
      `${baseUrl}/pc/post/updatePost`,
      param,
      true
    );
  },
  // 岗位管理 --- 删除
  postDelete(param, callbak) {
    return http.post(
      `${baseUrl}/pc/post/deletePost`,
      param,
      true
    );
  },
  // ------------------------ 职级管理 ------------------------

  // 职级管理 --- 职级列表
  rankList(param, callbak) {
    return http.post(
      `${baseUrl}/pc/level/listForPage`,
      param
    );
  },
  // 职级管理 --- 新增
  rankAdd(param, callbak) {
    return http.post(
      `${baseUrl}/pc/level/addLevel`,
      param,
      true
    );
  },
  // 职级管理 --- 编辑
  rankUpdateLevel(param, callbak) {
    return http.post(
      `${baseUrl}/pc/level/updateLevel`,
      param,
      true
    );
  },
  // 职级管理 --- 删除
  rankDeleteLevel(param, callbak) {
    return http.post(
      `${baseUrl}/pc/level/deleteLevel`,
      param,
      true
    );
  },

  // ------------------------ 数据字典 ------------------------
  // 列表页左侧字典类型
  getDicGroups(param, callbak) {
    return http.post(
      `${baseUrl}/dictionary/getDicGroups`,
      param
    );
  },
  // 右侧列表查询
  pageDictionary(param, callbak) {
    return http.post(
      `${baseUrl}/dictionary/pageDictionary`,
      param
    );
  },
  // 新增数据字典
  dictionaryInsert(param, callbak) {
    return http.post(
      `${baseUrl}/dictionary/insert`,
      param,
      true
    );
  },
  // 修改数据字典
  dictionaryUpdate(param, callbak) {
    return http.post(
      `${baseUrl}/dictionary/update`,
      param,
      true
    );
  },
  // 删除数据字典
  dictionaryDelete(param, callbak) {
    return http.post(
      `${baseUrl}/dictionary/delete`,
      param,
      true
    );
  },
  // ------------------------ 系统公告 ------------------------
  // 查询公告列表数据
  pageNotifications(param, callbak) {
    return http.post(
      `${baseUrl}/notification/pageNotifications`,
      param
    );
  },
  // 发布/取消发布
  notificationRelease(param, callbak) {
    return http.post(
      `${baseUrl}/notification/release`,
      param,
      true
    );
  },
  // 删除公告
  notificationDelete(param, callbak) {
    return http.post(
      `${baseUrl}/notification/delete`,
      param,
      true
    );
  },
  // 新增公告
  notificationInsert(param, callbak) {
    return http.post(
      `${baseUrl}/notification/insert`,
      param,
      true
    );
  },
  // 公告详情
  getNotificationById(param, callbak) {
    return http.post(
      `${baseUrl}/notification/getNotificationById`,
      param
    );
  },
  // 修改公告
  notificatioUpdate(param, callbak) {
    return http.post(
      `${baseUrl}/notification/update`,
      param,
      true
    );
  },
  // ----------------------- 个人中心 --------------------------
  queryForPersonal(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staff/queryForPersonal`,
      param
    );
  },
  // 修改密码
  updatePasswordByOld(param, callbak) {
    return http.post(
      `${baseUrl}/pc/user/updatePasswordByOld`,
      param,
      true
    );
  },
  // 个人中心-修改头像、邮箱、手机号
  // /pc/staff/updateStaffForPersonal
};
