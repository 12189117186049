/**
* @fileoverview 系统管理---账号设置 -- 数据列表
* @author  蒋西园
*/
import React, { Component } from 'react';
import { Table } from 'antd';
import './index.less'

class tableList extends Component {
    constructor(props) {
        super(props)
        this.columns = [{
                title: '合同编号',
                dataIndex: 'contractCode',
                width: 180,
                render: (text, record) =>  <span onClick={this.handleContractDetail.bind(this,record.contractId)} style={{ color: "#4D91FA", cursor: 'pointer' }}>{text}</span>
            },{
                title: '工号',
                dataIndex: 'staffCode',
                width: 120,
                render: (text,record) =>  <span onClick={this.handleTableRow.bind(this,record.staffId)} style={{ color: "#4D91FA", cursor: 'pointer' }}>{text}</span>
            },{
                title: '姓名',
                dataIndex: 'staffName',
                width: 120
            },{
                title: '部门',
                dataIndex: 'departmentName',
                render: (text) => text ? text : '--'
            },{
                title: '岗位',
                dataIndex: 'postName',
            },{
                title: '合同类型',
                dataIndex: 'contractTypeName',
            },{
                title: '合同到期日',
                dataIndex: 'contractMaturityday',
                sorter: true
            },{
                title: '操作',
                render: (record) => {
                    return (
                        <span>
                           <span onClick={this.handleRenewContract.bind(this,record.staffId)} style={{ color: "#4D91FA", cursor: 'pointer' }}>续签</span>
                        </span>
                    )
                }
            },
        ]
    }
    handleTableRow = (staffId) => {
		var example = window.location.protocol;
		var example1 = window.location.host;
		let url = example+'//'+example1+'/personnelMatters/detail'+'?staffId='+staffId
		window.open(url)
        // this.props.history.push({ pathname: `detail`, state: { staffId } });
    }
    handleContractDetail = (contractId) => {

		var example = window.location.protocol;
		var example1 = window.location.host;
		let url = example+'//'+example1+'/personnelMatters/contractDetail'+'?contractId='+contractId
		window.open(url)
        // this.props.history.push({ pathname: `contractDetail`, state: { contractId } });
    }
    // 续签合同
    handleRenewContract = (staffId) => {
        this.props.handleRenewContract(staffId)
    }
    render() {
        return (
            <Table
                rowKey={(record,index) => { return index }}
                loading={this.props.loading}
                columns={this.columns} 
                dataSource={this.props.data}
                pagination={{
                    hideOnSinglePage: true,
                    current: this.props.pageNo,
                    total: this.props.total,
                    pageSize: this.props.pageSize || 10
                }}
                locale={{
                    emptyText: (
                      <div>
                        <img
                          style={{ width: 182, height: 94 }}
                          src={require("../../../../../assets/image/no-data.png")}
                          alt=""
                        />
                        <p
                          style={{
                            fontSize: 14,
                            color: "#717E99",
                            lineHeight: "20px"
                          }}
                        >
                          暂无数据
                        </p>
                      </div>
                    )
                  }}
                onChange={(pageNo, {}, sorter) => this.props.handleChangePage(pageNo, sorter)}
            />
        )
    }
}
export default tableList