/**
 * @fileoverview 系统管理---账号设置 -- 数据列表
 * @author  黄洪月
 */
import React, { Component } from "react";
import { Table } from "antd";
import EditButton from "../editButton";
import "./index.less";

class tableList extends Component {
  constructor(props) {
    super(props);
    // 列表
    this.columns = [
      {
        title: "工号",
        dataIndex: "staffCode",
        width: 100,
        rowKey:"staffCode",
        fixed:"left"
      },
      {
        title: "姓名",
        dataIndex: "staffName",
        rowKey:"staffName",
        fixed:"left"
      },
      {
        title: "部门",
        dataIndex: "departmentName",
        width: 200,
        rowKey:"departmentName",
        fixed: "left",
      },
      {
        title: "岗位",
        dataIndex: "positionName",
        width: 100,
        rowKey:"positionName",
      },
      {
        title: "入职时间",
        dataIndex: "hireDate",
        width: 110,
        rowKey:"hireDate",
        render: text => text ? text : "--"
      },
      {
        title: "在职状态",
        dataIndex: "jobStatus",
        width: 100,
        rowKey:"jobStatus",
        render: (text) => {
          return (
            <div
              className={
                text == 0 ? "job_type1" : "job_type2"
              }
            >
              {text == 0 ? "在职" : "离职"}
            </div>
          );
        },
      },
      {
        title: "合同类型",
        dataIndex: "contractType",
        width: 100,
        rowKey:"contractType",
        render: (text) => {
          return (
            <div>
              {text === "LABOR"
                ? "劳动合同"
                : text === "INTERNSHIP"
                ? "实习合同"
                : text === "SERVICE"
                ? "劳务合同"
                : "--"}
            </div>
          );
        },
      },
      {
        title: "薪资类型",
        dataIndex: "salaryType",
        width: 100,
        rowKey:"salaryType",
        render: text => <div>{text==1?"日新":text==2?"月薪":"--"}</div>
      },
      {
        title: "薪资（元）",
        dataIndex: "turnSalary",
        width: 110,
        rowKey:"turnSalary",
        render: (text) => (text ? `￥${text}` : "--"),
      },
      {
        title: "试用期薪资（元）",
        dataIndex: "probationSalary",
        width: 150,
        rowKey:"probationSalary",
        render: (text) => (text ? `￥${text}` : "--"),
      },
      {
        title: "客户认可的加薪（元）",
        dataIndex: "clientRaise",
        width: 180,
        rowKey:"clientRaise",
        render: (text) => (text ? `￥${text}` : "--"),
      },
      {
        title: "公司加薪（元）",
        dataIndex: "companyRaise",
        width: 130,
        rowKey:"companyRaise",
        render: (text) => (text ? `￥${text}` : "--"),
      },
      {
        title: "合计转正（元）",
        dataIndex: "totalTurn",
        width: 130,
        rowKey:"totalTurn",
        render: (text) => (text ? `￥${text}` : "--"),
      },
      {
        title: "rate",
        dataIndex: "rate",
        width: 80,
        rowKey:"rate",
        render: (text) => (text ? `${text}元` : "--"),
      },
      {
        title: "当前毛利",
        dataIndex: "grossMargin",
        width: 100,
        rowKey:"grossMargin",
        render: (text) => (text ? `${text}%` : "--"),
      },
      {
        title: "不加年终奖毛利",
        dataIndex: "outBonusGrossMargin",
        width: 140,
        rowKey:"outBonusGrossMargin",
        render: (text) => (text ? `${text}%` : "--"),
      },
      {
        title: "全额社保毛利",
        dataIndex: "socialGrossMargin",
        width: 120,
        rowKey:"socialGrossMargin",
        render: (text) => (text ? `${text}%` : "--"),
      },
      {
        title: "操作",
        width: 60,
        rowKey:`staffCode${+1}`,
        fixed:"right",
        render: (text, record) => {
          return (
            <EditButton
              id={record.staffId}
              refreshList={this.props.refreshList}
            />
          );
        },
      },
    ];
  }

  render() {
    return (
      <div className="salary_file_table">
        <Table
          rowKey="staffId"
          loading={this.props.loading}
          columns={this.columns}
          dataSource={this.props.data}
          scroll={{ x: 2200 }}
          locale={{
            emptyText: (
              <div>
                <img
                  style={{ width: 182, height: 94 }}
                  src={require("../../../../../assets/image/no-data.png")}
                  alt=""
                />
                <p
                  style={{
                    fontSize: 14,
                    color: "#717E99",
                    lineHeight: "20px",
                  }}
                >
                  暂无数据
                </p>
              </div>
            ),
          }}
          pagination={{
            hideOnSinglePage: true,
            current: this.props.pageNo,
            total: this.props.total,
            pageSize: this.props.pageSize || 10,
            onChange: (pageNo, pageSize) =>
              this.props.handleChangePage(pageNo, pageSize),
          }}
        />
      </div>
    );
  }
}
export default tableList;
