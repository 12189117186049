import http from "./fetch";
const baseUrl = process.env.API_URL
  ? process.env.API_URL
  : "";

export default {
  // 加注机待发货列表
  refuelInstallFindPage(param) {
    return http.post(
      `${baseUrl}/platform/device/install/findPage`,
      param
    );
  },
  // 加注机物流信息分页
  refuelPageConditionQuery(param) {
    return http.post(
      `${baseUrl}/platform/logistics/device/pageConditionQuery`,
      param
    );
  },
  // /platform/logistics/device/getByLogisticsId
  // 加注机物流信息详情
  getByLogisticsId(param) {
    return http.post(
      `${baseUrl}/platform/logistics/device/getByLogisticsId`,
      param
    );
  },
  // 获取安装详情
  // /platform/device/install/getByLogisticsId
  getInstallByLogisticsId(param) {
    return http.post(
      `${baseUrl}/platform/device/install/getByLogisticsId`,
      param
    );
  },
  //获取安装验收详情
  // /platform/device/check/result/details
  getInstallCheckDetail(param) {
    return http.post(
      `${baseUrl}/platform/device/check/result/details`,
      param
    );
  },
  //整改记录
  // /platform/rectification/listRectificationByPage
  listRectificationByPage(param) {
    return http.post(
      `${baseUrl}/platform/rectification/listRectificationByPage`,
      param
    );
  },
};
