/**
 * @fileoverview 系统管理---账号设置 -- 数据列表
 * @author  蒋西园
 */
import React, { Component } from 'react';
import { Table, Modal, Icon } from 'antd';
import { map, join, isPlainObject } from 'lodash';
import './index.less';
const { confirm } = Modal;

class tableList extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: '工号',
        dataIndex: 'staffCode',
        render: text => (text ? text : '--'),
        width: 80,
      },
      {
        title: '姓名',
        dataIndex: 'staffName',
        render: text => (text ? text : '--'),
        width: 80,
      },
      {
        title: '登录账号',
        dataIndex: 'staffAccount',
        render: text => (text ? text : '--'),
        width: 100,
      },
      {
        title: '状态',
        dataIndex: 'status',
        render: text => {
          return (
            <span
              className={text ? 'status-btn in-use' : 'status-btn prohibit'}
            >
              {text ? '使用中' : '禁用'}
            </span>
          );
        },
      },
      {
        title: '部门',
        dataIndex: 'departmentName',
        render: text => (text ? text : '--'),
        width: 150,
      },
      {
        title: '岗位',
        dataIndex: 'postName',
        width: 80,
      },
      {
        title: '创建时间',
        dataIndex: 'gmtCreated',
        sorter: true,
        width: 130,
      },
      {
        title: '角色',
        dataIndex: 'roles',
        width: 500,
        render: text => {
          return text ? (
            <span>{text.map(res => res.rolesName).join('，')}</span>
          ) : (
            '--'
          );
        },
      },
      {
        title: '数据权限',
        dataIndex: 'departmentPermissions',
        width: 300,
        render: text => {
          const arr = [];
          Array.isArray(text) &&
            map(text, item => {
              isPlainObject(item) && arr.push(item.departmentSonName);
            });
          return join(arr, '，') || '--';
        },
      },
      {
        title: '操作',
        width: 120,
        render: record => {
          return (
            <span>
              <span
                onClick={this.handleProhibit.bind(this, record)}
                style={{
                  color: '#4D91FA',
                  cursor: 'pointer',
                }}
              >
                {record.status ? '禁用' : '启用'}
              </span>
              <span
                onClick={() => this.props.handleEditSubmit(record)}
                style={{
                  marginLeft: '16px',
                  color: '#4D91FA',
                  cursor: 'pointer',
                }}
              >
                编辑
              </span>
            </span>
          );
        },
      },
    ];
  }
  /**
   * 函数描述: 禁用按钮事件
   * @param {object} record 该账号所有信息 -- 后期要精简参数更换为唯一标识
   * @return {Object} 二次确认后统一返回父组件处理请求
   */
  handleProhibit = record => {
    if (record.status) {
      confirm({
        title: '您确定禁用该账号吗？',
        icon: <Icon type="question-circle" style={{ color: '#4D91FA' }} />,
        onOk: () => {
          this.props.handleProhibit(record);
        },
      });
    } else {
      confirm({
        title: '您确定启用该账号吗？',
        onOk: () => {
          this.props.handleProhibit(record);
        },
      });
    }
  };
  render() {
    return (
      <Table
        rowKey={record => {
          return record.staffId;
        }}
        loading={this.props.loading}
        columns={this.columns}
        dataSource={this.props.data}
        locale={{
          emptyText: (
            <div>
              <img
                style={{ width: 182, height: 94 }}
                src={require('../../../../../assets/image/no-data.png')}
                alt=""
              />
              <p
                style={{
                  fontSize: 14,
                  color: '#717E99',
                  lineHeight: '20px',
                }}
              >
                暂无数据
              </p>
            </div>
          ),
        }}
        pagination={{
          hideOnSinglePage: true,
          current: this.props.pageNo,
          total: this.props.total,
          pageSize: this.props.pageSize || 10,
        }}
        onChange={(pageNo, {}, sorter) =>
          this.props.handleChangePage(pageNo, sorter)
        }
      />
    );
  }
}
export default tableList;
