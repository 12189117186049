import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form, Input, Select, Row, Modal, Table } from "antd";
import moment from "moment";
import "./index.less";

const FormItem = Form.Item;
const Option = Select.Option;

class SearchUserlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        onChange: this.paginationChange
      },
      modalVisible: false
    };
  }
  //
  handleOk = () => {};

  handleCancel = () => {
    this.setState({
      modalVisible: false
    });
  };

  handleShow = () => {
    const { pagination } = this.state;
    pagination.current = 1;
    pagination.total = 0;
    this.setState(
      {
        modalVisible: true,
        pagination
      },
      () => {
        this.getList();
      }
    );
  };

  getList = (pageNo = 1, pageSize = 10) => {
    const { pagination } = this.state;
    this.$http["queryWorkOvertimeListByAttendanceDate"]({
      attendanceDate: this.props.date || moment().format("YYYY-MM"),
      staffId: this.props.staffId,
      pageNo,
      pageSize
    }).then(res => {
      if (res.code === 200) {
        pagination.total = res.data.total;
        this.setState({
          tableData: res.data.list || [],
          pagination
        });
      }
    });
  };

  paginationChange = (page, pageSize) => {
    const { pagination } = this.state;
    pagination.current = page;
    this.setState(
      {
        pagination
      },
      () => {
        this.getList(page, pageSize);
      }
    );
  };

  render() {
    const columns = [
      {
        title: "日期",
        dataIndex: "workOverDate",
        key: "workOverDate"
      },
      {
        title: "加班时间",
        dataIndex: "workOverStartTime",
        key: "workOverStartTime",
        render: (text, record) => {
          return (
            <span>{`${record.workOverStartTime}~${record.workOverEndTime}`}</span>
          );
        }
      },
      {
        title: "时长（小时）",
        dataIndex: "workOverDuration",
        key: "workOverDuration"
      }
    ];

    const { modalVisible, tableData } = this.state;

    return (
      <span className="">
        <Button type="link" onClick={this.handleShow}>
          加班记录
        </Button>
        <Modal
          visible={modalVisible}
          title="加班记录"
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              关闭
            </Button>
          ]}
        >
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={this.state.pagination}
          />
        </Modal>
      </span>
    );
  }
}

export default withRouter(SearchUserlist);
