/**
 * @fileoverview 系统管理---账号设置 -- 数据列表
 * @author  蒋西园
 */
import React, { Component } from "react";
import { Table } from "antd";
import "./index.less";

class tableList extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "标题",
        dataIndex: "contractCode",
        render: (text, record) => (
          <a
            style={{
              width: "90%",
              display: "inline-block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              wordBreak: "break-all",
            }}
            onClick={() => this.handleLink(record)}
          >{`${record.procTemplateName}-${record.entryStaff}-${record.departmentName}(单号：${record.businessKey})`}</a>
        ),
      },
      {
        title: "流程",
        width: 120,
        dataIndex: "procTemplateName",
      },
      {
        title: "当前节点",
        dataIndex: "taskDefName",
        width: 120,
        render: (text, record) => {
          return (
            <span
              className={`status-item item-color-${record.taskDefKey}`}
            >
              {text || "待批"}
            </span>
          );
        },
      },
      {
        title: "发起人",
        width: 120,
        dataIndex: "staffName",
      },
      {
        title: "接收时间",
        width: 120,
        dataIndex: "lastUpdatedTimeStr",
      },
      {
        title: "处理人",
        width: 120,
        dataIndex: "assigneeName",
      },
    ];
  }

  handleLink = (record) => {
    var example = window.location.protocol;
    var example1 = window.location.host;

    if (record.procTemplate === "OFFER_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/offerApproval?offerProcId=${record.businessKey}&procTemplate=${record.procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/offerApproval?offerProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "ENTRY_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/checkin?inductionProcId=${record.businessKey}&procTemplate=${record.procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/checkin?inductionProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "BECOME_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/becomeWorker?positiveProcId=${record.businessKey}&procTemplate=${record.procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/becomeWorker?positiveProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "DIMI_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/quit?dimissionProcId=${record.businessKey}&procTemplate=${record.procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/quit?dimissionProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "SPON_DIMI_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/autoQuit?dimissionProcId=${record.businessKey}&procTemplate=${record.procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/autoQuit?dimissionProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "LEAVE_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/leave?leaveProcId=${record.businessKey}&procTemplate=${record.procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/leave?leaveProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "ATTE_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/monthlyAttendance?attendanceProcId=${record.businessKey}&procTemplate=${record.procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/monthlyAttendance?attendanceProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    }
  };
  handleTableRow = (staffId) => {
    this.props.history.push({
      pathname: `detail`,
      state: { staffId },
    });
  };
  handleContractDetail = (contractId) => {
    this.props.history.push({
      pathname: `contractDetail`,
      state: { contractId },
    });
  };
  // 续签合同
  handleRenewContract = (staffId) => {
    this.props.handleRenewContract(staffId);
  };
  render() {
    return (
      <Table
        rowKey={(record) => {
          return record.id;
        }}
        loading={this.props.loading}
        columns={this.columns}
        dataSource={this.props.data}
        locale={{
          emptyText: (
            <div>
              <img
                style={{ width: 182, height: 94 }}
                src={require("../../../../../assets/image/no-data.png")}
                alt=""
              />
              <p
                style={{
                  fontSize: 14,
                  color: "#717E99",
                  lineHeight: "20px",
                }}
              >
                暂无数据
              </p>
            </div>
          ),
        }}
        pagination={{
          hideOnSinglePage: true,
          current: this.props.pageNo,
          total: this.props.total,
          pageSize: this.props.pageSize || 10,
        }}
        onChange={(pageNo) =>
          this.props.handleChangePage(pageNo)
        }
      />
    );
  }
}
export default tableList;
