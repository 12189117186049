/**
 * @fileoverview 人事管理---入职名单 -- 搜索条件
 * @author  黄洪月
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  TreeSelect,
} from "antd";
import moment from "moment";
import "./index.less";

const FormItem = Form.Item;   // Form.Item
const { SHOW_PARENT, TreeNode } = TreeSelect; // 下拉选择框--树状

class SearchAttendancelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departmentList: [], // 部门列表
      departmentIds: [],  // 部门id
      departmentName:undefined,  // 部门名称
      searchVale: {}      // 搜索条件
    };
  }
  componentDidMount() {
    this.querydepartmentId();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ searchVale: nextProps.searchVale })
    console.log(nextProps.searchVale);
  }
  // 提交表单
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll(
      (err, values) => {
        if (err) {
          return false;
        }
        
       getDepartmentName(this.state.departmentList)

        function getDepartmentName(item) {
           item.map(c_item => {
            if (c_item.departmentSonId == values.departmentIds) {
              values.departmentName = c_item.departmentSonName
            } else if(c_item.dsTree) {
              getDepartmentName(c_item.dsTree)
            }
          })
        }
        
        const data = { pageNo: 1, pageSize: 10 };
        // 去除含有undefined的item
        Object.keys(values).forEach((res) => {
          if (values[res] && res === "cycleMonth") {
            data.cycleMonth = moment(values[res]).format(
              "YYYY/MM"
            );
          } else if (values[res]) {
            data[res] = values[res];
          }
        });

        data.departmentIds = this.state.departmentIds;
        data.departmentName=values.departmentName
        console.log(data);
        this.props.handleSearchBtn(data);
      }
    );
  };

  // 重置
  handleReset = () => {
    this.setState({ searchVale:{staffName: "",departmentIds:[],departmentName:undefined} })
    this.props.form.resetFields();
    this.props.handleSearchBtn({
      departmentIds: [],
      pageNo: 1,
      pageSize: 10,
    });
  };

  /**
   * 函数描述: 查询部门名称
   * @param {string} type   类型（1：项目 2：部门）
   */
  querydepartmentId = () => {
    this.$http["departmentPermissions"]({}).then((res) => {
      if (res.code === 200) {
        this.setState({
          departmentList: res.data || [],
        });
      }
    });
  };

  // 选择部门
  onChange = (value) => {
    const departmentIds = [];
    if (value == null) {
      this.setState({ departmentIds: [] });
      return;
    }
    departmentIds.push(value);
    this.state.departmentList.map((item) => {
      if (item.departmentSonId === value && item.dsTree) {
        this.setState({departmentName:item.departmentSonName})
        getChildDepartmentId(item.dsTree);
      } else if (item.dsTree) {
        mapItem(item.dsTree);
      }
    });
    // 处理树状数据
    function mapItem(item) {
      item.map((c_item) => {
        if (c_item.departmentSonId === value) {
          if (c_item.dsTree) {
            getChildDepartmentId(c_item.dsTree);
          }
        } else if (item.dsTree) {
          mapItem(item.dsTree);
        }
      });
    }
    // 处理树状子级
    function getChildDepartmentId(item) {
      if (item.departmentSonId) {
        departmentIds.push(item.departmentSonId);
      }
      item.map((c_item) => {
        departmentIds.push(c_item.departmentSonId);
        if (c_item.dsTree) {
          getChildDepartmentId(c_item.dsTree);
        }
      });
    }
    this.setState({ departmentIds: departmentIds });
  };

  // 部门查询树状结构
  getTreeNode = (item) => {
    return (
      <TreeNode
        key={item.departmentSonId}
        value={item.departmentSonId}
        title={item.departmentSonName}
      >
        {item.dsTree
          ? item.dsTree.map((c_item) => {
              return this.getTreeNode(c_item);
            })
          : ""}
      </TreeNode>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {searchVale}=this.state
    return (
      <div className="search-form">
        <Form>
          <Row>
            <Col>
              <FormItem label="姓名" style={{marginLeft:16}}>
                {getFieldDecorator("staffName", {
                  rules: [
                    {
                      max: 32,
                      message: "请输入32字以内的名字",
                    },
                  ],
                  initialValue:searchVale.staffName
                })(
                  <Input placeholder="请输入" allowClear style={{width:"160px"}}/>
                )}
              </FormItem>
            </Col>
            <Col>
              <FormItem label="部门" style={{marginLeft:16}} className="departmentIds">
                {getFieldDecorator("departmentIds", {
                  initialValue:searchVale.departmentName
                })(
                  <TreeSelect
                    showSearch
                    style={{ height: 32,width:"160px" }}
                    value={this.state.departmentIds}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    placeholder="请选择"
                    allowClear
                    onChange={this.onChange}
                    showCheckedStrategy={SHOW_PARENT}
                    treeNodeFilterProp="title"
                  >
                    {(this.state.departmentList || []).map(
                      (item) => {
                        return this.getTreeNode(item);
                      }
                    )}
                  </TreeSelect>
                )}
              </FormItem>
            </Col>
            <Col style={{ marginLeft: 16 }}>
              <Button onClick={this.handleReset}>
                重置
              </Button>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 16 }}
              >
                查询
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(
  Form.create()(SearchAttendancelist)
);
