import React,{ Component } from 'react'
import './index.less'

import { Button, message } from 'antd';
import SearchForm from './component/searchForm' // 搜索条件
import Table from "./component/tableList"  		// 数据列表
import AddModal from './component/addAccount'	// 新增或修改公告
import E from 'wangeditor'
import util from '../../../utils/utils'

class SystemBulletin extends Component{
	constructor(props){
		super(props)
		this.state = {
			loading: false,
            dataList: [],     	// 公告列表数据
            total: 0,       	// 公告总数
            pageSize: 10,     	// 分页Size
            pageNo: 1,        	// 分页页码
			searchVale: {},   	// 搜索条件
			addModalShow: false,// 新增弹框显隐
			modalType: 0,		// 0：添加公告弹框 1：修改公告弹框
			accountItem: {},	// 编辑公告弹框回显数据
			disabled: false,	// 防重复点击
			sorter: {
				orderBy: '',	// 排序方式 1创建时间 2发布时间
				orderWay: ''	// 排序方式 1正序 2倒序
			},			
		}
	}
	componentDidMount(){
		this.queryTableList();
	}
	
    /**
     * 函数描述: 查询公告数据
     * @param {string} page   	页码
     * @param {string} pageSize 条数
     * @param {string} title   标题
     * @param {string} notificationStatus   已发布状态 0待发布 1已发布
     * @return {Object} 
     */
    queryTableList = (page=1, pageSize=10) =>{
        this.setState({
			loading: true
		},() => {
			const params = {
				...this.state.searchVale,
				page,
				pageSize,
				...this.state.sorter
			}
			this.$http.pageNotifications(params).then((res) => {
				if(res.code == 200){
					this.setState({
						loading: false,
						pageNo: res.data.pageNum,
						pageSize: res.data.pageSize,
						dataList: res.data.list,
						total: res.data.total
					})
				}
			}).catch((err) => {
				this.setState({
					loading: false,
				})
			})
		})
	}
    /**
     * 函数描述: 高级搜索
     * @param {Object} value   高级搜索条件
     * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
     */
    handleSearchBtn = (value) =>{
        this.setState({
            searchVale: {...value}
        },() => {
            this.queryTableList();
        })
	}
    /**
     * 函数描述: 切换列表页码
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} sorter 	排序方式 ASC： 正序 DESC: 倒叙
     * @return {Object} 请求pageNo页列表数据
     */
    handleChangePage = (page,sorter) => {
		const sorterOrder = {}
		console.log(sorter)
		if (sorter.order && sorter.columnKey) {
			sorterOrder['orderBy'] = {
				'gmtCreated' : 1,  // 创建时间
				'releaseTime' : 2  // 发布时间
			}[sorter.columnKey];
			sorterOrder['orderWay'] = {
				'ascend' : 1,  // 创建时间
				'descend' : 2  // 发布时间
			}[sorter.order];
		}
		this.setState({
			sorter: sorterOrder
		},() => {
			this.queryTableList(page.current,page.pageSize);
		})
	}
    /**
     * 函数描述: 新增公告
     * @return 打开新增弹框
     */
	handleAddSubmit = () => {
		this.refs.addModal.resetFields(); // 避免数据闪现
		this.setState({
			addModalShow: true,
			modalType: 0,
			accountItem: {}
		})
	}
	// 关闭弹框
	handleCancelModal = () => {
		this.setState({
			addModalShow: false
		})
	}
    /**
     * 函数描述: 取消发布/发布公告
     * @param {Object} notificationId 该公告id
     * @return {Object} 刷新列表数据 --- 需要跟后端确认修改状态后该数据的排序方式：1.刷新当前页数据 2.刷新第一页数据
     */
	handleReleased = (notificationId) => {
		this.$http.notificationRelease({
			notificationId
		}).then((res) => {
			if(res.code === 200) {
				this.queryTableList();
			}
		}).catch((err) => {

		})
	}
    /**
     * 函数描述: 删除公告
     * @param {Object} notificationId 该公告所id
     * @return {Object} 刷新当前页数据
     */
	handleDelete = (notificationId) => {
		this.$http.notificationDelete({notificationId}).then(res => {
			if (res.code === 200) {
				const { pageNo, pageSize } = this.state;
				this.queryTableList(pageNo,pageSize);
			}
		})
	}
    /**
     * 函数描述: 编辑公告
     * @return 打开新增弹框
     */
	handleEditSubmit = (notificationId) => {
		this.refs.addModal.resetFields(); // 避免数据闪现
		this.$http.getNotificationById({notificationId}).then(res => {
			if (res.code === 200) {
				res.data.content = util.HtmlUtil.htmlDecodeByRegExp(res.data.content)
				this.setState({
					addModalShow: true,
					modalType: 1,
					accountItem: res.data
				})
			}
		})
		
	}

    /**
     * 函数描述: 修改或新增确认
     * @param {string} type 弹框类型 0：新增 1：修改
     * @param {Object} value 弹框各项值
     * @return 
     */
	handleAddSure = (type,value) => {
		const { disabled } = this.state;
		value.content=util.HtmlUtil.htmlEncodeByRegExp(value.content)

		if(!disabled) {
			this.setState({
				disabled: true
			},() => {
				if (type === 0) { // 新增弹框
					this.$http.notificationInsert(value).then(res => {
						this.setState({
							disabled: false
						},() => {
							if (res.code === 200){
								this.handleCancelModal();
								this.queryTableList();
							}
						})
					})
					
				}else{
					this.$http.notificatioUpdate({
						...value,
						notificationId: this.state.accountItem.notificationId
					}).then(res => {
						this.setState({
							disabled: false
						},() => {
							if (res.code === 200){
								this.handleCancelModal();
								const { pageNo, pageSize } = this.state;
								this.queryTableList(pageNo,pageSize);
							}
						})
					})
				}
			})
		}
	}
	render(){
		const { dataList, total, pageNo, pageSize, loading, addModalShow, modalType, accountItem } = this.state;
		return (
		<div className="system-bulletin">
			<div className="crumbs">系统公告</div>
			{/* 搜索框 */}
			<SearchForm handleSearchBtn={this.handleSearchBtn} />
			{/* 公告数据 */}
			<div className="bulletin-box wrap-card">
				<Button className="addButton" type="primary"  onClick={this.handleAddSubmit}>添加</Button>
				<Table 
					loading={loading} 
					data={dataList} 
					total={total}
					pageNo={pageNo}
					pageSize={pageSize}
					handleChangePage={this.handleChangePage} // 切换页码
					handleReleased={this.handleReleased}	 // 取消发布/发布公告
					handleDelete={this.handleDelete}		 // 删除公告
					handleEditSubmit={this.handleEditSubmit} // 编辑公告
				/>
			</div>
			{/* 新增或修改公告 */}
			<AddModal
				ref="addModal"
				addModalShow={addModalShow}
				type={modalType}
				accountItem={accountItem}
				handleCancel={this.handleCancelModal}
				handleAddSure={this.handleAddSure}
			/>
		</div>
		)
	}
}


export default SystemBulletin
