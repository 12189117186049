/**
 * @fileoverview 系统管理---组织架构 -- 数据列表
 * @author  蒋西园
 */
import React, { Component } from 'react'
import { Table, Modal, Icon } from 'antd'
import './index.less'
const { confirm } = Modal

class tableList extends Component {
  constructor(props) {
    super(props)
    this.rowSelection = {
      checkStrictly: false,
      childrenColumnName: 'childList'
    }
    this.columns = [
      {
        title: '部门',
        width: '22%',
        dataIndex: 'departmentName',
        key: 'departmentName',
        render: text => text || '--'
      },
      {
        title: '部门负责人',
        width: '18%',
        dataIndex: 'departmentPrincipalName',
        key: 'departmentPrincipalName'
      },
      {
        title: '在职员工',
        width: '18%',
        dataIndex: 'onWorkNum',
        key: 'onWorkNum',
        // render: text => (text ? text : '--')
      },
      {
        title: '类型',
        width: '10%',
        dataIndex: 'type',
        key: 'type',
        render: text => (text ? (text == 1 ? '项目' : '部门') : '--')
      },
      {
        title: '操作',
        width: "16%",
        render: record => {
          return (
            <span>
              <span className={record.level==="5"?"disabble":""} onClick={() => this.props.handleAddNext(record)} style={{marginRight: '16px', color: '#4D91FA', cursor: 'pointer' }}>
                添加下级部门
              </span>
              <span onClick={() => this.props.handleEditSubmit(record)} className={record.level==="5"?"margin-right":"eidet"}>
                编辑
              </span>
              <span onClick={this.handleDelete.bind(this, record)} style={{ color: '#4D91FA', cursor: 'pointer' }}>
                删除
              </span>
            </span>
          )
        }
      }
    ]
  }

  /**
   * 函数描述: 删除按钮事件
   * @param {object} record 该组织架构所有信息 -- 后期要精简参数更换为唯一标识
   * @return {Object} 二次确认后统一返回父组件处理请求
   */
  handleDelete = record => {
    confirm({
      title: '删除',
      content: '您确定要删除该组织架构吗？',
      icon: <Icon type="delete" style={{ color: '#E5537B' }} />,
      onOk: () => {
        this.props.handleDelete(record.departmentId)
      }
    })
  }
  render() {
    return (
      <Table
        rowKey={record => {
          return record.departmentId
        }}
        loading={this.props.loading}
        columns={this.columns}
        childrenColumnName="chirdren"
        dataSource={this.props.data}
        locale={{
          emptyText: (
            <div>
              <img style={{ width: 182, height: 94 }} src={require('../../../../../assets/image/no-data.png')} alt="" />
              <p
                style={{
                  fontSize: 14,
                  color: '#717E99',
                  lineHeight: '20px'
                }}
              >
                暂无数据
              </p>
            </div>
          )
        }}
        pagination={false}
        // pagination={{
        //   hideOnSinglePage: true,
        //   current: this.props.pageNo,
        //   total: this.props.total,
        //   pageSize: this.props.pageSize || 10,
        //   onChange: (pageNo, pageSize) => this.props.handleChangePage(pageNo, pageSize)
        // }}
      />
    )
  }
}
export default tableList
