/**
 * @fileoverview 工作台-未完善名单--列表
 * @author  赵娟
 */
import React, { Component } from "react";
import { Table } from "antd";
import "./index.less";

class tableList extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "工号",
        dataIndex: "staffCode",
      },
      {
        title: "姓名",
        dataIndex: "staffName",
      },
      {
        title: "部门",
        dataIndex: "departmentName",
        render: (text) => (text ? text : "--"),
      },
      {
        title: "岗位",
        dataIndex: "postName",
        render: (text) => (text ? text : "--"),
      },
      {
        title: "入职时间",
        dataIndex: "inductionTime",
        render: (text) => (text ? text : "--"),
      },
      {
        title: "是否完善",
        dataIndex: "perfect",
        render: (text, record) => {
          return (
            <span className="status-item item-color-ATT0001">
              {text || "待完善"}
            </span>
          );
        },
      },
    ];
  }

  render() {
    console.log(this.props.data);
    return (
      <Table
        rowKey={(record, index) => {
          return index;
        }}
        loading={this.props.loading}
        columns={this.columns}
        dataSource={this.props.data}
        locale={{
          emptyText: (
            <div>
              <img
                style={{ width: 182, height: 94 }}
                src={require("../../../../../assets/image/no-data.png")}
                alt=""
              />
              <p
                style={{
                  fontSize: 14,
                  color: "#717E99",
                  lineHeight: "20px",
                }}
              >
                暂无数据
              </p>
            </div>
          ),
        }}
        pagination={{
          hideOnSinglePage: true,
          current: this.props.pageNo,
          total: this.props.total,
          pageSize: this.props.pageSize || 10,
          onChange: (pageNo, pageSize) =>
            this.props.handleChangePage(pageNo, pageSize),
        }}
      />
    );
  }
}
export default tableList;
