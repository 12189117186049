import http from './fetch'
const baseUrl = process.env.API_URL ? process.env.API_URL : '';

export default {
    // 商户分页
    merchantPageConditionQuery(param) {
        return http.post(`${baseUrl}/platform/merchant/pageConditionQuery`, param)
    },
    // 商户启用/停用
    merchantUpdateEnableStatus(param) {
        return http.post(`${baseUrl}/platform/merchant/updateEnableStatus`, param, true)
    },
    // 新增商户
    addMerchant(param) {
        return http.post(`${baseUrl}/platform/merchant/addMerchant`, param, true)
    },
    // 修改商户
    updateByMerchantId(param) {
        return http.post(`${baseUrl}/platform/merchant/updateByMerchantId`, param, true)
    },
    // 删除商户
    deleteByMerchantId(param) {
        return http.post(`${baseUrl}/platform/merchant/deleteByMerchantId`, param, true)
    },
    // 查询下级商户
    listByParentId(param) {
        return http.post(`${baseUrl}/platform/merchant/listByParentId`, param,)
    },
    // 商户类型初始化菜单
    listMenuForMerchantType(param) {
        return http.post(`${baseUrl}/platform/menu/listMenuForMerchantType`, param)
    },
    // 商户详情
    getByMerchantId(param) {
        return http.post(`${baseUrl}/platform/merchant/getByMerchantId`, param)
    },
    
    // ========================
    // 商户类型分页
    pageConditionQuery(param) {
        return http.post(`${baseUrl}/platform/merchant/type/pageConditionQuery`, param)
    },
    // 商户类型停用/启用
    updateEnableStatus(param) {
        return http.post(`${baseUrl}/platform/merchant/type/updateEnableStatus`, param, true)
    },
    // 新增商户类型
    addMerchantType(param) {
        return http.post(`${baseUrl}/platform/merchant/type/addMerchantType`, param, true)
    },
    // 修改商户类型
    updateByMerchantTypeId(param) {
        return http.post(`${baseUrl}/platform/merchant/type/updateByMerchantTypeId`, param, true)
    },
    // 删除商户类型
    deleteByMerchantTypeId(param) {
        return http.post(`${baseUrl}/platform/merchant/type/deleteByMerchantTypeId`, param, true)
    },
    // 启用/禁用状态查询，不传查所有
    listByStatus(param) {
        return http.post(`${baseUrl}/platform/merchant/type/listByStatus`, param)
    }, 
    // 菜单树
    listMenuForMerchant(param) {
        return http.post(`${baseUrl}/platform/menu/listMenuForMerchant`, param)
    }, 
    // 商户类型详情
    getByMerchantTypeId(param) {
        return http.post(`${baseUrl}/platform/merchant/type/getByMerchantTypeId`, param)
    }, 
    
    
    

}