// 个人信息
export const personalInformation = [
  {
    key: 'baseInformation',
    value: '基本信息',
    children: [
      {
        key: 'staffName1',
        detailKey: 'staffName1',
        value: '姓名',
        inputTyle: 'input',
        placeholder: '请输入',
        rules: [
          {
            required: true,
            whitespace: true,
            message: '请输入姓名',
          },
          {
            max: 32,
            message: '姓名最大长度为32个字符',
          },
          {
            pattern: new RegExp(/^[A-Za-z\u4e00-\u9fa5]+$/, 'g'),

            message: '仅支持输入中英文',
          },
        ],
      },
      {
        key: 'staffAccount',
        detailKey: 'staffAccount',
        value: '登录账号',
        inputTyle: 'input',
        placeholder: '请输入',
        rules: [
          {
            required: true,
            whitespace: true,
            message: '请输入登录账号',
          },
          {
            max: 32,
            message: '姓名最大长度为32个字符',
          },
          {
            pattern: new RegExp(/^[a-zA-Z0-9]*$/, 'g'),
            message: '仅支持输入英文和数字',
          },
        ],
      },
      {
        key: 'certificatesType',
        detailKey: 'certificatesTypeName',
        value: '证件类型',
        inputTyle: 'select',
        placeholder: '请选择',
        dicGroup: 'ID_TYPE',
        rules: [
          {
            required: true,
            message: '请选择证件类型',
          },
        ],
      },
      {
        key: 'certificatesNumber',
        detailKey: 'certificatesNumber',
        value: '证件号码',
        inputTyle: 'input',
        placeholder: '请输入',
        rules: [
          {
            max: 18,
            message: '证件号码最大长度为18个字符',
          },
          {
            required: true,
            message: '请输入证件号码',
          },
          {
            pattern: new RegExp(/^[0-9A-Z]*$/, 'g'),
            message: '请输入正确证件号码',
          },
        ],
      },
      {
        key: 'sex',
        detailKey: 'sexName',
        value: '性别',
        inputTyle: 'select',
        placeholder: '请选择',
        dicGroup: 'GENDER',
        rules: [
          {
            required: true,
            message: '请选择性别',
          },
        ],
      },
      {
        key: 'marriageStatus',
        detailKey: 'marriageStatusName',
        value: '婚姻情况',
        inputTyle: 'select',
        placeholder: '请选择',
        dicGroup: 'MARITAL_STATUS',
        rules: [
          {
            required: true,
            message: '请选择婚姻情况',
          },
        ],
      },
      {
        key: 'age',
        detailKey: 'age',
        value: '年龄',
        inputTyle: 'span',
      },
      {
        key: 'birthday',
        detailKey: 'birthday',
        value: '出生日期',
        inputTyle: 'datePicker',
        placeholder: '请选择',
        rules: [
          {
            required: true,
            message: '请选择出生日期',
          },
        ],
      },
      {
        key: 'actualBirthday',
        detailKey: 'actualBirthday',
        value: '实际生日',
        inputTyle: 'datePicker',
        placeholder: '请选择',
      },
      {
        key: 'constellation',
        detailKey: 'constellationName',
        value: '星座',
        inputTyle: 'select',
        placeholder: '请选择',
        dicGroup: 'CONSTELLATION',
      },
      {
        key: 'email',
        detailKey: 'email',
        value: '邮箱',
        inputTyle: 'input',
        placeholder: '请输入',
        rules: [
          {
            required: true,
            whitespace: true,
            message: '请输入邮箱',
          },
          {
            max: 32,
            message: '邮箱最大长度为32个字符',
          },
          {
            // pattern: new RegExp(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, "g"),
            pattern: new RegExp(
              /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi,
              'g'
            ),
            message: '邮箱格式不正确',
          },
        ],
      },
      {
        key: 'phone',
        detailKey: 'phone',
        value: '手机',
        inputTyle: 'input',
        placeholder: '请输入',
        rules: [
          {
            required: true,
            whitespace: true,
            message: '请输入手机号',
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入有效的手机号',
          },
        ],
      },
      {
        key: 'politicalOutlook',
        detailKey: 'politicalOutlookName',
        value: '政治面貌',
        inputTyle: 'select',
        placeholder: '请选择',
        required: true,
        dicGroup: 'POLITICAL_ORIENTATION',
        rules: [
          {
            required: true,
            message: '请选择政治面貌',
          },
        ],
      },
      {
        key: 'nativePlaceProvince',
        detailKey: 'nativePlaceProvinceName',
        value: '籍贯',
        inputTyle: 'input',
        placeholder: '请输入',
        multiple: false,
        required: true,
        rules: [
          {
            required: true,
            message: '请输入籍贯',
          },
          {
            max: 20,
            message: '籍贯最大长度为20个字符',
          },
        ],
      },
      {
        key: 'residenceNature',
        detailKey: 'residenceNatureName',
        value: '户口性质',
        inputTyle: 'select',
        placeholder: '请选择',
        required: true,
        dicGroup: 'REGISTERED_RESIDENCE_NATURE',
        rules: [
          {
            required: true,
            message: '请选择户口性质',
          },
        ],
      },
      {
        key: 'nation',
        detailKey: 'nationName',
        value: '民族',
        inputTyle: 'select',
        placeholder: '请选择',
        required: true,
        // dicGroup: 'REGISTERED_RESIDENCE_NATURE',
        rules: [
          {
            required: true,
            message: '请选择民族',
          },
        ],
      },
      {
        key: 'registrationAddress',
        detailKey: 'registrationAddress',
        value: '户籍地址',
        inputTyle: 'input',
        placeholder: '请输入(请精确到门牌号)',
        required: true,
        onlyLine: true,
        rules: [
          {
            required: true,
            message: '请输入户籍地址',
          },
          {
            max: 128,
            message: '户籍地址最大长度为128个字符',
          },
        ],
      },
      {
        key: 'nowAddress',
        detailKey: 'nowAddress',
        value: '现地址',
        inputTyle: 'input',
        placeholder: '请输入(请精确到门牌号)',
        required: true,
        onlyLine: true,
        rules: [
          {
            required: true,
            message: '请输入现地址',
          },
          {
            max: 128,
            message: '现地址最大长度为128个字符',
          },
        ],
      },
    ],
  },
  {
    key: 'emergencyContact',
    value: '紧急联系人',
    children: [
      {
        key: 'urgentContactPeople',
        detailKey: 'urgentContactPeople',
        value: '紧急联系人',
        inputTyle: 'input',
        placeholder: '请输入',
        required: true,
        rules: [
          {
            required: true,
            message: '请输入紧急联系人',
          },
          {
            max: 32,
            message: '姓名最大长度为32个字符',
          },
          {
            pattern: new RegExp(/^[A-Za-z\u4e00-\u9fa5]+$/, 'g'),
            message: '仅支持输入中英文',
          },
        ],
      },
      {
        key: 'relationship',
        detailKey: 'relationshipName',
        value: '关系',
        inputTyle: 'select',
        placeholder: '请选择',
        required: true,
        dicGroup: 'RELATION_EMERGENCY_CONTCAT',
        rules: [
          {
            required: true,
            message: '请选择关系',
          },
        ],
      },
      {
        key: 'urgentContacPhone',
        detailKey: 'urgentContacPhone',
        value: '手机号',
        inputTyle: 'input',
        placeholder: '请输入',
        required: true,
        rules: [
          {
            required: true,
            whitespace: true,
            message: '请输入手机号',
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入有效的手机号',
          },
        ],
      },
    ],
  },
  {
    key: 'bankInformation',
    value: '银行信息',
    children: [
      {
        key: 'bankNumber',
        detailKey: 'bankNumber',
        value: '银行卡号',
        inputTyle: 'input',
        placeholder: '请输入',
        required: true,
        rules: [
          {
            max: 19,
            message: '请输入正确银行卡号',
          },
          {
            pattern: /^(\d{0,19})$/,
            message: '请输入0-19位数字',
          },
        ],
      },
      {
        key: 'bankInformation',
        detailKey: 'bankInformation',
        value: '开户行信息',
        inputTyle: 'input',
        placeholder: '请输入',
        rules: [
          {
            required: false,
            whitespace: true,
            message: '请输入开户行信息',
          },
          {
            max: 64,
            message: '开户行信息最大长度为64个字符',
          },
          {
            pattern: /^[A-Za-z\u4e00-\u9fa5]+$/,
            message: '请输入中英文',
          },
        ],
      },
    ],
  },
  {
    key: 'education',
    value: '教育',
    children: [
      {
        key: 'graduationTime',
        detailKey: 'graduationTime',
        value: '毕业时间',
        inputTyle: 'monthPicker',
        placeholder: '请输入',
        required: true,
        rules: [
          {
            required: true,
            message: '请输入毕业时间',
          },
        ],
      },
      {
        key: 'graduationSchool',
        detailKey: 'graduationSchool',
        value: '毕业院校',
        inputTyle: 'input',
        placeholder: '请输入',
        required: true,
        rules: [
          {
            required: true,
            message: '请输入毕业院校',
          },
          {
            max: 64,
            message: '毕业院校最大长度为64个字符',
          },
          {
            pattern: /^(?!\s).*/,
            message: '首不可为空格',
          },
        ],
      },
      {
        key: 'highestEducation',
        detailKey: 'highestEducationName',
        value: '最高学历',
        inputTyle: 'select',
        placeholder: '请选择',
        required: true,
        dicGroup: 'HIGHEST_DEGREE',
        rules: [
          {
            required: true,
            message: '请输入最高学历',
          },
        ],
      },
      {
        key: 'bachelorScience',
        detailKey: 'bachelorScienceName',
        value: '学位',
        inputTyle: 'select',
        placeholder: '请选择',
        required: false,
        dicGroup: 'DEGREE',
      },
      {
        key: 'profession',
        detailKey: 'profession',
        value: '专业',
        inputTyle: 'input',
        placeholder: '请输入',
        rules: [
          {
            required: false,
            whitespace: true,
            message: '请输入专业信息',
          },
        ],
      },
      {
        key: 'educationCertificateNumber',
        detailKey: 'educationCertificateNumber',
        value: '学历证书编号',
        inputTyle: 'input',
        placeholder: '请输入',
        rules: [
          {
            required: false,
            message: '请输入学历证书编号信息',
          },
          {
            pattern: /^(?!\s).*/,
            message: '首尾不可为空格',
          },
          {
            max: 32,
            message: '学历证书编号最大长度为32个字符',
          },
        ],
      },
      {
        key: 'qualificationsCertificate',
        detailKey: 'qualificationsCertificate',
        value: '资格证书',
        inputTyle: 'input',
        placeholder: '请输入',
        rules: [
          {
            required: false,
            message: '请输入资格证书信息',
          },
          {
            pattern: /^(?!\s).*/,
            message: '首尾不可为空格',
          },
          {
            max: 128,
            message: '资格证书最大长度为128个字符',
          },
        ],
      },
    ],
  },
  {
    key: 'workExperience',
    value: '工作经历',
  },
  {
    key: 'uploadDocuments',
    value: '证件上传',
    info: '只能上传jpg、jpeg、png文件，且不超过2M',
    uploadChildren: [
      {
        key: 'byz',
        value: '毕业证',
        required: true,
      },
      {
        key: 'xwz',
        value: '学位证',
        required: false,
      },
      {
        key: 'sfzz',
        value: '身份证正面',
        required: true,
      },
      {
        key: 'sfzb',
        value: '身份证反面',
        required: true,
      },
    ],
  },
  {
    key: 'other',
    value: '其他附件',
    info: '只能上传jpg、jpeg、png文件，且不超过2M',
  },
  {
    key: 'supplement',
    value: '补充说明',
  },
];
// 岗位薪资
export const postSalary = [
  {
    key: 'onJobSituation',
    value: '在职情况',
    children: [
      {
        key: 'isProbation',
        detailKey: 'isProbation',
        value: '是否试用期',
        inputTyle: 'radio',
        radioData: [
          { value: '1', label: '是' },
          { value: '0', label: '否' },
        ],
        rules: [
          {
            required: true,
            message: '请选择是否试用期',
          },
        ],
      },
      {
        key: 'isLeave',
        detailKey: 'isLeave',
        value: '是否离职',
        inputTyle: 'radio',
        radioData: [
          { value: '1', label: '是' },
          { value: '0', label: '否' },
        ],
      },
      {
        key: 'leaveTime',
        detailKey: 'leaveTime',
        value: '离职时间',
        inputTyle: 'datePicker',
        placeholder: '请选择',
      },
      {
        key: 'leaveReason',
        detailKey: 'leaveReason',
        value: '离职原因',
        inputTyle: 'textArea',
        placeholder: '请输入',
        onlyLine: true,
      },
    ],
  },
  {
    key: 'baseInformation',
    value: '岗位信息',
    children: [
      {
        key: 'departmentId',
        detailKey: 'departmentName',
        value: '部门名称',
        inputTyle: 'treeSelect',
        placeholder: '请选择',
        rules: [
          {
            required: true,
            message: '请选择部门',
          },
        ],
      },
      {
        key: 'postIdOK',
        detailKey: 'postName',
        value: '岗位',
        inputTyle: 'select',
        placeholder: '请选择',
        rules: [
          {
            required: true,
            message: '请选择岗位',
          },
        ],
      },
      {
        key: 'occupationId',
        detailKey: 'occupationName',
        value: '职级',
        inputTyle: 'select',
        placeholder: '请选择',
        // rules: [
        //   {
        //     required: true,
        //     message: '请选择职级',
        //   },
        // ],
      },
      {
        key: 'belongDeliverManager',
        detailKey: 'belongDeliverManager',
        value: '直属上级',
        inputTyle: 'SelectStaff',
      },
      {
        key: 'turnTime',
        detailKey: 'turnTime',
        value: '转正时间',
        inputTyle: 'datePicker',
        placeholder: '请选择',
        rules: [
          {
            required: true,
            message: '请选择转正时间',
          },
        ],
      },
      {
        key: 'probationTime',
        detailKey: 'probationTime',
        value: '试用期时长',
        inputTyle: 'InputNumber',
        placeholder: '请输入',
        max: '999',
        min: '0',
        precision: 0,
        suffix: '个月',
        rules: [
          {
            required: true,
            message: '请输入试用期时长',
          },
        ],
      },
      {
        key: 'onboardingTime',
        detailKey: 'onboardingTime',
        value: '入职时间',
        inputTyle: 'datePicker',
        placeholder: '请选择',
        rules: [
          {
            required: true,
            message: '请选择入职时间',
          },
        ],
      },
      {
        key: 'socialSecurityfundPayLocalProvince',
        detailKey: 'socialSecurityfundPayLocal',
        value: '社保公积金缴纳地',
        inputTyle: 'cascader',
        placeholder: '省市二级',
        multiple: false,
        rules: [
          {
            required: true,
            message: '请选择社保公积金缴纳地',
          },
        ],
      },
      {
        key: 'isSchoolRecruitment',
        detailKey: 'isSchoolRecruitment',
        value: '招聘方式',
        inputTyle: 'radio',
        required: true,
        radioData: [
          { value: '1', label: '校招' },
          { value: '0', label: '社招' },
        ],
        rules: [
          {
            required: true,
            message: '请选择是否校招',
          },
        ],
      },
      {
        key: 'work',
        detailKey: 'work',
        value: '工作城市',
        inputTyle: 'cascader',
        placeholder: '请选择',
        multiple: false,
        rules: [
          {
            required: true,
            message: '请选择工作城市',
          },
        ],
      },
    ],
  },
  {
    key: 'salaryWelfare',
    value: '薪资福利',
    children: [
      //     {
      //     key: 'probationSalary',
      //     detailKey: 'probationSalary',
      //     value: '试用期薪资',
      //     money: true,
      //     inputTyle: 'InputNumber',
      //     placeholder: '请输入',
      //     suffix: '元',
      //     max:'999999',
      //     min:'0',
      //     show:true,
      //     precision:2,
      //     rules: [{
      //         required: true,
      //         message: '请输入试用期薪资'
      //     }]
      // },{
      //     key: 'trunSalary',
      //     detailKey: 'trunSalary',
      //     value: '转正薪资',
      //     money: true,
      //     inputTyle: 'InputNumber',
      //     placeholder: '请输入',
      //     suffix: '元',
      //     max:'999999',
      //     min:'0',
      //     show:true,
      //     precision:2,
      //     rules: [{
      //         required: true,
      //         message: '请输入转正薪资'
      //     }]
      // },{
      //     key: 'clientRaise',
      //     detailKey: 'clientRaise',
      //     value: '客户认可的加薪',
      //     money: true,
      //     inputTyle: 'InputNumber',
      //     placeholder: '请输入',
      //     max:'999999',
      //     min:'0',
      //     show:true,
      //     precision:2,
      //     suffix: '元'
      // },{
      //     key: 'companyRaise',
      //     detailKey: 'companyRaise',
      //     value: '公司加薪',
      //     money: true,
      //     inputTyle: 'InputNumber',
      //     placeholder: '请输入',
      //     max:'999999',
      //     min:'0',
      //     show:true,
      //     precision:2,
      //     suffix: '元'
      // },{
      //     key: 'totalTurn',
      //     detailKey: 'totalTurn',
      //     value: '合计转正',
      //     money: true,
      //     show:true,
      //     suffix: '元',
      //     inputTyle: 'span'
      // },
      {
        key: 'remainingAnnualLeave',
        detailKey: 'remainingAnnualLeave',
        value: '剩余年假',
        inputTyle: 'InputNumber',
        placeholder: '请输入',
        max: '999',
        min: '0',
        precision: 0,
        suffix: '天',
        rules: [
          {
            required: true,
            message: '请输入年假',
          },
        ],
      },
      {
        key: 'socialSecurityBase',
        detailKey: 'socialSecurityBase',
        value: '社保基数',
        money: true,
        inputTyle: 'InputNumber',
        placeholder: '请输入',
        max: '999999',
        min: '0',
        precision: 2,
        suffix: '元',
        rules: [
          {
            required: true,
            message: '请输入社保基数',
          },
        ],
      },
      {
        key: 'providentFundBase',
        detailKey: 'providentFundBase',
        value: '公积金基数',
        money: true,
        inputTyle: 'InputNumber',
        placeholder: '请输入',
        max: '999999',
        min: '0',
        precision: 2,
        suffix: '元',
        rules: [
          {
            required: true,
            message: '请输入公积金基数',
          },
        ],
      },
      {
        key: 'businessSettlement',
        detailKey: 'businessSettlement',
        value: '商务结算',
        money: true,
        inputTyle: 'InputNumber',
        placeholder: '请输入',
        max: '999999',
        min: '0',
        precision: 2,
        suffix: '元',
        rules: [
          {
            required: true,
            message: '请输入商务结算',
          },
        ],
      },
      {
        key: 'remark',
        detailKey: 'remark',
        value: '备注',
        inputTyle: 'textArea',
        placeholder: '请输入',
        required: true,
        onlyLine: true,
        rules: [
          {
            required: false,
            message: '请输入备注',
          },
          {
            pattern: /^(?!\s).*/,
            message: '首尾不可为空格',
          },
          {
            max: 128,
            message: '备注最大长度为128个字符',
          },
        ],
      },
    ],
  },
  {
    key: 'contract',
    value: '合同',
    children: [
      {
        key: 'contractType',
        detailKey: 'contractTypeName',
        value: '合同类型',
        inputTyle: 'select',
        placeholder: '请选择',
        required: true,
        dicGroup: 'CONTRACT_NATURE',
        rules: [
          {
            required: true,
            message: '请选择合同类型',
          },
        ],
      },
      {
        key: 'hireNature',
        detailKey: 'hireNatureName',
        value: '用工性质',
        inputTyle: 'span',
        dicGroup: 'EMPLOYMENT_NATURE',
      },
      {
        key: 'signTotalFrequency',
        detailKey: 'signTotalFrequency',
        value: '签订总次数',
        inputTyle: 'InputNumber',
        placeholder: '请输入',
        max: '999',
        min: '0',
        precision: 0,
        rules: [
          {
            required: true,
            message: '请输入签订总次数',
          },
        ],
      },
      {
        key: 'turnTime1',
        detailKey: 'turnTime1',
        value: '转正日期',
        inputTyle: 'span',
      },
      {
        key: 'firstContractStartday',
        detailKey: 'firstContractStartday',
        value: '首次合同起始日',
        inputTyle: 'datePicker',
        placeholder: '请选择',
        rules: [
          {
            required: true,
            message: '请选择首次合同起始日',
          },
        ],
      },
      {
        key: 'firstContractMaturityday',
        detailKey: 'firstContractMaturityday',
        value: '首次合同到期日',
        inputTyle: 'datePicker',
        placeholder: '请选择',
        rules: [
          {
            required: true,
            message: '请选择首次合同起始日',
          },
        ],
      },
      {
        key: 'contractStartday',
        detailKey: 'contractStartday',
        value: '最新合同起始日',
        inputTyle: 'datePicker',
        placeholder: '请选择',
        required: true,
        rules: [
          {
            required: true,
            message: '请选择最新合同起始日',
          },
        ],
      },
      {
        key: 'contractMaturityday',
        detailKey: 'contractMaturityday',
        value: '最新合同到期日',
        inputTyle: 'datePicker',
        placeholder: '请选择',
        required: true,
        rules: [
          {
            required: true,
            message: '请选择最新合同到期日',
          },
        ],
      },
      {
        key: 'isOtherCompanyTransfer',
        detailKey: 'isOtherCompanyTransfer',
        value: '是否为他公司转签',
        inputTyle: 'radio',
        radioData: [
          { value: '1', label: '是' },
          { value: '0', label: '否' },
        ],
        rules: [
          {
            required: true,
            message: '请选择是否为他公司转签',
          },
        ],
      },
      {
        key: 'lastCompanyTime',
        detailKey: 'lastCompanyTime',
        value: '上家公司时间',
        inputTyle: 'datePicker',
        placeholder: '请选择',
        rules: [
          {
            required: true,
            message: '请选择上家公司时间',
          },
        ],
      },
      {
        key: 'uploadContract',
        detailKey: 'uploadContract',
        value: '合同附件',
        inputTyle: 'upload',
      },
    ],
  },
];
// 合同详情
export const contractItem = [
  {
    key: 'staffDetail',
    value: '员工',
    children: [
      {
        key: 'staffName',
        value: '姓名',
      },
      {
        key: 'staffCode',
        value: '工号',
      },
      {
        key: 'departmentPrincipalName',
        value: '直属上级',
      },
      {
        key: 'departmentName',
        value: '部门名称',
      },
      {
        key: 'postName',
        value: '岗位',
      },
      {
        key: 'occupationName',
        value: '职级',
      },
    ],
  },
  {
    key: 'contract',
    value: '合同',
    children: [
      {
        key: 'contractCode',
        value: '合同编号',
      },
      {
        key: 'contractTypeName',
        value: '合同类型',
      },
      {
        key: 'hireNatureName',
        value: '用工性质',
      },
      {
        key: 'turnTime',
        value: '转正日期',
      },
      {
        key: 'secretary',
        value: '司龄',
      },
      {
        key: 'signTotalFrequency',
        value: '签订总次数',
      },
      {
        key: 'firstContractStartday',
        value: '首次合同起始日',
      },
      {
        key: 'firstContractMaturityday',
        value: '首次合同到期日',
      },
      {
        key: 'contractStartday',
        value: '合同起始日',
      },
      {
        key: 'contractMaturityday',
        value: '合同到期日',
      },
      {
        key: 'isOtherCompanyTransfer',
        value: '是否为他公司转签',
      },
      {
        key: 'lastCompanyTime',
        value: '上家公司时间',
      },
    ],
  },
];
// 数据字典关系
export const selectList = {
  // 部门名称
  departmentIdList: [
    {
      value: '123',
      text: 'hr部门',
    },
    {
      value: '132',
      text: '流程部',
    },
  ],
  // 岗位名称
  postIdList: [
    {
      value: 'gw1',
      text: '岗位1',
    },
    {
      value: 'gw2',
      text: '岗位2',
    },
  ],
  zjList: [
    {
      value: 'zj1',
      text: '职级1',
    },
    {
      value: 'zj2',
      text: '职级2',
    },
  ],
  htlxList: [
    {
      value: 'htlx1',
      text: '合同类型1',
    },
  ],
  ygxzList: [
    {
      value: 'ygxz1',
      text: '用工性质1',
    },
  ],
  sexList: [
    {
      value: '0',
      text: '女',
    },
    {
      value: '1',
      text: '男',
    },
  ],
  hyqkList: [
    {
      value: '0',
      text: '未婚',
    },
    {
      value: '1',
      text: '已婚',
    },
  ],
  zjlxList: [
    {
      value: '0',
      text: '身份证',
    },
    {
      value: '1',
      text: '护照',
    },
    {
      value: '2',
      text: '港澳通行证',
    },
  ],
  zzmmList: [
    {
      value: '0',
      text: '党员',
    },
    {
      value: '1',
      text: '预备党员',
    },
    {
      value: '2',
      text: '团员',
    },
    {
      value: '3',
      text: '群众',
    },
    {
      value: '4',
      text: '其他党派',
    },
  ],
  hkxzList: [
    {
      value: '0',
      text: '杭州社保本地城镇主城区',
    },
    {
      value: '1',
      text: '杭州社保本地农村主城区',
    },
    {
      value: '2',
      text: '杭州社保外地城镇省内',
    },
    {
      value: '3',
      text: '杭州社保外地城镇省外',
    },
    {
      value: '4',
      text: '杭州社保外地农村省内',
    },
    {
      value: '5',
      text: '杭州社保外地农村省外',
    },
    {
      value: '6',
      text: '非杭州社保',
    },
    {
      value: '7',
      text: '无',
    },
  ],
  nationList: [
    {
      value: '0',
      text: '父子',
    },
    {
      value: '1',
      text: '母子',
    },
    {
      value: '2',
      text: '夫妻',
    },
    {
      value: '3',
      text: '兄弟姐妹',
    },
    {
      value: '4',
      text: '亲戚',
    },
  ],
  gxList: [
    {
      value: '0',
      text: '父子',
    },
    {
      value: '1',
      text: '母子',
    },
    {
      value: '2',
      text: '夫妻',
    },
    {
      value: '3',
      text: '兄弟姐妹',
    },
    {
      value: '4',
      text: '亲戚',
    },
    {
      value: '5',
      text: '朋友',
    },
  ],
  zgxlList: [
    {
      value: '0',
      text: '博士',
    },
    {
      value: '1',
      text: '研究生',
    },
    {
      value: '2',
      text: '本科',
    },
    {
      value: '3',
      text: '大专',
    },
    {
      value: '4',
      text: '中专',
    },
    {
      value: '5',
      text: '高中及以下',
    },
    {
      value: '6',
      text: '无',
    },
  ],
  xwList: [
    {
      value: '0',
      text: '博士',
    },
    {
      value: '1',
      text: '硕士',
    },
    {
      value: '2',
      text: '学士',
    },
    {
      value: '3',
      text: '无',
    },
  ],
};
