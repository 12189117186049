import http from './fetch'
import machine from './machine'
import merchant from './merchant'
import mainBoard from './mainBoard'
import rolePermissions from './rolePermissions'
import refuelInstallManagement from './refuelInstallManagement'
import workbench from './workbench'
import system from './system'
import personnelMatters from './personnelMatters'
import personalInformation from './personalInformation'
const baseUrl = process.env.API_URL ? process.env.API_URL : ''

export default {
  // 登录
  login(param) {
    return http.post(`${baseUrl}/personnel-bsm/pc/user/login`, param)
  },
  // 退出登录
  logout(param, callbak) {
    return http.post(`${baseUrl}/personnel-bsm/pc/user/logout`, param)
  },
  // 发送验证码
  sendEmailCode(param, callbak) {
    return http.post(`${baseUrl}/personnel-bsm/pc/user/login/sendEmailCode`, param, callbak)
  },
  // 修改密码
  updatePwdByEmail(param, callbak) {
    return http.post(`${baseUrl}/personnel-bsm/pc/user/login/updatePwdByEmail`, param, true, callbak)
  },
  // 忘记密码
  forgetUserPassword(param, callbak) {
    return http.post(`${baseUrl}/platform/user/forgetUserPassword`, param, true, callbak)
  },
  //部门树
  departmentListForTree(param, callbak) {
    return http.post(`${baseUrl}/personnel-bsm/pc/department/listForTree`, param, false, callbak)
  },
  //部门树二
  departmentlistForRoleTree(param, callbak) {
    return http.post(`${baseUrl}/personnel-bsm/pc/department/listForRoleTree`, param, false, callbak)
  },
  //部门树三
  departmentPermissions(param, callbak) {
    return http.post(`${baseUrl}/personnel-bsm//pc/department/departmentPermissions`, param, false, callbak)
  },

  // 获取菜单
  listMenuForUser(param) {
    return http.post(`${baseUrl}/platform/menu/listMenuForUser`, param)
  },
  //上传图片
  uploadImg(param, callbak) {
    return http.post(`${baseUrl}/platform/api/image`, param, callbak)
  },
  //导入
  uploadExcel(param) {
    return http.post(`${baseUrl}/platform/mainboard/importMainboard`, param, false, 'blob', true)
  },
  //导入数据错误导出
  exportUrl() {
    return http.post(`${baseUrl}/platform/mainboard/exportErrorData`, {}, false, 'blob')
  },
  // 查询组织列表数据接口
  listForTreePage(params) {
    return http.post(`${baseUrl}/personnel-bsm/pc/department/listForTreePage`, params)
  },
  // 分开保存之个人信息
  updateForPersonal(params) {
    return http.post(`${baseUrl}/personnel-bsm/pc/staffRoster/updateForPersonal`, params)
  },
  // 分开保存之岗位&薪资
  updateStaffPostContract(params) {
    return http.post(`${baseUrl}/personnel-bsm/pc/staffRoster/updateStaffPostContract`, params)
  },
  // 查询备注
  queryOne(params){
    return http.post(`${baseUrl}/personnel-bsm/pc/department/queryOne`, params)
  },
  nationData() {
    return http.post(`${baseUrl}/personnel-bsm/pc/nation/queryNationList`)
  },
  ...machine,
  ...merchant,
  ...mainBoard,
  ...rolePermissions,
  ...refuelInstallManagement,
  ...workbench,
  ...system,
  ...personnelMatters,
  ...personalInformation
}
