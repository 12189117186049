import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Timeline,
  Icon,
  Form,
  Select,
  Row,
  Col,
  Button,
  Input,
  message,
  Radio,
  DatePicker,
  Modal,
  Alert,
  Tabs,
  Table,
  Calendar,
  Checkbox,
} from 'antd';
import './index.less';
import ProcessGuide from '../../../components/processGuide';
import AuditRecord from '../../../components/auditRecord';
import Withdraw from '../../../components/withdraw'; // 撤回
import Quit from '../../../components/quitList2';
import SelectStaff from '../../../components/selectStaff';
import SelectStaffNo from '../../../components/SelectStaffNo';
import { getQueryVariable } from '../../../utils/common';
import utils from '../../../utils/utils';
import moment from 'moment';
import $, { param } from 'jquery';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { confirm } = Modal;
const { TabPane } = Tabs;

let weeks = {
  1: '周一',
  2: '周二',
  3: '周三',
  4: '周四',
  5: '周五',
  6: '周六',
  7: '周七',
};

let roles = ['685864770255065088', '682541940419465214', '682541940419465212'];

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: utils.localDb.get('userData').staffId,
      staffName: utils.localDb.get('userData').staffName,
      roles: utils.localDb.get('userData').roles || [],

      showType: 1,
      quitData: {
        affairRecess: '事假（天）',
        diseaseRecess: '病假（天）',
        festivalRecess: '年假（天）',
        marriedRecess: '婚假（天）',
        antenatalRecess: '产假（天）',
        bereftRecess: '丧假（天）',
        accompanyRecess: '陪产假（天）',
        prenatalCheckUpRecess: '产检假（天）',
      },
      leaveData: {},
      staffRow: {},
      staffDetail: {},
      dimissionDetail: {
        dimissionType: 'RESIGNATION',
      },
      total: 0,
      attendanceDate: moment().format('YYYY-MM'),
      time: moment(),
      leaveListVOS: [],
      leaveDayWithIdentificationVOS: [],
      logList: [],
      submitDisabled: false,

      applyDimissionTime: '',
    };
  }
  componentDidMount() {
    this.queryStaffDetail();
    if (getQueryVariable('dimissionProcId')) {
      this.queryDimissionById();
      this.queryQuitType();
      this.queryProcessLogList();
    } else {
      this.queryStaffDetail();
      this.queryQuitType();
      this.setState({
        logList: [],
      });
    }
  }

  onSelect = value => {
    this.setState(
      {
        time: value,
      },
      () => {
        // this.fetchScheduleByDay();
      }
    );
  };
  onPanelChange = value => {
    this.setState(
      {
        time: value,
        attendanceDate: value.format('YYYY-MM'),
      },
      () => {
        this.getLeaveByMonth(value.format('YYYY-MM'));
      }
    );
  };

  dateCellRender = value => {
    // console.log(value.date());
    // return <span>{value.date() > 9 ? value.date() : "0" + value.date()}</span>;

    if (value.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      $('.ant-fullcalendar-today')
        .children()
        .find('.ant-fullcalendar-value')
        .css('background', '#1890ff');
    }

    return this.state.leaveDayWithIdentificationVOS.map(item => {
      if (
        moment(item.date).format('YYYY-MM-DD') === value.format('YYYY-MM-DD')
      ) {
        if (
          moment(item.leaveStartTime).format('YYYY-MM-DD') ===
          moment().format('YYYY-MM-DD')
        ) {
          $('.ant-fullcalendar-today')
            .children()
            .find('.ant-fullcalendar-value')
            .css('background', '#fff');
        }
        return (
          <span
            key={item.date}
            className={`leave-day leave-day-${item.identifier}`}
          >
            {value.date() > 9 ? value.date() : '0' + value.date()}
          </span>
        );
      }
    });
  };

  queryDimissionById = () => {
    this.$http['queryDimissionById']({
      dimissionProcId: getQueryVariable('dimissionProcId'),
    }).then(res => {
      if (res.code === 200) {
        let showType = 2;
        let showWithdraw = false;
        let showOpinion = false;
        let showPass = false;
        let showComplete = false;

        let step2 = false;
        let step3 = false;
        let step4 = false;

        let taskDefKey = res.data.processData
          ? res.data.processData.taskDefKey
          : '';
        //
        if (res.data.procStatus === 'STORAGE') {
          showType = 1;
        }
        if (
          res.data.procStatus == 'REFUSE' ||
          res.data.procStatus == 'RECALL'
        ) {
          if (res.data.creatorId == this.state.staffId) {
            showType = 1;
          }
        }
        if (res.data.procStatus == 'AUDIT') {
          // 审核状态
          if (res.data.creatorId == this.state.staffId) {
            // 撤回操作
            showWithdraw = true;
          }
          if (res.data.auditId == this.state.staffId) {
            if (taskDefKey === 'DIM0002') {
              // 第二步
              step2 = true;
            }
            if (taskDefKey === 'DIM0003') {
              // 第三步
              step3 = true;
            }
            if (taskDefKey === 'DIM0004') {
              // 第四步
              step4 = true;
              showComplete = true;
            }
          }
        }
        if (res.data.procStatus == 'COMPLETE') {
          // 完成状态，归档
          showComplete = false;
        }

        // 抄送的详情没有操作权限
        if (getQueryVariable('copyToFlag') == 1) {
          step2 = false;
          step3 = false;
          step4 = false;
        }

        let date = moment(res.data.applyDimissionTime);

        // 本月的第一天，本月的最后一天
        let currentStart = date.add('month', 0).format('YYYY-MM') + '-01';
        let currentEnd = moment(currentStart)
          .add('month', 1)
          .add('days', -1)
          .format('YYYY-MM-DD');
        // 上个月的第一天，上个月的最后一天
        let prevStart = date.subtract('month', 1).format('YYYY-MM') + '-01';
        let prevEnd = moment(prevStart)
          .subtract('month', -1)
          .add('days', -1)
          .format('YYYY-MM-DD');

        console.log(currentStart, currentEnd);
        console.log(prevStart, prevEnd);
        const days = moment(res.data.applyDimissionTime).diff(
          moment(currentStart),
          'days'
        );
        console.log(days);

        let cutOffTime;
        if (days >= 14) {
          // 设置为本月底的日期
          cutOffTime = currentEnd;
        } else {
          // 设置为上月底的日期
          cutOffTime = prevEnd;
        }

        this.setState(
          {
            dimissionDetail: res.data || {},
            staffDetail: res.data || {},
            staffRow: res.data
              ? {
                  staffId: res.data.heirId || '',
                  staffName: res.data.heirName || '',
                }
              : {},
            showType,
            showWithdraw,
            showOpinion,
            showPass,
            showComplete,
            step2,
            step3,
            step4,

            applyDimissionTime: cutOffTime,
          },
          () => {
            this.getLeaveByMonth();
          }
        );
        if (res.data.procStatus === 'STORAGE') {
          this.queryQuitType();
        }
      }
    });
  };

  //流程日志
  queryProcessLogList = () => {
    this.$http['queryProcessLogList']({
      businessKey: getQueryVariable('dimissionProcId'),
      procTemplate: getQueryVariable('procTemplate'),
    })
      .then(res => {
        if (res.code == 200) {
          this.setState({
            logList: res.data || [],
          });
        } else {
        }
      })
      .catch(err => {});
  };

  queryStaffDetail = () => {
    this.$http['getStaffPostInfoForStaffRoster']({
      staffId: this.state.staffId,
    }).then(res => {
      if (res && res.code === 200) {
        this.setState(
          {
            staffDetail: res.data || {},
          },
          () => {
            this.getLeaveByMonth();
          }
        );
      }
    });
  };

  getLeaveByMonth = (attendanceDate = this.state.attendanceDate) => {
    this.$http['getLeaveByMonth']({
      attendanceDate: attendanceDate,
      staffId: this.state.staffDetail.staffId,
    }).then(res => {
      if (res.code === 200) {
        // let total = 0;
        // if (res.data) {
        //   Object.keys(this.state.quitData).map(item => {
        //     total += res.data[item] ? res.data[item] - 0 : 0;
        //   });
        // }
        this.setState({
          leaveListVOS: res.data.leaveListVOS || [],
          leaveDayWithIdentificationVOS:
            res.data.leaveDayWithIdentificationVOS || [],
          leaveData: res.data || {},
          total: res.data.totalRecess || 0,
        });
      }
    });
  };

  queryQuitType = () => {
    this.$http['selectListByGroup']({
      dicGroup: 'QUIT_TYPE',
    }).then(res => {
      if (res.code === 200) {
        if (res.data) {
          res.data.splice(
            res.data.findIndex(item => item.dicKey == 'AUTOMATIC'),
            1
          );
        }
        this.setState({
          QUIT_TYPE: res.data || [],
        });
      }
    });
  };

  handleDelete = () => {
    const self = this;
    confirm({
      title: '删除',
      content: '您确定要删除该工作流吗？',
      onOk() {
        self.$http['deleteByDimissionId']({
          dimissionProcId: getQueryVariable('dimissionProcId'),
        })
          .then(res => {
            if (res.code === 200) {
              // window.close();
              self.props.history.go(-1);
            } else {
            }
          })
          .catch(err => {});
      },
      onCancel() {},
    });
  };

  handleFail = () => {
    const self = this;
    const { form } = this.props;
    const { dimissionDetail } = this.state;
    if (!form.getFieldValue('auditOpinion').trim()) {
      message.destroy();
      message.warning('请在审核意见中填写退回原因');
      document.getElementById('auditOpinion').focus();
      return;
    }
    form.validateFieldsAndScroll((err, values) => {
      if (self.state.step2) {
        if (
          values['accumulationFundCutOffTime'].format('YYYY-MM-DD') !=
            this.state.applyDimissionTime &&
          !values['welfareCause'].trim()
        ) {
          message.destroy();
          message.warning('截止日修改，请填写截止日修改原因');
          return;
        }
        if (
          values['socialInsuranceCutOffTime'].format('YYYY-MM-DD') !=
            this.state.applyDimissionTime &&
          !values['welfareCause'].trim()
        ) {
          message.destroy();
          message.warning('截止日修改，请填写截止日修改原因');
          return;
        }
      }
      if (!err) {
        confirm({
          icon: <Icon type="question-circle" style={{ color: '#4D91FA' }} />,
          title: '您确定退回该工作流吗？',
          content: '',
          onOk() {
            self.$http['dimissionRefuse']({
              dimissionProcId: getQueryVariable('dimissionProcId'),
              taskId: dimissionDetail.processData.taskId,
              ...values,
            })
              .then(res => {
                if (res.code === 200) {
                  // window.close();
                  self.props.history.go(-1);
                } else {
                }
              })
              .catch(err => {});
          },
          onCancel() {},
        });
      }
    });
  };

  handleSubmit = type => {
    const { staffRow, staffDetail } = this.state;
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          submitDisabled: true,
        });
        const params = {
          ...values,
          applyDimissionTime: values['applyDimissionTime']
            ? values['applyDimissionTime'].format('YYYY/MM/DD')
            : '',
          attendanceAffirm: values['attendanceAffirm']
            ? values['attendanceAffirm'].join('')
            : '',
          dimissionType: 'RESIGNATION',
          heirName: staffRow.staffName,
          heirId: staffRow.staffId,
          staffName: staffDetail.staffName,
          staffId: staffDetail.staffId,
          staffCode: staffDetail.staffCode,
          departmentId: staffDetail.departmentId,
          departmentName: staffDetail.departmentName,
          postId: staffDetail.postId,
          postName: staffDetail.postName,
          onBoardingTime:
            staffDetail.onboardingTime || staffDetail.onBoardingTime,
          belongDeliverManager: staffDetail.belongDeliverManager,
          belongDeliverManagerId: staffDetail.belongDeliverManagerId,
          procStatus: type,
          leaveDays: this.state.total,
        };
        if (getQueryVariable('dimissionProcId')) {
          params.dimissionProcId = getQueryVariable('dimissionProcId');
        }
        console.log('params --->', params);
        this.$http[
          getQueryVariable('dimissionProcId')
            ? 'updateByDimissionId'
            : 'addDimissionProcess'
        ](params)
          .then(res => {
            if (res.code === 200) {
              this.props.history.push('/workbench/launch');
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch(err => {
            this.setState({
              submitDisabled: false,
            });
          });
      }
    });
  };

  handleAddSure = values => {
    console.log(values);
  };

  handleOk = row => {
    this.setState({
      staffRow: row[0] || {},
    });
  };

  handlePass = type => {
    console.log('归档');
    const { dimissionDetail } = this.state;
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.state.step2) {
          if (
            values['accumulationFundCutOffTime'].format('YYYY-MM-DD') !=
              this.state.applyDimissionTime &&
            !values['welfareCause'].trim()
          ) {
            message.destroy();
            message.warning('截止日修改，请填写截止日修改原因');
            return;
          }
          if (
            values['socialInsuranceCutOffTime'].format('YYYY-MM-DD') !=
              this.state.applyDimissionTime &&
            !values['welfareCause'].trim()
          ) {
            message.destroy();
            message.warning('截止日修改，请填写截止日修改原因');
            return;
          }
        }
        const params = {
          dimissionProcId: getQueryVariable('dimissionProcId'),
          taskId: dimissionDetail.processData.taskId,
          ...values,
        };
        if (type === 'pass') {
          if (values['accumulationFundCutOffTime']) {
            params.accumulationFundCutOffTime = values[
              'accumulationFundCutOffTime'
            ].format('YYYY-MM-DD');
          }
          if (values['socialInsuranceCutOffTime']) {
            params.socialInsuranceCutOffTime = values[
              'socialInsuranceCutOffTime'
            ].format('YYYY-MM-DD');
          }
        } else if (type === 'file') {
          params.propertyReturn = values['propertyReturn'].join('');
          params.accountingLiquidation = values['accountingLiquidation'].join(
            ''
          );
          params.resourcesConfirm = values['resourcesConfirm'].join('');
          params.leaveOfficeTime = values['leaveOfficeTime'].format(
            'YYYY-MM-DD'
          );
        }
        this.setState({
          submitDisabled: true,
        });
        console.log('params ->', params);
        this.$http['dimissionPass'](params)
          .then(res => {
            if (res.code === 200) {
              // window.close();
              this.props.history.go(-1);
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch(err => {
            this.setState({
              submitDisabled: false,
            });
          });
      }
    });
  };

  validateLength128 = (rule, value, callback) => {
    if (value.trim().length > 128) {
      callback('长度限制128');
    } else {
      callback();
    }
  };
  validatorMoney = (rule, value, callback) => {
    const reg = /^[0-9]+(.[0-9]{1,2})?$/;
    if (value && value.trim() && !reg.test(value.trim())) {
      callback('大于等于0，小于99999999 ，两位小数');
    } else {
      if (Number(value.trim()) > 99999999) {
        callback('大于等于0，小于99999999 ，两位小数');
      } else {
        callback();
      }
    }
  };

  handleWithdraw = () => {
    this.props.history.go(-1);
  };
  quitOnChange = (date, dateString) => {
    const { staffDetail } = this.state;
    console.log('staffDetail.onboardingTime ->', staffDetail.onboardingTime);
    if (
      moment(dateString).unix() <
      moment(staffDetail.onBoardingTime || staffDetail.onboardingTime).unix()
    ) {
      message.warning('离职时间不能小于入职时间');
    }
  };
  render() {
    const {
      showType,
      quitData,
      staffDetail,
      dimissionDetail,
      leaveData,
      logList,
    } = this.state;

    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    };

    // 显示审核结果，判断右侧记录第一个的状态operStatus，有的话就显示第一个，没有的话显示第二个
    let log = {};
    if (logList.length > 1) {
      if (logList[0].operStatus) {
        log = logList[0];
      } else {
        log = logList[1];
      }
    }

    return (
      <div className="quit-main">
        <div className="quit-title">
          <span>
            离职申请流程
            {showType == 1 && <span>-新建</span>}
            {dimissionDetail.procStatus == 'AUDIT' ? (
              dimissionDetail.processData ? (
                <span>{`-${dimissionDetail.processData.taskName}`}</span>
              ) : null
            ) : null}
          </span>
          <ProcessGuide title="离职申请流程" temp="DIMI_PROC" />
        </div>
        <div className="quit-wrap">
          <div className="wrap-left" style={{ flex: 1 }}>
            {log && log.operDescription ? (
              <div>
                {log.operStatus == 'PASS' && (
                  <Alert
                    message={`${log.taskName}通过`}
                    description={log.operDescription}
                    type="success"
                    showIcon
                    style={{ marginBottom: 12 }}
                  />
                )}
                {log.operStatus == 'REFUSE' && (
                  <Alert
                    message={
                      <span className="alert-process-refuse">{`${log.taskName}不通过`}</span>
                    }
                    description={
                      <span className="alert-process-desc">
                        {log.operDescription}
                      </span>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="close-circle"
                        style={{
                          fontSize: 18,
                          color: '#E5537B',
                          top: '18px',
                        }}
                      />
                    }
                    style={{ marginBottom: 12 }}
                  />
                )}
              </div>
            ) : null}
            <Form {...formItemLayout} className="process-form-readonly">
              <Row className="wrap-block">
                <Row style={{ marginLeft: 21 }}>
                  <Row>
                    <Col span={8}>
                      <FormItem label="员工">
                        <span>{staffDetail.staffName}</span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="工号">
                        <span>{staffDetail.staffCode}</span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="直属上级">
                        <span>{staffDetail.belongDeliverManager || '--'}</span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="部门名称">
                        <span>{staffDetail.departmentName}</span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="岗位">
                        <span>{staffDetail.postName}</span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="入职时间">
                        <span>
                          {staffDetail.onBoardingTime ||
                            staffDetail.onboardingTime}
                        </span>
                      </FormItem>
                    </Col>
                  </Row>
                  {/* {showType == 1 && (
                    <Row>
                      <Col span={24}>
                        <FormItem
                          label="离职类型"
                          labelCol={{ span: 4 }}
                          wrapperCol={{ span: 20 }}
                          className="quit-form-item"
                        >
                          {getFieldDecorator('dimissionType', {
                            initialValue: dimissionDetail.dimissionType || '',
                            rules: [
                              {
                                required: true,
                                message: '离职类型必填',
                              },
                            ],
                          })(
                            <Radio.Group>
                              {this.state['QUIT_TYPE'] &&
                                this.state['QUIT_TYPE'].map(item => {
                                  return (
                                    <Radio
                                      key={item.dicKey}
                                      value={item.dicKey}
                                    >
                                      {item.dicValue}
                                    </Radio>
                                  );
                                })}
                            </Radio.Group>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  )} */}
                  {showType == 1 && (
                    <Row>
                      <Col span={8}>
                        <FormItem
                          label="申请离职时间"
                          className="quit-form-item"
                        >
                          {getFieldDecorator('applyDimissionTime', {
                            initialValue: dimissionDetail.applyDimissionTime
                              ? moment(
                                  dimissionDetail.applyDimissionTime,
                                  'YYYY-MM-DD'
                                )
                              : '',
                            rules: [
                              {
                                required: true,
                                message: '申请离职时间必填',
                              },
                            ],
                          })(
                            <DatePicker
                              onChange={this.quitOnChange}
                              getCalendarContainer={triggerNode =>
                                triggerNode.parentNode
                              }
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="交接人">
                          {/* <SelectStaff
                            form={this.props.form}
                            handleOk={this.handleOk}
                            departmentId={
                              staffDetail.departmentId || ""
                            }
                            staffId={
                              staffDetail.staffId || ""
                            }
                            organizationalItem={{
                              departmentPrincipalId: this
                                .state.staffRow.staffId,
                              departmentPrincipalName: this
                                .state.staffRow.staffName,
                            }}
                            isHidenTree={true}
                            type={"handover"}
                          /> */}
                          <SelectStaffNo
                            form={this.props.form}
                            departmentId={staffDetail.departmentId}
                            staffId={staffDetail.staffId}
                            handleOk={this.handleOk}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  )}
                  {showType == 2 && (
                    <Row>
                      <Col span={8}>
                        {this.state.step4 ? (
                          <FormItem
                            label="申请离职时间"
                            className="quit-form-item"
                          >
                            {getFieldDecorator('leaveOfficeTime', {
                              initialValue: dimissionDetail.applyDimissionTime
                                ? moment(
                                    dimissionDetail.applyDimissionTime,
                                    'YYYY-MM-DD'
                                  )
                                : '',
                              rules: [
                                {
                                  required: true,
                                  message: '申请离职时间必填',
                                },
                              ],
                            })(
                              <DatePicker
                                onChange={this.quitOnChange}
                                getCalendarContainer={triggerNode =>
                                  triggerNode.parentNode
                                }
                              />
                            )}
                          </FormItem>
                        ) : (
                          <FormItem label="申请离职时间">
                            <span>
                              {dimissionDetail.applyDimissionTime || '--'}
                            </span>
                          </FormItem>
                        )}
                      </Col>
                      {/* <Col span={8}>
                        <FormItem label="离职类型">
                          <span>{dimissionDetail.dimissionName || '--'}</span>
                        </FormItem>
                      </Col> */}
                      <Col span={8}>
                        <FormItem label="交接人">
                          <span>{dimissionDetail.heirName || '无'}</span>
                        </FormItem>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col span={16}>
                      {showType == 1 && (
                        <FormItem label="离职原因" labelCol={{ span: 6 }}>
                          {getFieldDecorator('dimissionCause', {
                            initialValue: dimissionDetail.dimissionCause || '',
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: '离职原因必填',
                              },
                              {
                                validator: this.validateLength128,
                              },
                            ],
                          })(
                            <TextArea
                              rows={4}
                              maxLength={128}
                              placeholder="请输入"
                              allowClear
                              style={{ width: 600 }}
                            />
                          )}
                        </FormItem>
                      )}
                      {showType == 2 && (
                        <FormItem
                          label="离职原因"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                        >
                          <span>{dimissionDetail.dimissionCause || '--'}</span>
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      {showType == 1 && (
                        <FormItem label="考勤确认">
                          {getFieldDecorator('attendanceAffirm', {
                            initialValue: dimissionDetail.attendanceAffirm
                              ? [dimissionDetail.attendanceAffirm]
                              : '',
                            rules: [
                              {
                                required: true,
                                message: '考勤确认必填',
                              },
                            ],
                          })(
                            <Checkbox.Group>
                              <Checkbox value="1">已确认</Checkbox>
                            </Checkbox.Group>
                          )}
                        </FormItem>
                      )}
                      {showType == 2 && (
                        <FormItem label="考勤确认">
                          <span>
                            {dimissionDetail.attendanceAffirm == 1
                              ? '已确认'
                              : '--'}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                  {showType == 1 && (
                    <Row style={{ marginBottom: 12 }}>
                      <Col span={8}>
                        <FormItem label="" wrapperCol={{ offset: 12 }}>
                          <Button
                            type="primary"
                            onClick={() => {
                              this.props.history.push('/workbench/leave');
                            }}
                          >
                            新建请假
                          </Button>
                        </FormItem>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <FormItem label="" wrapperCol={{ offset: 4 }}>
                      <Col span={10}>
                        <div>
                          <div className="quit-table-wrap">
                            <span>累计请假{this.state.total}天</span>
                            <Quit
                              date={this.state.attendanceDate}
                              staffId={staffDetail.staffId}
                            />
                          </div>
                          {Object.keys(quitData).map(item => {
                            return (
                              <div className="quit-table-line">
                                <span className="table-text-left">
                                  {quitData[item]}
                                </span>
                                <span className="table-value-right">
                                  {leaveData[item]}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </Col>
                      <Col span={10} style={{ marginLeft: 22 }}>
                        <div className="calendar-main-content">
                          <Calendar
                            key={new Date().getTime()}
                            fullscreen={false}
                            value={this.state.time}
                            headerRender={({
                              value,
                              type,
                              onChange,
                              onTypeChange,
                            }) => {
                              return (
                                <div className="calender-head">
                                  <div>
                                    <img
                                      src={require('../../../assets/image/calendar.png')}
                                      alt=""
                                      style={{ width: 26 }}
                                    />
                                    <span
                                      style={{
                                        marginLeft: 8,
                                      }}
                                    >{`${moment().month() +
                                      1}/${moment().format('D')}`}</span>
                                    <span
                                      style={{
                                        marginLeft: 8,
                                      }}
                                    >
                                      {weeks[moment().format('d')]}
                                    </span>
                                  </div>
                                  <div>
                                    <Icon
                                      type="left-circle"
                                      style={{
                                        color: '#ADB7CC',
                                      }}
                                      onClick={() => {
                                        onChange(
                                          moment(value).subtract(1, 'months')
                                        );
                                      }}
                                    />
                                    <span className="calender-title">{`${value.year()}年${value.month() +
                                      1}月`}</span>
                                    <Icon
                                      type="right-circle"
                                      style={{
                                        color: '#ADB7CC',
                                      }}
                                      onClick={() => {
                                        onChange(
                                          moment(value).add(1, 'months')
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            }}
                            dateCellRender={this.dateCellRender}
                            onSelect={this.onSelect}
                            onPanelChange={this.onPanelChange}
                          />
                          <div className="calendar-footer">
                            <span className="footer-span footer-span-0">
                              节假日
                            </span>
                            <span className="footer-span footer-span-4">
                              工作日
                            </span>
                            <span className="footer-span footer-span-3">
                              考勤确认
                            </span>
                            <span className="footer-span footer-span-2">
                              请假
                            </span>
                          </div>
                        </div>
                      </Col>
                    </FormItem>
                  </Row>
                </Row>
                {dimissionDetail.showWelfare == 1 ? (
                  this.state.step2 ? (
                    <Row>
                      <Row style={{ marginLeft: 21 }}>
                        <Col span={8}>
                          <FormItem
                            label="公积金截止日"
                            className="quit-form-item"
                          >
                            {getFieldDecorator('accumulationFundCutOffTime', {
                              initialValue: dimissionDetail.accumulationFundCutOffTime
                                ? moment(
                                    dimissionDetail.accumulationFundCutOffTime,
                                    'YYYY-MM-DD'
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: '请输入公积金截止日',
                                },
                              ],
                            })(
                              <DatePicker
                                getCalendarContainer={triggerNode =>
                                  triggerNode.parentNode
                                }
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="社保截止日"
                            className="quit-form-item"
                          >
                            {getFieldDecorator('socialInsuranceCutOffTime', {
                              initialValue: dimissionDetail.socialInsuranceCutOffTime
                                ? moment(
                                    dimissionDetail.socialInsuranceCutOffTime,
                                    'YYYY-MM-DD'
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: '请输入社保截止日',
                                },
                              ],
                            })(
                              <DatePicker
                                getCalendarContainer={triggerNode =>
                                  triggerNode.parentNode
                                }
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="离职补偿金"
                            className="quit-form-item"
                          >
                            {getFieldDecorator('severancePackage', {
                              initialValue:
                                dimissionDetail.severancePackage || '',
                              rules: [
                                {
                                  validator: this.validatorMoney,
                                },
                              ],
                            })(
                              <Input
                                placeholder="请输入"
                                allowClear
                                style={{ width: '70%' }}
                              />
                            )}
                            <span>元</span>
                          </FormItem>
                        </Col>
                      </Row>
                      <Row style={{ marginLeft: '-27px' }}>
                        <Col span={24}>
                          <FormItem
                            label="社保/公积金截止日修改原因"
                            labelCol={{ span: 5 }}
                          >
                            {getFieldDecorator('welfareCause', {
                              initialValue: dimissionDetail.welfareCause || '',
                              rules: [
                                {
                                  validator: this.validateLength128,
                                },
                              ],
                            })(
                              <TextArea
                                rows={4}
                                maxLength={128}
                                placeholder="请输入（截止日修改时必填）"
                                allowClear
                                style={{ width: 461 }}
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Row>
                  ) : (
                    <Row>
                      <Row style={{ marginLeft: 21 }}>
                        <Col span={8}>
                          <FormItem
                            label="公积金截止日"
                            className="quit-form-item"
                          >
                            <span>
                              {dimissionDetail.accumulationFundCutOffTime ||
                                '--'}
                            </span>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="社保截止日"
                            className="quit-form-item"
                          >
                            <span>
                              {dimissionDetail.socialInsuranceCutOffTime ||
                                '--'}
                            </span>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem
                            label="离职补偿金"
                            className="quit-form-item"
                          >
                            <span>
                              {dimissionDetail.severancePackage
                                ? `¥ ${dimissionDetail.severancePackage}元`
                                : '--'}
                            </span>
                          </FormItem>
                        </Col>
                      </Row>
                      <Row style={{ marginLeft: '-27px' }}>
                        <Col span={24}>
                          <FormItem
                            label="社保/公积金截止日修改原因"
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 15 }}
                          >
                            <span
                              style={{
                                wordBreak: 'break-all',
                                wordWrap: 'break-word',
                              }}
                            >
                              {dimissionDetail.welfareCause || '--'}
                            </span>
                          </FormItem>
                        </Col>
                      </Row>
                    </Row>
                  )
                ) : null}

                {dimissionDetail.showReturn == 1 ? (
                  this.state.step4 ? (
                    <Row style={{ marginLeft: 27 }}>
                      <Col span={8}>
                        <FormItem label="固定资产归还">
                          {getFieldDecorator('propertyReturn', {
                            initialValue: '',
                            rules: [
                              {
                                required: true,
                                message: '请选择固定资产归还',
                              },
                            ],
                          })(
                            <Checkbox.Group>
                              <Checkbox value="1">完成</Checkbox>
                            </Checkbox.Group>
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="账务清算">
                          {getFieldDecorator('accountingLiquidation', {
                            initialValue: '',
                            rules: [
                              {
                                required: true,
                                message: '请选择账务清算',
                              },
                            ],
                          })(
                            <Checkbox.Group>
                              <Checkbox value="1">完成</Checkbox>
                            </Checkbox.Group>
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="人力资源部确认">
                          {getFieldDecorator('resourcesConfirm', {
                            initialValue: '',
                            rules: [
                              {
                                required: true,
                                message: '请选择人力资源部确认',
                              },
                            ],
                          })(
                            <Checkbox.Group>
                              <Checkbox value="1">完成</Checkbox>
                            </Checkbox.Group>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  ) : (
                    <Row style={{ marginLeft: 27 }}>
                      <Col span={8}>
                        <FormItem label="固定资产归还">
                          <span>{dimissionDetail.propertyReturn}</span>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="账务清算">
                          <span>{dimissionDetail.accountingLiquidation}</span>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="人力资源部确认">
                          <span>{dimissionDetail.resourcesConfirm}</span>
                        </FormItem>
                      </Col>
                    </Row>
                  )
                ) : null}
                {/* {this.state.step4 ? (
                  <Row>
                    <Col span={8}>
                      <FormItem label="固定资产归还">
                        {getFieldDecorator("propertyReturn", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: "请选择固定资产归还"
                            }
                          ]
                        })(
                          <Checkbox.Group>
                            <Checkbox value="1">完成</Checkbox>
                          </Checkbox.Group>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="账务清算">
                        {getFieldDecorator("accountingLiquidation", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: "请选择账务清算"
                            }
                          ]
                        })(
                          <Checkbox.Group>
                            <Checkbox value="1">完成</Checkbox>
                          </Checkbox.Group>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="人力资源部确认">
                        {getFieldDecorator("resourcesConfirm", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: "请选择人力资源部确认"
                            }
                          ]
                        })(
                          <Checkbox.Group>
                            <Checkbox value="1">完成</Checkbox>
                          </Checkbox.Group>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                ) : dimissionDetail.showReturn ? (
                  <Row>
                    <Col span={8}>
                      <FormItem label="固定资产归还">
                        <span>{dimissionDetail.propertyReturn}</span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="账务清算">
                        <span>{dimissionDetail.accountingLiquidation}</span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="人力资源部确认">
                        <span>{dimissionDetail.resourcesConfirm}</span>
                      </FormItem>
                    </Col>
                  </Row>
                ) : null} */}
              </Row>
              {(this.state.step2 || this.state.step3) && (
                <Row className="wrap-block" style={{ marginBottom: 0 }}>
                  <div className="block-title">流程审核</div>
                  <Row>
                    <FormItem
                      label="审核意见"
                      labelCol={{ span: 2 }}
                      style={{ marginLeft: 16 }}
                    >
                      {getFieldDecorator('auditOpinion', {
                        initialValue: '',
                        rules: [
                          {
                            validator: this.validateLength128,
                          },
                        ],
                      })(
                        <TextArea
                          rows={4}
                          maxLength={128}
                          placeholder="请输入审核意见"
                          allowClear
                        />
                      )}
                    </FormItem>
                  </Row>
                </Row>
              )}
            </Form>
          </div>
          <div className="wrap-right">
            <AuditRecord data={logList || []} />
          </div>
        </div>
        <div className="footer-fixed-wrap">
          <div className="footer-fixed-btn">
            {showType == 1 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // if (getQueryVariable("dimissionProcId")) {
                    //   window.close();
                    // }
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => this.handleSubmit('INITIATE')}
                  disabled={this.state.submitDisabled}
                >
                  提交
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => this.handleSubmit('STORAGE')}
                  disabled={this.state.submitDisabled}
                >
                  保存
                </Button>
                {(dimissionDetail.procStatus === 'STORAGE' ||
                  dimissionDetail.procStatus === 'REFUSE') && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={this.handleDelete}
                  >
                    删除
                  </Button>
                )}
              </div>
            )}
            {showType == 2 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // window.close();
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                {/* {this.state.showWithdraw && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      this.setState(
                        {
                          visible: true
                        },
                        () => {
                          this.refs.withdrawRef.handleShow();
                        }
                      );
                    }}
                  >
                    撤回
                  </Button>
                )} */}
                {(this.state.step2 || this.state.step3) && (
                  <span>
                    <Button
                      type="primary"
                      style={{ marginRight: 16 }}
                      onClick={() => this.handlePass('pass')}
                      disabled={this.state.submitDisabled}
                    >
                      通过
                    </Button>
                    <Button
                      type="danger"
                      style={{ marginRight: 16 }}
                      onClick={this.handleFail}
                    >
                      不通过
                    </Button>
                  </span>
                )}
                {this.state.step4 && (
                  <Button
                    type="primary"
                    style={{ marginRight: 16 }}
                    onClick={() => this.handlePass('file')}
                    disabled={this.state.submitDisabled}
                  >
                    归档
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        {this.state.visible && (
          <Withdraw
            ref="withdrawRef"
            form={this.props.form}
            data={{
              dimissionProcId: dimissionDetail.dimissionProcId,
              taskId: dimissionDetail.processData.taskId,
            }}
            url="dimissionWithdraw"
            handleOk={this.handleWithdraw}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Form.create()(MainManagement));
