import http from './fetch'
const baseUrl = process.env.API_URL ? process.env.API_URL : '';

export default {
    /** 加注点*/
    //加注点--列表数据、首页--加注点分布
    getStationsData(param, callbak) {
        return http.post(`${baseUrl}/platform/device/point/pageConditionQuery`, param, callbak)
    },
    /** 加注点--新增、编辑*/
    //获取所属商户数据
    getMerchantData(param, callbak) {
        return http.post(`${baseUrl}/platform/merchant/allChildrenMerchant`, param, callbak)
    },
    //新增
    addStations(param, callbak) {
        return http.post(`${baseUrl}/platform/device/point/addDevicePoint`, param, true, callbak)
    },
    //编辑
    editStations(param, callbak,) {
        return http.post(`${baseUrl}/platform/device/point/updateByDevicePointId`, param, true, callbak,)
    },
    //删除
    deleteStations(param, callbak) {
        return http.post(`${baseUrl}/platform/device/point/deleteByDevicePointId`, param, true, callbak)
    },
    //省市区查询
    queryAddressForFourLinkage(param, callbak) {
        return http.post(`${baseUrl}/platform/address/queryAddressForFourLinkage`, param, callbak)
    },
    //加注点详情
    getStationsDetail(param, callbak) {
        return http.post(`${baseUrl}/platform/device/point/getByDevicePointId`, param, callbak)
    },

    /** 加注机*/
    //加注机--列表数据
    getDevicesData(param, callbak) {
        return http.post(`${baseUrl}/platform/device/pageConditionQuery`, param, callbak)
    },
    /** 加注机--新增、编辑*/
    //新增
    addDevices(param, callbak) {
        return http.post(`${baseUrl}/platform/device/addDevice`, param, true, callbak)
    },
    //编辑
    editDevices(param, callbak) {
        return http.post(`${baseUrl}/platform/device/updateByDeviceId`, param, true, callbak)
    },
    //删除
    deleteDevices(param, callbak) {
        return http.post(`${baseUrl}/platform/device/deleteByDeviceId`, param, true, callbak)
    },
    //获取加注机型号下拉数据
    getDeviceModelsDataByStatus(param, callbak) {
        return http.post(`${baseUrl}/platform/device/type/listByStatus`, param, callbak)
    },
    //获取加注点下拉数据
    getStationsDataByStatus(param, callbak) {
        return http.post(`${baseUrl}/platform/device/point/listByDevicePointStatus`, param, callbak)
    },
    //加注机详情
    getDevicesDetail(param, callbak) {
        return http.post(`${baseUrl}/platform/device/getByDeviceId`, param, callbak)
    },

     /** 加注机型号 */
    //加注机型号列表，加注机-新增里型号数据
    getDeviceModelsData(param, callbak) {
        return http.post(`${baseUrl}/platform/device/type/pageConditionQuery`, param, callbak)
    },
     /** 加注机型号--新增编辑 */
    //加注机生产商数据
    getDeviceProducerData(param, callbak) {
        return http.post(`${baseUrl}/platform/merchant/listByDeviceProducer`, param, callbak)
    },
    //详情
    getDeviceModelsDetail(param, callbak) {
        return http.post(`${baseUrl}/platform/device/type/getByDeviceTypeId`, param, callbak)
    },
    //新增
    addDeviceModels(param,callbak) {
        return http.post(`${baseUrl}/platform/device/type/addDeviceType`, param, true, callbak)
    },
    //编辑
    editDeviceModels(param, callbak) {
        return http.post(`${baseUrl}/platform/device/type/updateByDeviceTypeId`, param, true, callbak)
    },
    //删除
    deleteDeviceModels(param, callbak) {
        return http.post(`${baseUrl}/platform/device/type/deleteByDeviceTypeId`, param, true, callbak)
    },
    //停用启用
    stopStartDeviceModels(param, callbak) {
        return http.post(`${baseUrl}/platform/device/type/updateEnableStatus`, param, true, callbak)
    },

    // =============主板型号管理

    // 分页查询主板型号
    pageMainBoardTypes(param, callbak) {
        return http.post(`${baseUrl}/platform/mainboardType/pageMainBoardTypes`, param, callbak)
    },
    // 新增主板型号
    addMainboardType(param, callbak) {
        return http.post(`${baseUrl}/platform/mainboardType/addMainboardType`, param, true, callbak)
    },
    // 修改主板型号
    updateMainboardType(param, callbak) {
        return http.post(`${baseUrl}/platform/mainboardType/updateMainboardType`, param, true, callbak)
    },
    // 修改主板型号状态
    updateMainboardTypeStatus(param, callbak) {
        return http.post(`${baseUrl}/platform/mainboardType/updateMainboardTypeStatus`, param, true, callbak)
    },
    // 删除主板型号
    deleteMainboardType(param, callbak) {
        return http.post(`${baseUrl}/platform/mainboardType/deleteMainboardType`, param, true, callbak)
    },
    // 主板型号下拉
    listMainBoardType(param, callbak) {
        return http.post(`${baseUrl}/platform/mainboardType/listMainBoardType`, param, true, callbak)
    },

}