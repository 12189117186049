/**
 * @fileoverview 系统管理---账号设置 -- 搜索条件
 * @author  蒋西园
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form, Input, Select, Row, Col, DatePicker } from "antd";
import moment from "moment";
import "./index.less";

const FormItem = Form.Item;
const Option = Select.Option;

class SearchAttendancelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startValue: null,
      endValue: null,
      endOpen: false,
      postList: []
    };
  }
  componentDidMount() {
    this.listForDropDownPost();
  }
  // 查询岗位
  listForDropDownPost = () => {
    this.$http.listForDropDownPost().then(res => {
      if (res.code === 200) {
        this.setState({
          postList: res.data
        });
      }
    });
  };
  // 提交表单
  handleSubmit = () => {
    const { startValue, endValue } = this.state;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return false;
      }
      const data = {};
      // 去除含有undefined的item
      Object.keys(values).forEach(res => {
        if (values[res]) {
          if (res == "procStatus") {
            data[res] = values[res] - 1;
          } else {
            data[res] = values[res];
          }
        }
      });
      if (startValue)
        data["contractStartTime"] = moment(Date.parse(startValue)).format(
          "YYYY-MM-DD"
        );
      if (endValue)
        data["contractEndTime"] = moment(Date.parse(endValue)).format(
          "YYYY-MM-DD"
        );
      console.log(data);
      this.props.handleSearchBtn(data);
    });
  };
  handleReset = () => {
    this.props.form.resetFields();
    this.props.handleSearchBtn({});
  };
  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value
    });
  };
  onStartChange = value => {
    this.onChange("startValue", value);
  };

  onEndChange = value => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };
  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };
  onDropdownVisiblePost = open => {
    if (open) {
      this.listForDropDownPost();
    }
  };
  // 下拉框数据查询
  selectListByGroup = (open, key) => {
    if (open) {
      this.$http["queryTemplate"]({}).then(res => {
        if (res.code === 200) {
          this.setState({
            [key]: res.data.templateList || []
          });
        }
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { startValue, endValue, endOpen, postList } = this.state;
    return (
      <div className="query-process-top">
        <Form layout="inline">
          <FormItem label="流程">
            {getFieldDecorator("procTemplate")(
              <Select
                placeholder="请选择流程"
                allowClear
                onDropdownVisibleChange={value =>
                  this.selectListByGroup(value, "ID_TYPE")
                }
                style={{ width: 120 }}
              >
                {this.state["ID_TYPE"] &&
                  this.state["ID_TYPE"].map(item => {
                    return (
                      <Option key={item.templateCode} value={item.templateCode}>
                        {item.templateName}
                      </Option>
                    );
                  })}
              </Select>
            )}
          </FormItem>
          <FormItem label="归档状态">
            {getFieldDecorator("procStatus")(
              <Select
                placeholder="请选择状态"
                allowClear
                style={{ width: 120 }}
              >
                <Option key={1} value={1}>
                  未完成
                </Option>
                <Option key={2} value={2}>
                  已完成
                </Option>
              </Select>
            )}
          </FormItem>
          <FormItem
            label="姓名"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
          >
            {getFieldDecorator("staffName")(
              <Input placeholder="姓名" allowClear style={{ width: 120 }} />
            )}
          </FormItem>
          <FormItem label="工号">
            {getFieldDecorator("staffCode")(
              <Input placeholder="工号" allowClear style={{ width: 120 }} />
            )}
          </FormItem>
          <FormItem label="">
            <Button onClick={this.handleReset}>重置</Button>
            <Button
              type="primary"
              onClick={this.handleSubmit}
              style={{ marginLeft: 10 }}
            >
              查询
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default withRouter(Form.create()(SearchAttendancelist));
