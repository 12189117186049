/**
* @fileoverview 左侧树
* @author  蒋西园
*/
import React, { Component } from 'react';
import { Input, Tree,Tooltip } from 'antd';
import './index.less'
const { Search } = Input;
const { TreeNode } = Tree;

class LeftTree extends Component {
    constructor(props) {
        super(props)
        this.state = {
            treeData: [
                { title: '全部', key: '0',cj: 1, isLeaf: true },
                { title: '一级部门', key: '1', cj: 1 },
                { title: '一级部门', key: '2', cj: 1 },
            ]
        }
    }
    handleTreeNode = (item) => {
        const { mapping } = this.props;
        this.props.handleTreeNode({[mapping.id]:item[mapping.id],[mapping.value]:item[mapping.value]})
    };
    render() {
        const { list, mapping, checkedTreeItem, placeholder } = this.props;
        return (
            <div className="left-tree">
                <Search
                    placeholder={placeholder ? placeholder : '请输入'}
                    maxLength='32'
                    onSearch={value => this.props.hansleSearch(value)}
                />
                <div className="list-wrap">
                    {
                        list.map((item) => {
                            let item1 = item[mapping.value].substring(0,10)+'...'
                            return <li className={item[mapping.id] === checkedTreeItem[mapping.id] ? 'active' : ''} key={item[mapping.id]} onClick={() => this.handleTreeNode(item)}>
                                {item[mapping.value].length>10? <Tooltip title={item[mapping.value]}>
                                    <span>{item1}</span>
                                </Tooltip>:item[mapping.value]}
                                
                            </li>
                        })
                    }
                </div>
            </div>
        )
    }
}
export default LeftTree