import http from './fetch'
const baseUrl = process.env.API_URL ? process.env.API_URL : '';

export default {
    //主板分页列表查询
    mainBoardList(param) {
        return http.post(`${baseUrl}/platform/mainBoard/pageConditionQuery`, param)
    },
    //主板新增
    addMainBoard(param) {
        return http.post(`${baseUrl}/platform/mainBoard/addMainBoard`, param, true)
    },
    //主板修改
    updateByMainBoardId(param) {
        return http.post(`${baseUrl}/platform/mainBoard/updateByMainBoardId`, param, true)
    },
    //主板详情
    getByMainBoardId(param) {
        return http.post(`${baseUrl}/platform/mainBoard/getByMainBoardId`, param)
    },
    //删除主板
    deleteByMainBoardId(param) {
        return http.post(`${baseUrl}/platform/mainBoard/deleteByMainBoardId`, param, true)
    },
    //主板型号下拉
    listMainBoardType(param) {
        return http.post(`${baseUrl}/platform/mainboardType/listMainBoardType`, param)
    },
    //主板发货
    // /platform/logistics/mainBoardDelivery
    mainBoardDelivery(param){
        return http.post(`${baseUrl}/platform/logistics/mainBoardDelivery`, param,true)
    },
    //主板发货物流列表
    // /platform/logistics/pageConditionQuery
    mainBoardDeliveryList(param){
        return http.post(`${baseUrl}/platform/logistics/pageConditionQuery`, param)
    },
    //主板物流详情
    // /platform/logistics/getByLogisticsId
    getByMainBoardLogisticsId(param){
        return http.post(`${baseUrl}/platform/logistics/getByLogisticsId`, param)
    },
    // /platform/mainBoard/activate
    // 主板激活
    mainBoardActivate(param){
        return http.post(`${baseUrl}/platform/mainBoard/activate`, param,true)
    },
    // /platform/order/findTestOrder
    // 获取出厂测试订单
    findTestOrder(param){
        return http.post(`${baseUrl}/platform/order/findTestOrder`, param)
    },
    // 出厂记录新增
    testResultSave(param){
        return http.post(`${baseUrl}/platform/mainBoard/testResult/save`, param,true)
    },

}