import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, message } from "antd";
import "./index.less";
import LeftTree from "./../../../components/leftTreeTwo";
import Table from "./component/tableList";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      treeList: [], // 左侧tree数据
      searchValue: "", // 左侧tree搜索框
      checkedTreeId: "", // 左侧tree默认选中
      checkedTreeName: "", // 左侧tree默认选中的部门名字
      dataList: [], // 账号列表数据
      total: 0, // 账号总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      addModalShow: false, // 新增数据弹框
      modalType: 0, // 0：添加 1：修改
      dicItem: {}, // 编辑数据字典初始化数据
      isShow: 1,
      selectedKeys: [],
      expandedKeys: [],
    };
  }
  componentDidMount() {
    // this.queryTableList();
  }
  // 888
  /**
   * 函数描述: 查询部门流程
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} departmentId   部门编号
   * @return {Object}
   */
  queryTableList = (pageNo = 1, pageSize = 10) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const params = {
          pageNo,
          pageSize,
          departmentId:
            (sessionStorage.queryProcessSelectedKeys &&
              sessionStorage.queryProcessSelectedKeys.split(
                "&"
              )[0]) ||
            this.state.checkedTreeId,
        };
        this.$http
          .pageConfigByDeptId(params)
          .then((res) => {
            if (res.code == 200) {
              this.setState({
                loading: false,
                pageNo,
                pageSize,
                dataList: res.data.list,
                total: res.data.total,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };

  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @return {Object} 请求pageNo页列表数据
   */
  handleChangePage = (pageNo, pageSize) => {
    this.queryTableList(pageNo, pageSize);
  };

  /**
   * 函数描述: 删除流程
   * @param {String}  procDefKey 流程编码
   * @param {Boolean} departmentId 部门编号
   * @return {Object} 刷新当前页数据
   */
  handleDelete = ({ procDefKey, departmentId }) => {
    this.$http
      .deleteConfigByDeptId({
        procDefKey,
        departmentId,
      })
      .then((res) => {
        if (res.code === 200) {
          const { pageNo, pageSize } = this.state;
          this.queryTableList(pageNo, pageSize);
        }
      })
      .catch((err) => { });
  };
  handleAdd = () => {
    // var example = window.location.protocol;
    // var example1 = window.location.host;
    // let url = example+'//'+example1+`/systemSetup/nodeConfig?departmentId=${this.state.checkedTreeId}&departmentName=${this.state.checkedTreeName}`
    // window.open(url)
    this.props.history.push({
      pathname: "/systemSetup/nodeConfig",
      state: {
        departmentId: this.state.checkedTreeId,
        departmentName: this.state.checkedTreeName,
      },
    });
  };
  handleEditNodeConfig = (item) => {
    // var example = window.location.protocol;
    // var example1 = window.location.host;
    // let url = example+'//'+example1+`/systemSetup/nodeConfig?departmentId=${this.state.checkedTreeId}&departmentName=${this.state.checkedTreeName}&procDefKey=${item.procDefKey}`
    // window.open(url)
    this.props.history.push({
      pathname: "/systemSetup/nodeConfig",
      state: {
        departmentId: this.state.checkedTreeId,
        departmentName: this.state.checkedTreeName,
        procDefKey: item.procDefKey,
      },
    });
  };
  changeDepartmentId = (val) => {
    console.log("选中的树key ->", val);
    const selectedKeys =
      sessionStorage.getItem("selectedKey") &&
      sessionStorage.getItem("selectedKey").split("&");
    let arr = val.length > 0 ? val[0].split("&") : [];
    this.setState(
      {
        checkedTreeId: selectedKeys
          ? selectedKeys[0]
          : arr[0] || this.state.checkedTreeId,
        checkedTreeName: selectedKeys
          ? selectedKeys[1]
          : arr[1],
        isShow: selectedKeys
          ? selectedKeys[2]
          : arr[2] || this.state.isShow,
      },
      () => {
        this.queryTableList();
      }
    );
  };

  render() {
    const {
      loading,
      dataList,
      total,
      pageNo,
      pageSize,
      addModalShow,
      modalType,
      dicItem,
      checkedTreeItem,
      treeList,
    } = this.state;

    return (
      <div className="process-config-wrapper">
        <div className="crumbs">流程配置</div>
        <div className="contract-wrap">
          {/* 左侧树 */}
          <LeftTree
            isShow="流程配置"
            selectedKeys="queryProcessSelectedKeys"
            selectedDepartmentIds="queryProcessDepartmentIds"
            expandedKeys="queryProcessExpandedKeys"
            onSelect={this.changeDepartmentId}
            type="流程配置"
          />
          <div className="right-wrap wrap-card">
            <Button
              className="addBtn"
              type="primary"
              // disabled={this.state.isShow==1?true:false}
              onClick={this.handleAdd}
            >
              添加
            </Button>
            <Table
              history={this.props.history}
              loading={loading}
              data={dataList}
              total={total}
              pageNo={pageNo}
              pageSize={pageSize}
              handleChangePage={this.handleChangePage} // 切换页码
              handleDelete={this.handleDelete} // 删除
              handleEditNodeConfig={
                this.handleEditNodeConfig
              } // 节点配置
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
