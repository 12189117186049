import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import "./index.less";

// import logoUrl from '../../assets/images/logo.png';
import loginMain from "../../assets/image/login.png";
import Api from "../../api/apis";
import utils from "../../utils/utils";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 60,
      getCodeChange: true, //文案 获取验证码,
      from: "",
      userPhone: "",
      isDisable: false,
    };
  }
  componentDidMount() {
    // 修改密码
    if (this.props.location.state.from === "modify") {
      this.setState({
        from: this.props.location.state.from,
        userPhone: utils.localDb.get("userData").userPhone || "",
        isDisable: utils.localDb.get("userData").userPhone ? true : false,
      });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { from, isDisable, userPhone } = this.state;
        let url = "forgetUserPassword";
        if (from === "modify") {
          url = "updateUserPwd";
        }

        Api["updatePwdByEmail"]({
          userPhone: isDisable ? userPhone : values.userPhone,
          staffCode: values.staffCode,
          password: this.$md5(values.password),
          emailCode: values.emailCode,
        })
          .then((res) => {
            if (res.code == 200) {
              this.props.history.push("/login");
            }
          })
          .catch((err) => {});
      }
    });
  };
  setCookie = function(name, value, day) {
    if (day !== 0) {
      //当设置的时间等于0时，不设置expires属性，cookie在浏览器关闭后删除
      var expires = day * 24 * 60 * 60 * 1000;
      var date = new Date(+new Date() + expires);
      document.cookie =
        name + "=" + escape(value) + ";expires=" + date.toUTCString();
    } else {
      document.cookie = name + "=" + escape(value);
    }
  };
  // 发送验证码
  sendCaptcha = () => {
    let { getCodeChange, count } = this.state;

    this.props.form.validateFieldsAndScroll(["staffCode"], (err, values) => {
      if (!err) {
        // 验证码有效期内
        if (!getCodeChange) {
          return;
        }

        // 发送
        Api.sendEmailCode({
          staffCode: values.staffCode,
        })
          .then((res) => {
            if (res.code == 200) {
              message.destroy();
              message.success("验证码已发送至您的邮箱");
              // 倒计时
              const timer = setInterval(() => {
                this.setState(
                  {
                    getCodeChange: false,
                    count: count--,
                  },
                  () => {
                    if (count === 0) {
                      clearInterval(timer);
                      this.setState({
                        getCodeChange: true,
                        count: 60,
                      });
                    }
                  }
                );
              }, 1000);
            } else {
              message.warning(res.message);
            }
          })
          .catch((e) => console.log(e, "e"));
      }
    });
  };

  confirmPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("两次密码不一致");
    } else {
      callback();
    }
  };

  checkPassword = (rule, value, callback) => {
    const reg = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{6,16}$/;
    if (value && value.trim() && reg.test(value.trim())) {
      callback("密码为6-16个英文数字或符号");
    } else {
      callback();
    }
  };

  validateStaffCode = (rule, value, callback) => {
    const reg = /^[0-9]+$/;
    if (value && value.trim() == "") {
      callback("账号未输入");
    } else if (value && value.trim() && !reg.test(value.trim())) {
      callback("只能输入数字");
    } else {
      callback();
    }
  };

  render() {
    const { getCodeChange, count, isDisable, userPhone } = this.state;
    const { getFieldDecorator } = this.props.form;

    let selectAfter = "";
    if (getCodeChange) {
      selectAfter = (
        <span onClick={this.sendCaptcha} className="sendCaptcha">
          发送验证码
        </span>
      );
    } else {
      selectAfter = <span className="disableCaptcha">{count}秒后重发</span>;
    }

    const isPhone = function(rule, value, callback) {
      if (value && value.trim()) {
        if (/^1[34578]\d{9}$/.test(value.trim())) {
          callback();
        } else {
          callback("手机号格式错误");
        }
      } else {
        callback("请输入手机号!");
      }
    };
    return (
      <div className="p-main">
        {/* <img 
                alt=''
                src={ require("../../assets/image/triangle-right.png")} 
                className='conent-bg-r'
            />
            <img 
                alt=''
                src={ require("../../assets/image/triangle-left.png")} 
                className='conent-bg-l'
            /> */}
        <div className="p-content">
          <div
            className="content-img"
            style={{ backgroundImage: "url(" + loginMain + ")" }}
          ></div>
          <div className="content-form">
            <div className="content-form-main">
              <div id="login_container"></div>
              <Form onSubmit={this.handleSubmit} className="login-form ">
                <Form.Item style={{ marginBottom: 16 }}>
                  {getFieldDecorator("staffCode", {
                    rules: [
                      { required: true, message: "账号未输入" },
                      {
                        validator: this.validateStaffCode,
                      },
                    ],
                    initialValue: userPhone ? userPhone : "",
                  })(
                    <Input
                      // className='round-large login-input'
                      // prefix={<img
                      //         alt=''
                      //     src={ require("../../assets/image/login-phone.png")}
                      //     className='logo-phone-icon'
                      // />}
                      disabled={isDisable}
                      placeholder="请输入账号"
                    />
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 16 }}>
                  {getFieldDecorator("emailCode", {
                    rules: [{ required: true, message: "验证码未输入" }],
                  })(
                    <Input
                      //   className="round-large certificate-input login-input"
                      //   prefix={
                      //     <img
                      //       alt=""
                      //       src={require("../../assets/image/login-code.png")}
                      //       className="logo-code-icon"
                      //     />
                      //   }
                      placeholder="请输入验证码"
                      suffix={selectAfter}
                      autocomplete="new-password"
                      className="code-input"
                    />
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 16 }}>
                  {getFieldDecorator("password", {
                    rules: [
                      { required: true, message: "重置密码未输入" },
                      {
                        validator: this.checkPassword,
                      },
                    ],
                  })(
                    <Input.Password
                      //   className="round-large certificate-input login-input"
                      //   prefix={
                      //     <img
                      //       alt=""
                      //       src={require("../../assets/image/login-psd.png")}
                      //       className="logo-psd-icon"
                      //     />
                      //   }
                      placeholder="请输入重置密码"
                      autocomplete="new-password"
                      maxLength={16}
                    />
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 20 }}>
                  {getFieldDecorator("userConfirmPwd", {
                    rules: [
                      { required: true, message: "确认密码未输入" },
                      {
                        validator: this.confirmPassword,
                      },
                    ],
                  })(
                    <Input.Password
                      //   className="round-large certificate-input login-input"
                      //   prefix={
                      //     <img
                      //       alt=""
                      //       src={require("../../assets/image/login-psd.png")}
                      //       className="logo-psd-icon"
                      //     />
                      //   }
                      placeholder="请输入确认密码"
                      autocomplete="new-password"
                      maxLength={16}
                    />
                  )}
                </Form.Item>

                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", height: 40 }}
                    // shape="round"
                  >
                    重置密码
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(LoginForm);

export default withRouter(WrappedNormalLoginForm);
