/**
 * @fileoverview 系统管理---账号设置 -- 搜索条件
 * @author  蒋西园
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Select, Row, Col } from 'antd';
import moment from 'moment';
import './index.less';

const FormItem = Form.Item;
const Option = Select.Option;

class SearchAttendancelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postList: [],
      searchVale: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ searchVale: nextProps.searchVale });
    console.log(nextProps.searchVale);
  }

  // 查询岗位
  listForDropDownPost = () => {
    this.$http.listForDropDownPost().then(res => {
      if (res.code === 200) {
        this.setState({
          postList: res.data,
        });
      }
    });
  };
  // 提交表单
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return false;
      }
      this.state.postList.map(item => {
        if (item.postId == values.postId) {
          values.postName = item.postName;
        }
      });
      const data = {};
      // 去除含有undefined的item
      Object.keys(values).forEach(res => {
        if (values[res]) {
          // if (res == 'isLeave') {
          //   data[res] = values[res] === '1'
          // } else {
          // }
          data[res] = values[res];
        } else {
          data[res] = '';
        }
      });
      this.props.handleSearchBtn(data);
    });
  };

  // 重置
  handleReset = () => {
    this.setState({
      searchVale: { staffName: '', postId: '', postName: undefined },
    });
    this.props.form.resetFields();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return false;
      }
      this.props.handleSearchBtn({
        staffName: '',
        postId: '',
        postName: undefined,
      });
    });
    this.props.form.resetFields();
  };

  onDropdownVisiblePost = open => {
    if (open) {
      this.listForDropDownPost();
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { searchVale } = this.state;
    return (
      <div className="search-form">
        <Form>
          <Row>
            <Col style={{ marginLeft: 20 }}>
              <FormItem
                label="姓名"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
              >
                {getFieldDecorator('staffName', {
                  rules: [
                    { max: 32, message: '请输入32字以内的名字' },
                    {
                      pattern: new RegExp(/^[A-Za-z\u4e00-\u9fa5]+$/, 'g'),
                      message: '仅支持输入中英文',
                    },
                  ],
                  initialValue: searchVale.staffName,
                })(
                  <Input
                    placeholder="请输入"
                    allowClear
                    style={{ width: 180 }}
                  />
                )}
              </FormItem>
            </Col>
            {/* <Col style={{ marginLeft: 20 }}>
              <FormItem label="离职">
                {getFieldDecorator('isLeave', {
                  initialValue: searchVale.isLeave==true?"1":searchVale.isLeave===false?"2":undefined
                })(
                  <Select placeholder="请选择" allowClear style={{width:180}} >
                    <Option value="1">离职</Option>
                    <Option value="2">未离职</Option>
                  </Select>
                )}
              </FormItem>
            </Col> */}
            <Col style={{ marginLeft: 20 }}>
              <FormItem label="岗位">
                {getFieldDecorator('postId', {
                  initialValue: searchVale.postName,
                })(
                  <Select
                    style={{ width: 180 }}
                    placeholder="请选择"
                    showSearch
                    allowClear
                    onDropdownVisibleChange={this.onDropdownVisiblePost}
                    optionFilterProp="children"
                  >
                    {this.state.postList.map(item => {
                      return (
                        <Option key={item.postId} value={item.postId}>
                          {item.postName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={5} style={{ marginLeft: 20 }}>
              <Button onClick={this.handleReset}>重置</Button>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 14 }}
              >
                查询
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(Form.create()(SearchAttendancelist));
