/**
* @fileoverview 系统管理---岗位管理 -- 新增弹框
* @author  蒋西园
*/
import React,{Component} from 'react';
import './index.less'
import { Modal, Form, Input, Select, Tooltip, Icon } from "antd";
const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

class addPostModal extends Component{
    constructor(props){
		super(props)
        this.state = {
            roleList: []
        }
    }
    // 点击确定
    handleAddSure = (e) => {
        e.preventDefault();
        const { form, type } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.handleAddSure(type,values)
            }
        })
    }
    // 点击取消关闭弹框
    handleCancel = () => {
        this.props.handleCancel();
    }
    onDropdownVisibleChange = (open) => {
        if(open) {
            this.props.listForDropDownRole()
        }
    }
    render(){
        const { addModalShow, form, type, postItem, roleList } = this.props;
        const { getFieldDecorator } = form;
        return(
            <Modal
                className="setUserModal"
                title={type === 0 ? '添加岗位' : '修改岗位'}
                visible={addModalShow}
                width={500}
                onCancel={this.handleCancel}
                onOk={this.handleAddSure}
                maskClosable={false}
            >
                <div className="setUser-box" style={{marginLeft:20}}>
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
                        <FormItem 
                            label="岗位">
                            {getFieldDecorator('postName', {
                                initialValue: postItem.postName,
                                rules: [
                                    { required: true,whitespace:true,  message: '请输入岗位' },
                                    { max: 16, message: '请输入16字以内的岗位名称'},
                                    { 
                                        pattern: new RegExp(/^[A-Za-z0-9\u4e00-\u9fa5]+$/, "g"),
                                        message:'只能输入中英文数字'}
                                ],
                            })(
                                <Input placeholder="请输入" allowClear autoComplete="new-password" />
                            )}
                        </FormItem>
                        <FormItem 
                            label="初始化角色">
                            {getFieldDecorator('rolesIds', {
                                initialValue: (postItem.roles || []).map(item => item.rolesId) || [],
                                rules: [
                                    { required: true, message: '请选择角色' }
                                ],
                            })(
                                <Select 
                                    placeholder="请选择"
                                    mode="multiple" 
                                    allowClear
                                    onDropdownVisibleChange={this.onDropdownVisibleChange}
                                    showSearch
                                    optionFilterProp="children"
                                >
                                    {
                                        roleList.map(item => {
                                            return <Option key={item.rolesId} value={item.rolesId}>{item.rolesName}</Option>
                                        })
                                    }
                                </Select>
                            )}
                            <Tooltip title="用于员工入职时初始化账号">
                                <Icon className="info-circle-icon" type="info-circle" />
                            </Tooltip>
                        </FormItem>
                        <FormItem 
                            label="岗位职责">
                            {getFieldDecorator('postDuties', {
                                initialValue: postItem.postDuties,
                                rules: [
                                    { max: 128, message: '请输入128字以内的岗位职责内容'}
                                ],
                            })(
                                <TextArea placeholder="请输入" allowClear autoComplete="new-password" autoSize={{ minRows: 3, maxRows: 5 }}/>
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        )
    }
}
export default Form.create()(addPostModal)