import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Breadcrumb, Tabs, Calendar, Modal, Button, Icon } from "antd";
import "./index.less";
import CustomCalendar from "../../components/calendar";
import NoData from "../../components/no-data";
import moment from "moment";
import util from "../../utils/utils";
import $ from "jquery";

const { TabPane } = Tabs;

let weeks = {
  1: "周一",
  2: "周二",
  3: "周三",
  4: "周四",
  5: "周五",
  6: "周六",
  7: "周七",
};

let colors = {
  0: "#4D91FA",
  1: "#68CFAC",
  2: "#E5537B",
  3: "#F8808B",
  4: "#9799D6",
  5: "#70C2D6",
  6: "#84A9DE",
  7: "#F6C28F",
};

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaveListVOS: [],
      leaveDayWithIdentificationVOS: [],
      currentId: "1",
      notification: [],
      notificationId: "1",
      quickProcessTemplate: [],
      statVO: {},
      departmentList: [],
      visible: false,
      attendanceDate: moment().format("YYYY-MM"),
      LEAVE_TYPE: [],
      dateList: [2, 5, 8],
      time: moment(),
      tabkey: 1,
      isImperfectInfo: false, //是否完善个人信息
    };
  }

  componentDidMount() {
    this.queryIsImperfect();
    this.queryQuickProcessTemplate();
    this.getNotification();

    this.manageDepartmentStat();
    this.queryLeave();
  }

  //查询用户是否完善个人信息
  queryIsImperfect = () => {
    this.$http["queryIsImperfect"]().then((res) => {
      if (res.code === 200 && res.data) {
        this.setState({
          isImperfectInfo: res.data.code === 200 ? false : true,
        });
      }
    });
  };

  queryLeaveType = () => {
    this.$http["selectListByGroup"]({
      dicGroup: "LEAVE_TYPE",
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          LEAVE_TYPE: res.data || [],
        });
      }
    });
  };

  // 公告
  getNotification = () => {
    this.$http["notification"]({}).then((res) => {
      if (res.code === 200) {
        console.log(res.data);
        this.setState({
          notification: res.data || [],
          notificationId:
            res.data.length == 0 ? "" : res.data[0].notificationId || "",
        });
      }
    });
  };
  // 快捷流程列表
  queryQuickProcessTemplate = () => {
    this.$http["queryQuickProcessTemplate"]({}).then((res) => {
      if (res.code === 200) {
        this.setState({
          quickProcessTemplate: res.data || [],
        });
      }
    });
  };
  // 快捷流程跳转
  handleQuickProcess = (item) => {
    if (item.templateCode === "LEAVE_PROC") {
      this.props.history.push("/workbench/leave");
    } else if (item.templateCode === "ATTE_PROC") {
      this.props.history.push("/workbench/monthlyAttendance");
    } else if (item.templateCode === "BECOME_PROC") {
      this.props.history.push("/workbench/becomeWorker");
    } else if (item.templateCode === "OFFER_PROC") {
      this.props.history.push("/workbench/offerApproval");
    } else if (item.templateCode === "ENTRY_PROC") {
      this.props.history.push("/workbench/checkin");
    } else if (item.templateCode === "DIMI_PROC") {
      this.props.history.push("/workbench/quit");
    } else if (item.templateCode === "SPON_DIMI_PROC") {
      this.props.history.push("/workbench/autoQuit");
    }
  };
  // 管辖部门统计
  manageDepartmentStat = () => {
    this.$http["manageDepartmentStat"]({}).then((res) => {
      if (res.code === 200) {
        this.setState({
          statVO: res.data.statVO || {},
          departmentList: res.data.departmentList || [],
        });
      }
    });
  };
  // 请假信息
  queryLeave = (attendanceDate = this.state.attendanceDate) => {
    this.$http["leave"]({
      attendanceDate: attendanceDate,
    }).then((res) => {
      if (res.code === 200) {
        let leaveListVOS = res.data.leaveListVOS || [];
        leaveListVOS = leaveListVOS.slice(0, 5);
        this.setState(
          {
            leaveListVOS: res.data.leaveListVOS || [],
            leaveDayWithIdentificationVOS:
              res.data.leaveDayWithIdentificationVOS || [],
          },
          () => {
            this.queryLeaveType();
          }
        );
      }
    });
  };

  // handleLink
  handleLink = (type) => {
    if (type === "dept") {
      this.props.history.push("/workbench/jurisdiction");
    } else if (type === "schedule") {
      this.props.history.push("/workbench/schedule");
    } else if (type === "imperfect") {
      this.props.history.push("/workbench/imperfect");
    }
  };
  // handleDetail
  handleDetail = (item) => {
    this.setState({
      visible: true,
    });
    this.$http["getNotificationById"]({
      notificationId: item.notificationId,
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          notificationDetail:
            util.HtmlUtil.htmlDecodeByRegExp(res.data.content) || {},
        });
      }
    });
  };
  handleOk = () => {
    this.handleCancel();
  };
  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onSelect = (value) => {
    this.setState(
      {
        time: value,
      },
      () => {
        // this.fetchScheduleByDay();
      }
    );
  };

  onPanelChange = (value) => {
    this.setState(
      {
        time: value,
        attendanceDate: value.format("YYYY-MM"),
      },
      () => {
        this.queryLeave(value.format("YYYY-MM"));
      }
    );
  };

  dateCellRender = (value) => {
    // console.log(value.date());
    // return <span>{value.date() > 9 ? value.date() : "0" + value.date()}</span>;
    // console.log(leaveDayWithIdentificationVOS);

    if (value.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      $(".ant-fullcalendar-today")
        .children()
        .find(".ant-fullcalendar-value")
        .css("background", "#1890ff");
    }

    return this.state.leaveDayWithIdentificationVOS.map((item) => {
      if (
        moment(item.date).format("YYYY-MM-DD") === value.format("YYYY-MM-DD")
      ) {
        if (
          moment(item.leaveStartTime).format("YYYY-MM-DD") ===
          moment().format("YYYY-MM-DD")
        ) {
          $(".ant-fullcalendar-today")
            .children()
            .find(".ant-fullcalendar-value")
            .css("background", "transparent");
        }
        return (
          <span
            key={item.date}
            className={`leave-day leave-day-${item.identifier}`}
          >
            {value.date() > 9 ? value.date() : "0" + value.date()}
          </span>
        );
      }
    });
  };

  handleSchedule = (item) => {
    this.props.history.push(
      `/workbench/schedule?departmentId=${item.departmentId}`
    );
  };

  handleTabChange = (tabkey) => {
    this.setState(
      {
        tabkey,
      },
      () => {
        if (tabkey == 1) {
          this.queryLeave(this.state.attendanceDate);
        } else if (tabkey == 2) {
          this.getNotification();
        }
      }
    );
  };

  render() {
    const {
      leaveListVOS,
      currentId,
      notification,
      notificationId,
      quickProcessTemplate,
      statVO,
      departmentList,
      visible,
      notificationDetail,
      isImperfectInfo,
    } = this.state;
    return (
      <div className="workbench-index">
        <div className="index-left" style={{ flex: 1 }}>
          <div className="top-wrap">
            {quickProcessTemplate.map((item) => {
              return (
                <div
                  key={item.templateCode}
                  className="top-item"
                  onClick={() => this.handleQuickProcess(item)}
                  style={{
                    backgroundImage:
                      "url(" +
                      require(`../../assets/image/processBg.png`) +
                      ")",
                  }}
                >
                  <img
                    src={require(`../../assets/image/${item.templateCode}.png`)}
                    className="item-img"
                    alt=""
                  />
                  <span className="item-text">{item.templateName}</span>
                </div>
              );
            })}
          </div>
          {statVO.manageDepartmentCount && (
            <div className="center-wrap">
              <div className="center-main">
                <img
                  alt=""
                  src={require("../../assets/image/center-left.png")}
                  className="center-main-img"
                />
                <div className="center-item-wrap">
                  <div
                    className="center-item"
                    onClick={() => this.handleLink("dept")}
                  >
                    <div className="item-num">
                      {statVO.manageDepartmentCount}
                    </div>
                    <div className="item-text">管辖部门</div>
                  </div>
                  <div
                    className="center-item"
                    onClick={() => this.handleLink("dept")}
                  >
                    <div className="item-num">{statVO.staffCount}</div>
                    <div className="item-text">人员统计</div>
                  </div>
                  <div
                    className="center-item"
                    onClick={() => this.handleLink("schedule")}
                  >
                    <div className="item-num">{statVO.todoCount}</div>
                    <div className="item-text">待办统计</div>
                  </div>
                  <div
                    className="center-item"
                    onClick={() => this.handleLink("imperfect")}
                  >
                    <div className="item-num">
                      {statVO.perfectCount}
                      <span style={{ fontSize: 14 }}>人</span>
                    </div>
                    <div className="item-text">未完善</div>
                  </div>
                </div>
                <img
                  alt=""
                  src={require("../../assets/image/center-right.png")}
                  className="center-right-img"
                />
              </div>
            </div>
          )}
          {isImperfectInfo && (
            <div className="reminder-content">
              <span className="reminder-item">
                *温馨提醒：您还未完善个人信息，赶快去小程序中完善吧！
              </span>
            </div>
          )}
          <div className="bottom-wrap">
            {departmentList.map((item, index) => {
              return (
                <div
                  className="bottom-item"
                  key={item.departmentId}
                  onClick={() => this.handleSchedule(item)}
                >
                  <div
                    className="item-left"
                    style={{
                      background: colors[index % 8],
                    }}
                  >
                    <div className="left-num">{item.todoCount}</div>
                    <div className="left-text">待办</div>
                  </div>
                  <div className="item-right">
                    <span className="department">{item.departmentName}</span>
                    {item.remind && (
                      <span className="offer-tip">
                        offer即将过期，请尽快审核
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="index-right">
          <div id="calendarContainer">
            {/* <CustomCalendar
              dateList={this.state.dateList}
              nextMonth={() => {
                this.setState({
                  dateList: [5, 12, 24]
                });
              }}
            /> */}
          </div>
          <div className="calendar-main-content">
            <Calendar
              key={new Date().getTime()}
              fullscreen={false}
              value={this.state.time}
              headerRender={({ value, type, onChange, onTypeChange }) => {
                return (
                  <div className="calender-head">
                    <div>
                      <img
                        src={require("../../assets/image/calendar.png")}
                        alt=""
                        style={{ width: 26 }}
                      />
                      <span style={{ marginLeft: 8 }}>{`${moment().month() +
                        1}/${moment().format("D")}`}</span>
                      <span style={{ marginLeft: 8 }}>
                        {weeks[moment().format("d")]}
                      </span>
                    </div>
                    <div>
                      <Icon
                        type="left-circle"
                        style={{ color: "#ADB7CC" }}
                        onClick={() => {
                          onChange(moment(value).subtract(1, "months"));
                        }}
                      />
                      <span className="calender-title">{`${value.year()}年${value.month() +
                        1}月`}</span>
                      <Icon
                        type="right-circle"
                        style={{ color: "#ADB7CC" }}
                        onClick={() => {
                          onChange(moment(value).add(1, "months"));
                        }}
                      />
                    </div>
                  </div>
                );
              }}
              dateCellRender={this.dateCellRender}
              onSelect={this.onSelect}
              onPanelChange={this.onPanelChange}
            />
            <div className="calendar-footer">
              <span className="footer-span footer-span-0">节假日</span>
              <span className="footer-span footer-span-4">工作日</span>
              <span className="footer-span footer-span-3">考勤确认</span>
              <span className="footer-span footer-span-2">请假</span>
            </div>
          </div>
          <div>
            <div style={{ marginBottom: 13 }}>
              <span
                className={
                  this.state.tabkey == 1
                    ? "tab-normal tab-active"
                    : "tab-normal"
                }
                onClick={() => this.handleTabChange(1)}
              >
                请假记录
              </span>
              <span
                className={
                  this.state.tabkey == 2
                    ? "tab-normal tab-active"
                    : "tab-normal"
                }
                onClick={() => this.handleTabChange(2)}
              >
                公告
              </span>
            </div>
            {this.state.tabkey == 1 && (
              <div>
                {leaveListVOS.length ? (
                  leaveListVOS.map((item) => {
                    return (
                      <div
                        className={
                          currentId == item.leaveProId
                            ? "normal-record current-record"
                            : "normal-record"
                        }
                        onMouseEnter={() => {
                          this.setState({
                            currentId: item.leaveProId,
                          });
                        }}
                        onClick={() => {
                          this.props.history.push(
                            `/workbench/leave?leaveProcId=${item.leaveProId}&procTemplate=LEAVE_PROC`
                          );
                        }}
                      >
                        <div className="record-top">
                          <span className="top-arrow"></span>
                          <span className="top-number">
                            {this.state.LEAVE_TYPE.filter(
                              (type) => type.dicKey == item.leaveType
                            )[0] &&
                              this.state.LEAVE_TYPE.filter(
                                (type) => type.dicKey == item.leaveType
                              )[0].dicValue}
                            （单号：
                            {item.leaveProId}）
                          </span>
                        </div>
                        <div className="record-bottom">{`${item.leaveStartTime}~${item.leaveEndTime}`}</div>
                      </div>
                    );
                  })
                ) : (
                  <NoData height="200px" width="100%" />
                )}
              </div>
            )}
            {this.state.tabkey == 2 && (
              <div>
                {notification.length ? (
                  notification.map((item) => {
                    return (
                      <div
                        className={
                          notificationId == item.notificationId
                            ? "normal-record current-record"
                            : "normal-record"
                        }
                        onMouseEnter={() => {
                          this.setState({
                            notificationId: item.notificationId,
                          });
                        }}
                        onClick={() => this.handleDetail(item)}
                      >
                        <div className="record-top">
                          <span className="top-arrow"></span>
                          <span className="top-number">{item.title}</span>
                        </div>
                        <div className="record-bottom">{item.releaseTime}</div>
                      </div>
                    );
                  })
                ) : (
                  <NoData height="200px" width="100%" />
                )}
              </div>
            )}
          </div>
        </div>
        <Modal
          visible={visible}
          title="公告详情"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              关闭
            </Button>,
          ]}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: notificationDetail,
            }}
          ></div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(MainManagement);
