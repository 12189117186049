/**
* @fileoverview 系统管理---岗位设置
* @author  蒋西园
*/
import React,{ Component } from 'react'
import './index.less'

import { Button, message } from 'antd';
import SearchForm from './component/searchForm' // 搜索条件
import Table from "./component/tableList"  		// 数据列表
import AddModal from './component/addAccount'	// 新增或修改岗位


class PostManagement extends Component{
	constructor(props){
		super(props)
		this.state = {
			loading: false,
            dataList: [],     	// 岗位列表数据
            total: 100,       	// 岗位列表总数
            pageSize: 10,     	// 分页Size
            pageNo: 1,        	// 分页页码
			searchVale: {},   	// 搜索条件
			addModalShow: false,// 新增弹框显隐
			modalType: 0,		// 0：添加岗位弹框 1：修改岗位弹框
			postItem: {},		// 编辑岗位弹框回显数据
			disabled: false,	// 防重复点击
			roleList: [],		// 角色下拉框
		}
	}
	componentDidMount(){
		this.queryTableList();
		this.listForDropDownRole(); // 拒绝code闪现
	}
    /**
     * 函数描述: 查询角色下拉数据
     */
	listForDropDownRole = () => {
		this.$http.listForDropDownRole().then(res => {
			if(res.code === 200) {
				this.setState({
					roleList: res.data
				})
			}
		})
	}
    /**
     * 函数描述: 查询岗位数据
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} postName   	岗位名称
     * @return {Object} 
     */
    queryTableList = (pageNo=1, pageSize=10) =>{
        this.setState({
			loading: true
		},() => {
			const params = {
				...this.state.searchVale,
				pageNo,
				pageSize
			}
			this.$http.postList(params).then((res) => {
				if(res.code == 200){
					this.setState({
						loading: false,
						pageNo,
						pageSize,
						dataList: res.data.list,
						total: res.data.total
					})
				}
			}).catch((err) => {
				this.setState({
					loading: false,
				})
			})
		})
	}
    /**
     * 函数描述: 高级搜索
     * @param {Object} value   高级搜索条件
     * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
     */
    handleSearchBtn = (value) =>{
        this.setState({
            searchVale: {...value}
        },() => {
            this.queryTableList();
        })
	}
    /**
     * 函数描述: 切换列表页码
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @return {Object} 请求pageNo页列表数据
     */
    handleChangePage = (pageNo,pageSize) => {
		this.queryTableList(pageNo,pageSize);
	}
    /**
     * 函数描述: 新增岗位
     * @return 打开新增弹框
     */
	handleAddSubmit = () => {
		this.listForDropDownRole();
		this.refs.addModal.resetFields(); // 避免数据闪现
		this.setState({
			addModalShow: true,
			modalType: 0,
			postItem: {}
		})
	}
	// 关闭弹框
	handleCancelModal = () => {
		this.setState({
			addModalShow: false
		})
	}
    /**
     * 函数描述: 删除岗位
     * @param {Object} postId 该岗位所有信息 -- 后期要精简参数更换为唯一标识
     * @return {Object} 刷新当前页数据
     */
	handleDelete = (postId) => {
		this.$http.postDelete({postId}).then(res => {
			if (res.code === 200) {
				const { pageNo, pageSize } = this.state;
				this.queryTableList(pageNo,pageSize);
			}
		})
	}
    /**
     * 函数描述: 编辑岗位
     * @return 打开新增弹框
     */
	handleEditSubmit = (item) => {
		this.listForDropDownRole();
		this.refs.addModal.resetFields(); // 避免数据闪现
		this.setState({
			addModalShow: true,
			modalType: 1,
			postItem: item
		})
	}
    /**
     * 函数描述: 修改或新增确认
     * @param {string} type 弹框类型 0：新增 1：修改
     * @param {Object} value.postName 	岗位名称
     * @param {Object} value.postDuties 岗位职责
     * @param {Object} value.rolesIds 	角色id
     * @return 
     */
	handleAddSure = (type,value) => {
		const { disabled } = this.state;
		if(!disabled) {
			this.setState({
				disabled: true
			},() => {
				if (type === 0) { // 新增弹框
					this.$http.postAdd(value).then(res => {
						this.setState({
							disabled: false
						},() => {
							if (res.code === 200){
								this.handleCancelModal();
								this.queryTableList();
							}
						})
					})
				}else{
					this.$http.postUpdate({
						...value,
						postId: this.state.postItem.postId
					}).then(res => {
						this.setState({
							disabled: false
						},() => {
							if (res.code === 200){
								this.handleCancelModal();
								const { pageNo, pageSize } = this.state;
								this.queryTableList(pageNo, pageSize);
							}
						})
					})
				}
			})
		}
	}
	render(){
		const { dataList, total, pageNo, pageSize, 
			loading, addModalShow, modalType, postItem, roleList } = this.state;
		return (
		<div className="post-management">
			<div className="crumbs">岗位管理</div>
			{/* 搜索框 */}
			<SearchForm handleSearchBtn={this.handleSearchBtn} />
			{/* 岗位数据 */}
			<div className="post-box wrap-card">
				<Button className="addButton" type="primary"  onClick={this.handleAddSubmit}>添加</Button>
				<Table 
					loading={loading} 
					data={dataList} 
					total={total}
					pageNo={pageNo}
					pageSize={pageSize}
					handleChangePage={this.handleChangePage} // 切换页码
					handleDelete={this.handleDelete}		 // 删除岗位
					handleEditSubmit={this.handleEditSubmit} // 编辑岗位
				/>
			</div>
			{/* 新增或修改岗位 */}
			<AddModal
				ref="addModal"
				addModalShow={addModalShow}
				type={modalType}
				postItem={postItem}
				roleList={roleList}
				handleCancel={this.handleCancelModal}
				handleAddSure={this.handleAddSure}
				listForDropDownRole={this.listForDropDownRole}
			/>
		</div>
		)
	}
}

export default PostManagement
