/**
 * @fileoverview 系统管理---账号设置 -- 新增弹框
 * @author  蒋西园
 */
import React, { Component } from 'react';
import './index.less';
import { Modal, Form, Input, Select, TreeSelect, DatePicker } from 'antd';
import { map } from 'lodash';
const FormItem = Form.Item;
const Option = Select.Option;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
class addAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      isChangeTreeSlect: false,
    };
  }
  componentDidMount() {
    this.setState({
      treeData: this.props.dataRightsListInitValues,
    });
  }
  // 点击确定
  handleAddSure = e => {
    e.preventDefault();
    const { form, dataRightsListInitValues } = this.props;
    const { isChangeTreeSlect, treeData } = this.state;
    form.validateFieldsAndScroll((err, values) => {
      console.log('values ->', values);
      if (!err) {
        console.log('this.state.treeData ->', this.state.treeData);
        values.departmentPermissionsPos = this.getTreeParams(
          isChangeTreeSlect ? treeData : dataRightsListInitValues
        );
        this.props.handleAddSure(values);
      }
    });
  };
  /**
   * @method 遍历树结构获取后台需要的参数
   * @param { Array } keyArr 选中的树集合
   */
  getTreeParams = keyArr => {
    const params = [];
    const { accountItem, dataRightsList } = this.props;
    const { staffCode } = accountItem;
    const fn = data =>
      map(data, item => {
        const { key, level, children } = item;
        if (keyArr.includes(key)) {
          params.push({
            departmentSonId: key,
            level: Number(level),
            staffCode,
          });
        }
        children && Array.isArray(children) && fn(children);
      });

    fn(dataRightsList);
    return params;
  };
  // 点击取消关闭弹框
  handleCancel = () => {
    this.props.form.setFieldsValue({ rolesIds: [] });
    this.props.handleCancel();
  };
  onDropdownVisibleChange = open => {
    if (open) {
      this.props.listForDropDownRole();
    }
  };
  render() {
    const {
      addModalShow,
      form,
      accountItem,
      roleList,
      dataRightsList,
      dataRightsListInitValues,
    } = this.props;
    debugger;
    console.log('dataRightsListInitValues -->', dataRightsListInitValues);
    const { getFieldDecorator } = form;
    return (
      <Modal
        className="setUserModal"
        title="编辑"
        visible={addModalShow}
        width={500}
        onCancel={this.handleCancel}
        onOk={this.handleAddSure}
        maskClosable={false}
      >
        <div className="setUser-box">
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 17 }}>
            <FormItem label="登录账号">
              <span>{accountItem.staffAccount}</span>
            </FormItem>
            <FormItem label="密码">
              {getFieldDecorator('passWord', {
                initialValue: accountItem.passWord,
                rules: [
                  {
                    max: 16,
                    message: '请输入16字符以内的密码',
                  },
                ],
              })(
                <Input
                  type="password"
                  placeholder="请输入"
                  allowClear
                  autoComplete="new-password"
                />
              )}
            </FormItem>
            <FormItem label="部门">
              <span>{accountItem.departmentName}</span>
            </FormItem>
            <FormItem label="岗位">
              <span>{accountItem.postName}</span>
            </FormItem>
            <FormItem label="角色">
              {getFieldDecorator('rolesIds', {
                initialValue: (accountItem.roles || []).map(
                  item => item.rolesId
                ),
              })(
                <Select
                  placeholder="请选择"
                  mode="multiple"
                  allowClear
                  onDropdownVisibleChange={this.onDropdownVisibleChange}
                  showSearch
                  optionFilterProp="children"
                >
                  {(roleList || []).map(item => {
                    return (
                      <Option key={item.rolesId} value={item.rolesId}>
                        {item.rolesName}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
            <FormItem label="数据权限">
              {getFieldDecorator('dataRights', {
                initialValue: dataRightsListInitValues,
              })(
                <TreeSelect
                  className="data-rights-tree"
                  treeData={dataRightsList}
                  dropdownStyle={{ maxHeight: 256 }}
                  treeCheckable={true}
                  onChange={(value, node, ex) => {
                    console.log('ex -->', ex);
                    console.log('value ->', value);
                    const treeData = [];

                    const { allCheckedNodes } = ex;
                    if (!allCheckedNodes) {
                      return;
                    }
                    const fn = data => {
                      data.forEach(o => {
                        const { node, children, key } = o;
                        if (!node) {
                          treeData.push(key);
                        } else {
                          treeData.push(node.key);
                          children && Array.isArray(children) && fn(children);
                        }
                      });
                    };
                    fn(allCheckedNodes);
                    console.log('treeData ->', treeData);
                    this.setState({
                      treeData,
                      isChangeTreeSlect: true,
                    });
                  }}
                  showCheckedStrategy={TreeSelect.SHOW_PARENT}
                  // multiple={true}
                  // treeCheckStrictly={true}
                />
              )}
            </FormItem>
          </Form>
        </div>
      </Modal>
    );
  }
}
export default Form.create()(addAccountModal);
