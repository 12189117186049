/**
 * @fileoverview 系统管理---账号设置 -- 数据列表
 * @author  黄洪月
 */
import React, { Component } from 'react';
import { Table, Tooltip } from 'antd';
import './index.less';

class tableList extends Component {
  constructor(props) {
    super(props);
    // 列表
    this.columns = [
      {
        title: '工号',
        dataIndex: 'staffCode',
        fixed: 'left',
        width: 120,
        render: text => <p className="salary_management_remacks">{text}</p>,
      },
      {
        title: '员工姓名',
        dataIndex: 'staffName',
        fixed: 'left',
        width: 120,
        render: text => <p className="salary_management_remacks">{text}</p>,
      },
      {
        title: '请假',
        dataIndex: 'takeLeave',
        width: 70,
      },
      {
        title: '薪资周期',
        dataIndex: 'salaryCycle',
        width: 110,
      },
      {
        title: '基本月薪',
        dataIndex: 'basicMonthlySalary',
        width: 110,
        render: text => `￥${text}`,
      },
      {
        title: '本月应出勤天数',
        dataIndex: 'monthShouldDays',
        width: 130,
      },
      {
        title: '本月出勤天数',
        dataIndex: 'monthDays',
        width: 110,
      },
      {
        title: '本月出勤率',
        dataIndex: 'monthAttendanceRate',
        width: 100,
      },
      {
        title: '本月应发工资',
        dataIndex: 'monthShouldSalary',
        width: 110,
        render: text => `￥${text}`,
      },
      {
        title: '本月业绩奖金',
        dataIndex: 'monthPerformanceBonus',
        width: 110,
        render: text => `￥${text}`,
      },
      {
        title: '本月薪资合计',
        dataIndex: 'salaryTotal',
        width: 110,
        render: text => `￥${text}`,
      },
      {
        title: '社会保险个人缴纳部分合计',
        dataIndex: 'socialInsuranceIndividualPay',
        width: 190,
        render: text => `￥${text}`,
      },
      {
        title: '养老保险个人缴纳金额',
        dataIndex: 'personInsuranceIndividualPay',
        width: 160,
        render: text => `￥${text}`,
      },
      {
        title: '失业保险个人缴纳金额',
        dataIndex: 'unemploymentInsuranceIndividualPay',
        width: 140,
        render: text => `￥${text}`,
      },
      {
        title: '医疗保险个人缴纳金额',
        dataIndex: 'medicalInsuranceIndividualPay',
        width: 140,
        render: text => `￥${text}`,
      },
      {
        title: '住房公积金个人缴纳金额',
        dataIndex: 'housingFundIndividualPay',
        width: 150,
        render: text => `￥${text}`,
      },
      {
        title: '税前薪资合计',
        dataIndex: 'beforeTaxSalaryTotal',
        width: 100,
        render: text => `￥${text}`,
      },
      {
        title: '代扣个税',
        dataIndex: 'withHoldingTax',
        width: 100,
        render: text => `￥${text}`,
      },
      {
        title: '税后实发工资',
        dataIndex: 'afterTaxSalary',
        width: 100,
        render: text => `￥${text}`,
      },
      {
        title: '社会保险公司缴纳合计',
        dataIndex: 'socialInsuranceCompanyPay',
        width: 140,
        render: text => `￥${text}`,
      },
      {
        title: '养老保险公司缴纳金额',
        dataIndex: 'personInsuranceCompanyPay',
        width: 140,
        render: text => `￥${text}`,
      },
      {
        title: '失业保险公司缴纳金额',
        dataIndex: 'unemploymentInsuranceCompanyPay',
        width: 140,
        render: text => `￥${text}`,
      },
      {
        title: '工伤保险公司缴纳金额',
        dataIndex: 'workInjuryInsuranceCompanyPay',
        width: 140,
        render: text => `￥${text}`,
      },
      {
        title: '生育保险公司缴纳金额',
        dataIndex: 'maternityInsuranceCompanyPay',
        width: 140,
        render: text => `￥${text}`,
      },
      {
        title: '医疗保险公司缴纳金额',
        dataIndex: 'medicalInsuranceCompanyPay',
        width: 140,
        render: text => `￥${text}`,
      },
      {
        title: '住房公积金公司缴纳金额',
        dataIndex: 'housingFundCompanyPay',
        width: 150,
        render: text => `￥${text}`,
      },
      {
        title: '社保缴纳基数',
        dataIndex: 'socialSecurityPayBase',
        width: 100,
        render: text => `￥${text}`,
      },
      {
        title: '公积金缴纳基数',
        dataIndex: 'providentFundPayBase',
        width: 100,
        render: text => `￥${text}`,
      },
      {
        title: '考勤扣款',
        dataIndex: 'attendanceDeduction',
        width: 100,
        render: text => `￥${text}`,
      },
      {
        title: '其他扣款',
        dataIndex: 'otherDeduction',
        width: 100,
        render: text => `￥${text}`,
      },
      {
        title: '其他费用',
        dataIndex: 'otherExpense',
        width: 100,
        render: text => `￥${text}`,
      },
      {
        title: '备注',
        dataIndex: 'remarks',
        width: 115,
        fixed: 'right',
        render: text =>
          text && text.length ? (
            <p className="salary_management_remacks">
              <Tooltip placement="topLeft" title={text}>
                {text}
              </Tooltip>
            </p>
          ) : (
            '无'
          ),
      },
    ];
  }
  render() {
    return (
      <Table
        rowKey="staffCode"
        loading={this.props.loading}
        columns={this.columns}
        dataSource={this.props.data}
        scroll={{ x: 3560 }}
        locale={{
          emptyText: (
            <div>
              <img
                style={{ width: 182, height: 94 }}
                src={require('../../../../../assets/image/no-data.png')}
                alt=""
              />
              <p
                style={{
                  fontSize: 14,
                  color: '#717E99',
                  lineHeight: '20px',
                }}
              >
                暂无数据
              </p>
            </div>
          ),
        }}
        pagination={{
          hideOnSinglePage: true,
          current: this.props.pageNo,
          total: this.props.total,
          pageSize: this.props.pageSize || 10,
          onChange: (pageNo, pageSize) =>
            this.props.handleChangePage(pageNo, pageSize),
        }}
      />
    );
  }
}
export default tableList;
