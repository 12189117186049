import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Timeline,
  Icon,
  Avatar,
} from "antd";
import "./index.less";
import utils from "../../utils/utils";
import moment from "moment";

const status = {
  PASS: "通过",
  REFUSE: "拒绝",
  INITIATE: "发起",
  AUDIT: "审核",
  PIGEONHOLE: "归档",
  COMPLETE: "完成",
  STORAGE: "保存",
  RECALL: "撤回",
};

const FormItem = Form.Item;
const Option = Select.Option;

class TimelineList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffName: utils.localDb.get("userData").staffName,
      timeData: [],
      showMore: false,
    };
  }
  //查看更多
  handleMore = () => {
    this.setState({
      timeData: this.props.data,
      showMore: false,
    });
  };

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    const { data } = nextProps;
    if (data !== this.props.data) {
      let arr;
      if (!data.length) {
        arr = [
          {
            id: "001",
            taskName: "发起",
            operEndTimeStr: moment().format("YYYY-MM-DD HH:mm:ss"),
            creator: this.state.staffName,
          },
        ];
      } else {
        arr = data.slice(0, 10);
      }
      this.setState({
        timeData: arr,
        showMore: data.length > 10 ? true : false,
      });
    }
  }

  render() {
    const { timeData, showMore } = this.state;
    return (
      <div className="" style={{ margin: 10 }}>
        <Timeline>
          {timeData.map((item) => {
            return (
              <Timeline.Item
                key={item.id}
                dot={
                  <img
                    src={
                      item.headPortraitKeyUrl ||
                      require("../../assets/image/defaultHead.png")
                    }
                    alt=""
                    style={{ width: 38, height: 38, borderRadius: "50%" }}
                  />
                }
              >
                <div className="time-block">
                  <p className="time-taskname">{item.taskName}</p>
                  <p className="time-opertime">{item.operEndTimeStr}</p>
                  <p className="time-creator">
                    <span className="time-name">{item.creator}</span>
                    <span
                      className={
                        item.operStatus == "PASS"
                          ? "time-status time-status-pass"
                          : item.operStatus == "REFUSE"
                          ? "time-status time-status-refuse"
                          : "time-status"
                      }
                    >
                      {status[item.operStatus]}
                    </span>
                  </p>
                  <p className="time-operdesc">
                    <span
                      // className={item.operStatus == "PASS" ? "desc-pass" : ""}
                    >
                      {item.operDescription}
                    </span>
                  </p>
                </div>
              </Timeline.Item>
            );
          })}
        </Timeline>
        {showMore && (
          <div>
            <Button type="link" onClick={this.handleMore}>
              查看更多
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(TimelineList);
