/**
* @fileoverview 个人中心---修改密码
* @author  蒋西园
*/
import React,{ Component } from 'react'
import './index.less'
import { Form, message, Input, Button } from 'antd';
const FormItem = Form.Item;

class EditPassword extends Component{
	constructor(props){
		super(props)
		this.state = {
            confirmDirty: false
		}
	}
	componentDidMount(){
		
    }
    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFieldsAndScroll(['newPassWordSure'], { force: true });
        }
        callback();
    }
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
          callback('两次密码不一致');
        } else {
          callback();
        }
    };
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.$http.updatePasswordByOld({
                    oldPassword: this.$md5(values.oldPassword),
                    password: this.$md5(values.password)
                }).then(res => {
                    if (res.code === 200) {
                        this.props.history.push('/login');
                    }
                })
            }
        });
    }
    handleCancle = () => {
        this.props.history.go(-1);
    }
	render(){
        const {  } = this.state;
        const { getFieldDecorator } = this.props.form;
		return (
            <div className="edit-password">
                <div className="crumbs">个人信息</div>
                <div className="edit-box">
                    <Form className="password-form" labelCol={{ span: 7 }} wrapperCol={{ span: 15 }} onSubmit={this.handleSubmit}>
                        <FormItem label="旧密码">
                            {getFieldDecorator('oldPassword', {
                                rules: [
                                    { required: true, message: '请输入旧密码' },
                                ],
                            })(
                                <Input type="password" placeholder="请输入" allowClear autoComplete="new-password" />
                            )}
                        </FormItem>
                        <FormItem label="新密码">
                            {getFieldDecorator('password', {
                                rules: [
                                    { required: true, message: '新密码未输入' },
                                    {
                                        max: 16,
                                        message: '密码最大长度为16个字符'
                                    },
                                    { validator: this.validateToNextPassword }
                                ],
                            })(
                                <Input type="password" placeholder="请输入" allowClear autoComplete="new-password" />
                            )}
                        </FormItem>
                        <FormItem label="确认密码">
                            {getFieldDecorator('newPassWordSure', {
                                rules: [
                                    { required: true, message: '确认密码未输入' },
                                    {
                                        max: 16,
                                        message: '密码最大长度为16个字符'
                                    },
                                    { validator: this.compareToFirstPassword}
                                ],
                            })(
                                <Input type="password" placeholder="请输入" onBlur={this.handleConfirmBlur} allowClear autoComplete="new-password" />
                            )}
                        </FormItem>
                        <Form.Item className="btn-item" style={{marginLeft:38}}>
                            <Button onClick={this.handleCancle}>取消</Button>
                            <Button type="primary" htmlType="submit" className="sure-btn">
                                确定
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
		)
	}
}

export default Form.create()(EditPassword)
