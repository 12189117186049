/**
 * 系统设置
 */
import http from "./fetch";

const baseUrl = process.env.API_URL
  ? process.env.API_URL
  : "/personnel-bsm";
const baseUrlbus = process.env.API_URL
  ? process.env.API_URL
  : "/personnel-business";

export default {
  // ------------------------ 员工花名册 ------------------------
  // 花名册管理 -- 列表查询
  personnelMattersList(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffRoster/listStaffRosterByCondition`,
      param
    );
  },
  // 花名册管理 -- 新增
  addStaffRoster(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffRoster/addStaffRoster`,
      param,
      true
    );
  },
  // 花名册管理 -- 修改
  updateStaffRoster(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffRoster/updateStaffRoster`,
      param,
      true
    );
  },
  // 花名册管理 -- 删除
  deleteStaffRosterByStaffId(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffRoster/deleteStaffRosterByStaffId`,
      param,
      true
    );
  },

  // 花名册详情 -- 花名册查询岗位&薪资
  getStaffPostInfoForStaffRoster(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffRoster/getStaffPostInfoForStaffRoster`,
      param
    );
  },

  // 花名册详情 -- 花名册合同
  getStaffInfoContract(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffRoster/getStaffContractByStaffId`,
      param
    );
  },
  // 花名册详情 -- 花名册个人信息
  getStaffInfoForRoster(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffRoster/getStaffInfoForRoster`,
      param
    );
  },
  // 花名册详情 -- 花名册工作经验
  listStafWorkExperienceByStaffId(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffRoster/listStafWorkExperienceByStaffId`,
      param
    );
  },
  // 花名册详情 -- 花名册员工附件
  listStaffAnnexByStaffId(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffRoster/listStaffAnnexByStaffId`,
      param
    );
  },
  // 下载花名册模板
  downloadStaffTemplate(param) {
    return http.downloadFile(
      `${baseUrl}/pc/staffRoster/downloadTemplate`,
      param
    );
  },
  // 导出花名册
  exportStaffRoster() {
    return `${baseUrl}/pc/staffRoster/exportStaffRoster`;
  },
  // 导入花名册
  staffRosterImport(param) {
    return http.post(
      `${baseUrl}/pc/staffRoster/import`,
      param,
      false,
      "blob",
      true
    );
  },
  // 导出错误信息
  exportErrorData(param) {
    return `${baseUrl}/pc/staffRoster/exportErrorData`;
  },

  // ------------------------ 合同管理 ------------------------
  // 合同管理 -- 列表查询
  staffContractList(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffContract/listStaffContractByQuery`,
      param
    );
  },
  // 合同管理 -- 合同详情
  getStaffContractByContractId(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffContract/getStaffContractByContractId`,
      param
    );
  },
  // 合同管理 -- 续签合同查询详情
  getStaffContractByStaffId(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffContract/getStaffContractByStaffId`,
      param
    );
  },
  // 合同管理 -- 续签合同
  renewStaffContract(param, callbak) {
    return http.post(
      `${baseUrl}/pc/staffContract/renewStaffContract`,
      param,
      true
    );
  },
  // ------------------------ 考勤管理 ------------------------
  // 考勤管理 -- 列表查询
  attendanceList(param, callbak) {
    return http.post(
      `${baseUrlbus}/pc/attendance/pageAttendance`,
      param
    );
  },
  // 考勤管理 -- 导出员工月度考勤明细
  attendanceExport() {
    return `${baseUrlbus}/pc/attendance/export`;
  },
  // ------------------------ 薪资管理 ------------------------
  // 薪资管理 -- 列表查询
  salaryList(param, callbak) {
    return http.post(
      `${baseUrlbus}/pc/salary/pageSalary`,
      param
    );
  },
  // 薪资管理 -- 下载模板
  downloadSalaryTemplate(param) {
    return http.downloadFile(
      `${baseUrlbus}/pc/salary/downloadTemplate`,
      param
    );
  },
  // 薪资管理 -- 导入数据
  importSalary(param) {
    return http.post(
      `${baseUrlbus}/pc/salary/import`,
      param,
      false,
      "blob",
      true
    );
  },
  // 薪资管理 -- 导出错误数据
  exportSalary(param) {
    return http.downloadFile(
      `${baseUrlbus}/pc/salary/export`,
      param,
      false,
      "blob"
    );
  },

  // ------------------------ 薪资档案 ------------------------
  // 薪资档案 -- 列表查询
  salaryFileList(param, callbak) {
    return http.post(
      `${baseUrlbus}/pc/salary/salaryList`,
      param
    );
  },
  // 薪资档案 -- 获取编辑详情
  getSalaryFileDetails(param) {
    return http.post(
      `${baseUrlbus}/pc/salary/querySalaryFile`,
      param
    );
  },
  // 薪资档案 -- 保存编辑
  saveSalaryFileEdit(param) {
    return http.post(
      `${baseUrlbus}/pc/salary/updateSalaryFile`,
      param
    );
  },

  //---------------------------offer统计-----------------------------

  //列表
  getOfferStatistics(param) {
    return http.post(
      `${baseUrlbus}/offerStatistics/offerStatisticsList`,
      param
    );
  },
  // 详情
  getOfferDetails(param) {
    return http.post(
      `${baseUrlbus}/offerStatistics/offerDetails`,
      param
    );
  },
  // 导出
  exportStatistics() {
    return `${baseUrlbus}/offerStatistics/exportOfferStatistics`;
  },

  /**********************************员工分析****************************** */
  //列表
  queryEmployeeAnalysisList(param, path) {
    return http.post(`${baseUrl}/pc/${path}`, param);
  },
  // 查询城市列表
  queryCityList() {
    return http.get(
      `${baseUrl}/pc/reportForms/workCityBackfill`
    );
  },
  // 查询招聘专员
  queryRecruitmentSpecialist() {
    return http.get(
      `${baseUrl}/pc/reportForms/recruitmentBackfill`
    );
  },
  // 查询级别
  queryLevelList() {
    return http.get(
      `${baseUrl}/pc/reportForms/occupationBackfill`
    );
  },
  // 导出
  exportEmployeeAnalysis(path) {
    return `${baseUrl}/pc/${path}`;
  },
  // 根据权限获取部门树
  departmentPermissions() {
    return http.post(
      `${baseUrl}/pc/department/departmentPermissions`
    );
  },
};
