/**
* @fileoverview 系统管理---岗位管理 -- 数据列表
* @author  蒋西园
*/
import React, { Component } from 'react';
import { Table, Modal, Tooltip, Icon } from 'antd';
import './index.less'
const { confirm } = Modal;

class tableList extends Component {
    constructor(props) {
        super(props)
        this.columns = [{
                title: '岗位',
                width:'30%',
                dataIndex: 'postName'
            },{
                title: '岗位职责',
                width:'30%',
                dataIndex: 'postDuties',
                render: (text) => text ? text : '--'
            },{
                title: () => {
                    return <span>
                        初始化角色
                        <Tooltip placement="top" title={'用于员工入职时初始化账号'}>
                            <Icon className="exclamation-circle-icon" type="exclamation-circle" />
                        </Tooltip>
                    </span>
                },
                dataIndex: 'roles',
                width:'30%',
                render: (text) => text ? <span>{text.map(res => res.rolesName).join('，')}</span> : '--'
            },{
                title: '操作',
                width:'10%',
                
                render: (record) => {
                    return (
                        record.isOperable==false?null:
                        <span>
                           <span onClick={() => this.props.handleEditSubmit(record)} style={{ color: "#4D91FA", cursor: 'pointer' }}>编辑</span>
                           <span onClick={this.handleDelete.bind(this,record.postId)} style={{ marginLeft: "16px", color: "#4D91FA", cursor: 'pointer' }}>删除</span>
                        </span>
                    )
                }
            },
        ]
    }
    /**
     * 函数描述: 删除按钮事件
     * @param {object} postId 岗位id
     * @return {Object} 二次确认后统一返回父组件处理请求
     */
    handleDelete = (record) => {
         confirm({
             title: '删除',
             content: '您确定要删除该数据吗？',
             icon:<Icon type="delete" style={{color:'#E5537B'}} />,
             onOk: () => {
               this.props.handleDelete(record);
             }
         });
     }
    render() {
        return (
            <Table
                rowKey={(record) => { return record.postId }}
                loading={this.props.loading}
                columns={this.columns} 
                dataSource={this.props.data}
                locale={{
                    emptyText: (
                      <div>
                        <img
                          style={{ width: 182, height: 94 }}
                          src={require("../../../../../assets/image/no-data.png")}
                          alt=""
                        />
                        <p
                          style={{
                            fontSize: 14,
                            color: "#717E99",
                            lineHeight: "20px"
                          }}
                        >
                          暂无数据
                        </p>
                      </div>
                    )
                  }}
                pagination={{
                    hideOnSinglePage: true,
                    current: this.props.pageNo,
                    total: this.props.total,
                    pageSize: this.props.pageSize || 10,
                    onChange: (pageNo, pageSize) => this.props.handleChangePage(pageNo, pageSize)
                }}
            />
        )
    }
}
export default tableList