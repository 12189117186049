import React, { Component } from "react";

import "../assets/css/reset.less";
import noDataUrl from "../assets/image/no-data.png";
/*
数据格式如下： 
height: 无数据块的高度
width:  图片的宽度
*/

class NoData extends Component {
  render() {
    return (
      <div
        className="nodata-wrapper"
        style={{ height: this.props.height ? this.props.height : "400px" }}
      >
        <img src={noDataUrl} alt="" style={{ width: 182, height: 94 }} />
      </div>
    );
  }
}
export default NoData;
