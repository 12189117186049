import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
// import { Breadcrumb, Tabs } from 'antd';
import './index.less'
import WagePayTable from './component/wagePayTable' //工资发放table

class MainManagement extends Component {
	constructor(props) {
		super(props)
		this.state = {

		}
	}
	componentDidMount() {

	}
	
	render() {
		return (
			<div className='mainManagement-box' >
				<div className="crumbs">工资发放设置</div>
				<div className='mainManagement-header-list'>	
					<WagePayTable/>
				</div>
			</div>
		)
	}
}


export default withRouter(MainManagement)