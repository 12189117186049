/**
* @fileoverview 系统管理---账号设置 -- 新增弹框
* @author  蒋西园
*/
import React,{Component} from 'react';
import './index.less'
import { Modal, Form, Input, Select, Radio } from "antd";
import SelectStaff from '../../../../../components/selectStaff'
const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;
const RadioGroup = Radio.Group;

class AddOrganizational extends Component{
    constructor(props){
		super(props)
		this.state = {
            isShowFasterDepartment: false,
            addParentDepartmentList:null,
            checkedValue: {}
		}
	}
    static getDerivedStateFromProps (nextProps, prevState) {
        const { organizationalItem } = nextProps;
        if (organizationalItem != prevState.organizationalItem) {
            return {
                organizationalItem: nextProps.organizationalItem
            };
        }
        return null;
    }
    componentDidMount(){
        this.departmentListByParent()
        this.setState({
            isShowFasterDepartment:  this.props.organizationalItem.level && this.props.organizationalItem.level != 1,
        })
    }
    componentWillReceiveProps (nextProps){
        console.log(nextProps)
    }
    componentDidUpdate(prevProps) {
        if (prevProps.organizationalItem.departmentId !== this.props.organizationalItem.departmentId) {
                this.setState({
                    isShowFasterDepartment:  this.props.organizationalItem.level && this.props.organizationalItem.level != 1,
                })
        }
    }
    // 点击确定
    handleAddSure = (e) => {
        e.preventDefault();
        const { form, type } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log(values)
                values.departmentPrincipalName = this.state.checkedValue.staffName || this.state.organizationalItem.departmentPrincipalName;
                this.props.handleAddSure(type,values)
            }
        })
    }
    // 点击取消关闭弹框
    handleCancel = () => {
        this.props.handleCancel();
    }
    handleChange = (key) => {
        this.setState({
            isShowFasterDepartment: key != 1
        })
        this.props.form.setFieldsValue({parentDepartmentId:  undefined});
    }
    onDropdownVisibleChange = (open) => {
        if(open) {
            const { getFieldValue } = this.props.form;
            this.props.listForDropDown(getFieldValue('level')-1)
        }
    }
    handleOk = (value) => {
        this.state.organizationalItem.departmentPrincipalName=value[0].staffName
        this.state.organizationalItem.departmentPrincipalId=value[0].staffId
        this.setState({
            checkedValue: value[0],
            organizationalItem:this.state.organizationalItem
        })

        this.props.form.setFieldsValue({departmentPrincipalId:  value[0].staffId});
    }

    departmentListByParent = () => {
        const { getFieldValue } = this.props.form;
        let _this = this
		this.$http.departmentListByParent({
			level:getFieldValue('level')-1
		}).then(res => {
            let r = res.data
            if(this.props.organizationalItem && this.props.organizationalItem.departmentId){
                r = res.data.filter(function (x) {
                    return x.departmentId != _this.props.organizationalItem.departmentId;
                });
            }
			this.setState({
				addParentDepartmentList: r,
			})
			
		})
	}
    render(){
        const { addModalShow, form, type, organizationalItem, addParentDepartmentList } = this.props;
        const { getFieldDecorator } = form;
        const { isShowFasterDepartment } = this.state;
        return(
            <Modal
                className="organizationalModal"
                title={type === 0 ? '添加组织' : '修改组织'}
                visible={addModalShow}
                width={500}
                onCancel={this.handleCancel}
                onOk={this.handleAddSure}
                maskClosable={false}
            >
                <div className="setUser-box">
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
                        <FormItem className="p-2"
                            label="部门">
                            {getFieldDecorator('departmentName', {
                                initialValue: organizationalItem.departmentName,
                                rules: [
                                    { required: true, message: '请输入部门' },
                                    { max: 16, message: '部门长度为32个字符'}
                                ],
                            })(
                                <Input placeholder="请输入" allowClear />
                            )}
                        </FormItem>
                        <FormItem className="p-2"
                            label="层级">
                            {getFieldDecorator('level', {
                                initialValue: organizationalItem.level,
                                rules: [
                                    { required: true, message: '请选择层级' },
                                ],
                            })(
                                <Select placeholder="请选择" allowClear　onChange={this.handleChange} disabled={organizationalItem.disabled}>
                                    <Option value={organizationalItem.disabled?organizationalItem.levle:'1'}>{`${organizationalItem.disabled?organizationalItem.levle:'1'}级`}</Option>
                                    {/* <Option value="2">2级</Option>
                                    <Option value="3">3级</Option>
                                    <Option value="4">4级</Option>
                                    <Option value="5">5级</Option> */}
                                </Select>
                            )}
                        </FormItem>
                        {isShowFasterDepartment && <FormItem className="p-2"
                            label="上级部门">
                            {getFieldDecorator('parentDepartmentId', {
                                initialValue: organizationalItem.parentDepartmentId==0?undefined:organizationalItem.parentDepartmentId,
                            })(
                                <Select 
                                    placeholder="请选择"
                                    allowClear
                                    onDropdownVisibleChange={this.departmentListByParent}
                                    disabled={organizationalItem.disabled}
                                >
                                    {
                                        this.state.addParentDepartmentList==null?'':this.state.addParentDepartmentList.map(item => {
                                            return <Option key={item.departmentId} value={item.departmentId}>{item.departmentName}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </FormItem>}
                        <FormItem className="p-2"
                            label="类型">
                            {getFieldDecorator('type', {
                                initialValue: organizationalItem.type || '1'                    
                            })(
                                <RadioGroup>
                                    <Radio value="1">项目</Radio>
                                    <Radio value="2">部门</Radio>
                                </RadioGroup>
                            )}
                        </FormItem>
                        <FormItem className="p-2"
                            label="部门负责人">
                            {getFieldDecorator('departmentPrincipalId', {
                                initialValue: organizationalItem.departmentPrincipalId || '',
                            })(
                                <SelectStaff
                                    form={this.props.form} 
                                    handleOk={this.handleOk}
                                    organizationalItem={organizationalItem}
                                />
                            )}
                        </FormItem>
                        <FormItem className="p-2"
                            label="备注">
                            {getFieldDecorator('remark', {
                                initialValue: organizationalItem.remark,
                                rules: [
                                    { max: 128, message: '说明长度为128个字符'}
                                ],
                            })(
                                <TextArea placeholder="请输入" allowClear autoSize={{ minRows: 3, maxRows: 5 }}/>
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        )
    }
}
export default Form.create()(AddOrganizational)