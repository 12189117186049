import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Timeline,
  Icon,
  Form,
  Select,
  Row,
  Col,
  Button,
  Input,
  message,
  Radio,
  DatePicker,
  TimePicker,
  Modal,
  Alert,
  Tabs,
  Table,
  Calendar,
} from "antd";
import "./index.less";
import ProcessGuide from "../../../components/processGuide";
import AuditRecord from "../../../components/auditRecord";
import Withdraw from "../../../components/withdraw"; // 撤回
import Quit from "../../../components/quitList2";
import Overtime from "../../../components/overtime";
import { getQueryVariable } from "../../../utils/common";
import utils from "../../../utils/utils";
import moment from "moment";
import $ from "jquery";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { confirm } = Modal;
const { TabPane } = Tabs;

// console.log(
//   moment("2020-03-04 20:00").diff(moment("2020-03-04 18:00"), "hours")
// );
// console.log(moment("2020-02", "YYYY-MM-DD"));
// console.log(moment("2020-02", "YYYY-MM-DD").month());

let weeks = {
  1: "周一",
  2: "周二",
  3: "周三",
  4: "周四",
  5: "周五",
  6: "周六",
  7: "周七",
};

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}
let count = 0;

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: utils.localDb.get("userData").staffId,
      staffName: utils.localDb.get("userData").staffName,

      quitData: {
        affairRecess: "事假（天）",
        diseaseRecess: "病假（天）",
        festivalRecess: "年假（天）",
        marriedRecess: "婚假（天）",
        antenatalRecess: "产假（天）",
        bereftRecess: "丧假（天）",
        accompanyRecess: "陪产假（天）",
        prenatalCheckUpRecess: "产检假（天）",
      },
      leaveData: {},
      total: 0,
      attendanceDate: moment()
        .month(moment().month() - 1)
        .format("YYYY-MM"),
      time: moment(),
      leaveDayWithIdentificationVOS: [],

      tableData: [
        // {
        //   id: 0,
        //   workOverDate: "",
        //   workOverStartTime: "",
        //   workOverEndTime: "",
        //   workOverDuration: ""
        // }
      ],

      showType: 1,

      staffDetail: {},
      attendanceDetail: {},
      disabledHoursList: [],
      logList: [],
      submitDisabled: false,
    };
  }
  componentDidMount() {
    if (getQueryVariable("attendanceProcId")) {
      this.queryAttendanceConfirById();
      this.queryProcessLogList();
    } else {
      this.queryStaffDetail();
      this.setState({
        logList: [],
      });
    }
  }

  onSelect = (value) => {
    this.setState(
      {
        time: value,
      },
      () => {
        // this.fetchScheduleByDay();
      }
    );
  };
  onPanelChange = (value) => {
    this.setState({ time: moment() }, () => {
      // this.getLeaveByMonth(value.format("YYYY-MM"));
    });
  };

  dateCellRender = (value) => {
    // console.log(value.format("YYYY-MM-DD"));
    // return <span>{value.date() > 9 ? value.date() : "0" + value.date()}</span>;

    if (value.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      $(".ant-fullcalendar-today")
        .children()
        .find(".ant-fullcalendar-value")
        .css("background", "#1890ff");
    }

    return this.state.leaveDayWithIdentificationVOS.map((item) => {
      if (
        moment(item.date).format("YYYY-MM-DD") === value.format("YYYY-MM-DD")
      ) {
        if (
          moment(item.leaveStartTime).format("YYYY-MM-DD") ===
          moment().format("YYYY-MM-DD")
        ) {
          $(".ant-fullcalendar-today")
            .children()
            .find(".ant-fullcalendar-value")
            .css("background", "#fff");
        }
        return (
          <span
            key={item.date}
            className={`leave-day leave-day-${item.identifier}`}
          >
            {value.date() > 9 ? value.date() : "0" + value.date()}
          </span>
        );
      }
    });
  };

  queryAttendanceConfirById = () => {
    this.$http["queryAttendanceConfirById"]({
      attendanceProcId: getQueryVariable("attendanceProcId"),
    }).then((res) => {
      if (res.code === 200) {
        let showType = 2;
        let showWithdraw = false;
        let showComplete = false;

        let step2 = false;
        let step3 = false;

        let taskDefKey = res.data.processData
          ? res.data.processData.taskDefKey
          : "";
        // 保存状态
        if (res.data.procStatus === "STORAGE") {
          showType = 1;
        }
        if (
          res.data.procStatus == "REFUSE" ||
          res.data.procStatus == "RECALL"
        ) {
          if (res.data.creatorId == this.state.staffId) {
            showType = 1;
          }
        }
        if (res.data.procStatus == "AUDIT") {
          if (res.data.creatorId == this.state.staffId) {
            // 撤回操作
            showWithdraw = true;
          }
          // 审核状态
          if (res.data.auditId == this.state.staffId) {
            if (taskDefKey === "ATT0002") {
              // 第二步
              step2 = true;
              if (res.data.accumulatedOvertime) {
                // 回显填写的加班数据
                this.queryTableData();
              }
            }
            if (taskDefKey === "ATT0003") {
              // 第三步
              step3 = true;
              showComplete = true;
            }
          }
        }
        if (res.data.procStatus == "COMPLETE") {
          // 归档
          showComplete = false;
        }

        // 抄送的详情没有操作权限
        if (getQueryVariable("copyToFlag") == 1) {
          step2 = false;
          step3 = false;
        }

        this.setState(
          {
            staffDetail: res.data || {},
            actualAttendance: res.data.actualAttendance || 0,
            attendanceDetail: res.data || {},
            attendanceDate: res.data.attendanceMonth || "",
            showType,
            showWithdraw,
            showComplete,
            step2,
            step3,
          },
          () => {
            this.getLeaveByMonth();
          }
        );
      }
    });
  };

  // 获取加班列表
  queryTableData = () => {
    this.$http["queryWorkOverTimeList"]({
      attendanceProcId: getQueryVariable("attendanceProcId"),
      pageNo: 1,
      pageSize: 999,
    }).then((res) => {
      if (res.code === 200) {
        let tableData = [];
        let disabledHoursList = [];
        if (res.data.list && res.data.list.length > 0) {
          tableData = res.data.list.map((item, index) => {
            return {
              id: index,
              workOverDate: item.workOverDate,
              workOverStartTime: item.workOverStartTime + ":00",
              workOverEndTime: item.workOverEndTime + ":00",
              workOverDuration: item.workOverDuration,
            };
          });
          disabledHoursList = res.data.list.map((item) => {
            const start = parseInt(
              moment(`${item.workOverDate} ${item.workOverStartTime}`).format(
                "HH"
              )
            );
            const end = parseInt(
              moment(`${item.workOverDate} ${item.workOverEndTime}`).format(
                "HH"
              )
            );
            return [range(end, 24), range(0, start + 1)];
          });
          // console.log(disabledHoursList);
        }
        this.setState({
          tableData,
          disabledHoursList,
        });
        count = res.data.list ? res.data.list.length : 0;
      }
    });
  };

  //流程日志
  queryProcessLogList = () => {
    this.$http["queryProcessLogList"]({
      businessKey: getQueryVariable("attendanceProcId"),
      procTemplate: getQueryVariable("procTemplate"),
    })
      .then((res) => {
        if (res.code == 200) {
          this.setState({
            logList: res.data || [],
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  queryStaffDetail = () => {
    this.$http["getStaffPostInfoForStaffRoster"]({
      staffId: this.state.staffId,
    }).then((res) => {
      if (res.code === 200) {
        this.setState(
          {
            staffDetail: res.data || {},
          },
          () => {
            this.getLeaveByMonth();
          }
        );
      }
    });
  };

  getLeaveByMonth = () => {
    const { attendanceDetail } = this.state;
    this.$http["getLeaveByMonth"]({
      attendanceDate: this.state.attendanceDate,
      staffId: this.state.staffDetail.staffId,
    }).then((res) => {
      if (res.code === 200) {
        // let total = 0;
        // if (res.data) {
        //   Object.keys(this.state.quitData).map((item) => {
        //     total += res.data[item] ? res.data[item] - 0 : 0;
        //   });
        // }
        this.setState({
          leaveListVOS: res.data.leaveListVOS || [],
          leaveDayWithIdentificationVOS:
            res.data.leaveDayWithIdentificationVOS || [],
          leaveData: res.data || {},
          total: res.data.totalRecess || 0,
          actualAttendance: attendanceDetail.actualAttendance,
          // actualAttendance: attendanceDetail.shouldAttendance
          //   ? attendanceDetail.shouldAttendance - total > 0
          //     ? attendanceDetail.shouldAttendance - total
          //     : 0
          //   : 0
        });
      }
    });
  };

  handleDelete = () => {
    const self = this;
    confirm({
      title: "删除",
      content: "您确定要删除该工作流吗？",
      onOk() {
        self.$http["deleteByAttendanceConfirId"]({
          attendanceProcId: getQueryVariable("attendanceProcId"),
        })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              self.props.history.go(-1);
            } else {
            }
          })
          .catch((err) => {});
      },
      onCancel() {},
    });
  };

  handleSubmit = (type) => {
    const { staffDetail, total } = this.state;
    const params = {
      staffName: staffDetail.staffName,
      staffId: staffDetail.staffId,
      staffCode: staffDetail.staffCode,
      departmentId: staffDetail.departmentId,
      departmentName: staffDetail.departmentName,
      postId: staffDetail.postId,
      postName: staffDetail.postName,
      procStatus: type,
      leaveDays: total,
    };
    if (getQueryVariable("attendanceProcId")) {
      params.attendanceProcId = getQueryVariable("attendanceProcId");
    }
    // console.log(params);
    this.setState({
      submitDisabled: true,
    });
    this.$http[
      getQueryVariable("attendanceProcId")
        ? "updateByAttendanceConfirId"
        : "addAttendanceConfirProcess"
    ](params)
      .then((res) => {
        if (res.code === 200) {
          this.props.history.push("/workbench/launch");
          this.setState({
            submitDisabled: false,
          });
        } else {
          this.setState({
            submitDisabled: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          submitDisabled: false,
        });
      });
  };

  handlePass = (type) => {
    const self = this;
    const { attendanceDetail, tableData, total } = this.state;
    const { form } = this.props;
    // console.log(tableData);
    let accumulatedOvertime = 0;
    let overtimeMsg;
    tableData.map((item) => {
      overtimeMsg = false;
      if (item.workOverDuration == 0) {
        overtimeMsg = true;
      }
      accumulatedOvertime += item.workOverDuration - 0;
    });
    if (type === "pass") {
      form.validateFieldsAndScroll((err, values) => {
        if (overtimeMsg) {
          message.destroy();
          message.warning("请填写加班时间");
          return;
        }
        if (!err) {
          let params = {
            attendanceProcId: getQueryVariable("attendanceProcId"),
            taskId: attendanceDetail.processData.taskId,
            ...values,
            // actualAttendance: values["shouldAttendance"]
            //   ? values["shouldAttendance"] - total
            //   : 0,
            actualAttendance: attendanceDetail.actualAttendance,
            workOvertimeList: tableData,
            accumulatedOvertime,
          };
          delete params.workOverDate;
          delete params.workOverStartTime;
          delete params.workOverEndTime;
          // console.log(params);
          this.setState({
            submitDisabled: true,
          });
          this.$http["attendanceConfirPass"](params)
            .then((res) => {
              if (res.code === 200) {
                // window.close();
                this.props.history.go(-1);
                this.setState({
                  submitDisabled: false,
                });
              } else {
                this.setState({
                  submitDisabled: false,
                });
              }
            })
            .catch((err) => {
              this.setState({
                submitDisabled: false,
              });
            });
        }
      });
    } else if (type === "refuse") {
      if (!form.getFieldValue("auditOpinion").trim()) {
        message.destroy();
        message.warning("请在审核意见中填写退回原因");
        document.getElementById("auditOpinion").focus();
        return;
      }
      form.validateFieldsAndScroll(["auditOpinion"], (err, values) => {
        if (!err) {
          confirm({
            icon: <Icon type="question-circle" style={{ color: "#4D91FA" }} />,
            title: "您确定退回该工作流吗？",
            content: "",
            onOk() {
              let params = {
                attendanceProcId: getQueryVariable("attendanceProcId"),
                taskId: attendanceDetail.processData.taskId,
                ...values,
              };
              self.$http["attendanceConfirRefuse"](params)
                .then((res) => {
                  if (res.code === 200) {
                    // window.close();
                    self.props.history.go(-1);
                  } else {
                  }
                })
                .catch((err) => {});
            },
            onCancel() {},
          });
        }
      });
    } else if (type === "file") {
      let params = {
        attendanceProcId: getQueryVariable("attendanceProcId"),
        taskId: attendanceDetail.processData.taskId,
      };
      this.setState({
        submitDisabled: true,
      });
      this.$http["attendanceConfirPass"](params)
        .then((res) => {
          if (res.code === 200) {
            // window.close();
            this.props.history.go(-1);
            this.setState({
              submitDisabled: false,
            });
          } else {
            this.setState({
              submitDisabled: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            submitDisabled: false,
          });
        });
    }
  };

  handleRemove = (id, index) => {
    const { tableData, disabledHoursList } = this.state;
    let tableIndex = tableData.findIndex((item) => item.id == id);
    tableData.splice(tableIndex, 1);
    disabledHoursList.splice(index, 1);
    this.setState({
      tableData,
      disabledHoursList,
    });
  };

  dateChange = (date, dateString, index) => {
    const { tableData } = this.state;
    tableData[index].workOverDate = dateString;

    if (
      tableData[index].workOverStartTime &&
      tableData[index].workOverEndTime
    ) {
      tableData[index].workOverDuration = moment(
        `${tableData[index].workOverDate} ${tableData[index].workOverEndTime}`
      ).diff(
        moment(
          `${tableData[index].workOverDate} ${tableData[index].workOverStartTime}`
        ),
        "hours"
      );
    }

    this.setState(
      {
        tableData,
      },
      () => {
        // this.checkAdd();
      }
    );
  };

  startTimeChange = (time, timeString, index, id) => {
    console.log(moment(time).format("HH"), timeString, index, id);
    const { form } = this.props;
    const start = parseInt(moment(time).format("HH"));
    const { tableData, disabledHoursList } = this.state;
    tableData[index].workOverStartTime = timeString + ":00";
    disabledHoursList[index] = [[], range(0, start + 1), start + 1];

    form.setFieldsValue({
      [`workOverEndTime[${id}]`]: undefined,
    });
    tableData[index].workOverDuration = "";

    this.setState(
      {
        tableData,
        disabledHoursList,
      },
      () => {
        // this.checkAdd();
      }
    );
  };

  endTimeChange = (time, timeString, index, id) => {
    console.log(time, timeString, index, id);
    const end = parseInt(moment(time).format("HH"));
    const { tableData, disabledHoursList } = this.state;
    tableData[index].workOverEndTime = timeString + ":00";
    if (
      timeString &&
      tableData[index].workOverDate &&
      tableData[index].workOverStartTime
    ) {
      tableData[index].workOverDuration = moment(
        `${tableData[index].workOverDate} ${tableData[index].workOverEndTime}`
      ).diff(
        moment(
          `${tableData[index].workOverDate} ${tableData[index].workOverStartTime}`
        ),
        "hours"
      );
    } else {
      tableData[index].workOverDuration = "";
    }

    disabledHoursList[index] = [
      range(end, 24),
      disabledHoursList[index] ? range(0, disabledHoursList[index][2]) : [],
    ];
    this.setState(
      {
        tableData,
      },
      () => {
        // this.checkAdd();
      }
    );
  };

  handleWithdraw = () => {
    this.props.history.go(-1);
  };

  disabledStartTime = (index) => {
    const { disabledHoursList } = this.state;
    // console.log(disabledHoursList);
    if (disabledHoursList[index]) {
      return disabledHoursList[index][0] || [];
    } else {
      return [];
    }
  };

  disabledEndTime = (index) => {
    const { disabledHoursList } = this.state;
    if (disabledHoursList[index]) {
      return disabledHoursList[index][1] || [];
    } else {
      return [];
    }
  };

  disabledDate = (current) => {
    return (
      (current &&
        current <
          moment()
            .month(moment().month() - 1)
            .startOf("month")) ||
      (current &&
        current >
          moment()
            .month(moment().month() - 1)
            .endOf("month"))
    );
  };

  checkAdd = () => {
    const { tableData } = this.state;
    // let canAdd;
    // for (let i = 0; i < tableData.length; i++) {
    //   canAdd = false;
    //   if (
    //     tableData[i].workOverDate &&
    //     tableData[i].workOverStartTime &&
    //     tableData[i].workOverEndTime
    //   ) {
    //     canAdd = true;
    //   }
    // }
    // if (canAdd) {
    //   tableData.push({
    //     id: ++count,
    //     workOverDate: "",
    //     workOverStartTime: "",
    //     workOverEndTime: "",
    //     workOverDuration: ""
    //   });
    // }
    tableData.push({
      id: count++,
      workOverDate: "",
      workOverStartTime: "",
      workOverEndTime: "",
      workOverDuration: "",
    });
    this.setState({
      tableData,
    });
  };

  validateDays = (rule, value, callback) => {
    const { form } = this.props;
    const reg = /^[1-9]\d*$/;
    if (value && value.trim() && !reg.test(value.trim())) {
      callback("请输入正整数");
    } else if (value && value.trim() && parseInt(value.trim()) > 31) {
      callback("正整数不大于31");
    } else {
      callback();
      // this.setState({
      //   actualAttendance:
      //     value.trim() - this.state.total > 0
      //       ? value.trim() - this.state.total
      //       : 0
      // });
    }
  };

  render() {
    // console.log(this.state.disabledHoursList);
    const {
      showType,
      tableData,
      staffDetail,
      attendanceDetail,
      leaveData,
      quitData,
      disabledHoursList,
      logList,
    } = this.state;

    const columns = [
      {
        title: "日期",
        dataIndex: "workOverDate",
        key: "workOverDate",
        render: (text, record, index) => (
          <FormItem label="" style={{ margin: 0 }}>
            {getFieldDecorator(`workOverDate[${record.id}]`, {
              initialValue: record.workOverDate
                ? moment(record.workOverDate, "YYYY-MM-DD")
                : undefined,
              rules: [
                {
                  required: true,
                  message: "日期必填",
                },
              ],
            })(
              <DatePicker
                disabledDate={this.disabledDate}
                placeholder="请选择日期"
                onChange={(date, dateString) => {
                  this.dateChange(date, dateString, index);
                }}
                defaultPickerValue={moment()
                  .month(moment().month() - 1)
                  .startOf("month")}
                getCalendarContainer={(triggerNode) => triggerNode.parentNode}
              />
            )}
          </FormItem>
        ),
      },
      {
        title: "加班时间",
        dataIndex: "workOverStartTime",
        key: "workOverStartTime",
        render: (text, record, index) => {
          return (
            <div style={{ display: "flex" }}>
              <FormItem
                label=""
                wrapperCol={{ span: 24 }}
                style={{ margin: 0 }}
              >
                {getFieldDecorator(`workOverStartTime[${record.id}]`, {
                  initialValue: record.workOverStartTime
                    ? moment(record.workOverStartTime, "HH:mm")
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "开始时间必填",
                    },
                  ],
                })(
                  <TimePicker
                    // key={index}
                    placeholder="开始时间"
                    format="HH:mm"
                    defaultOpenValue={moment("00:00", "HH:mm")}
                    disabledHours={() => this.disabledStartTime(index)}
                    disabledMinutes={() => [0]}
                    minuteStep={60}
                    onChange={(time, timeString) => {
                      this.startTimeChange(time, timeString, index, record.id);
                    }}
                  />
                )}
              </FormItem>
              <FormItem
                label=""
                wrapperCol={{ span: 24 }}
                style={{ margin: 0 }}
              >
                {getFieldDecorator(`workOverEndTime[${record.id}]`, {
                  initialValue: record.workOverEndTime
                    ? moment(record.workOverEndTime, "HH:mm")
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "结束时间必填",
                    },
                  ],
                })(
                  <TimePicker
                    placeholder="结束时间"
                    format="HH:mm"
                    defaultOpenValue={moment("00:00", "HH:mm")}
                    disabledHours={() => this.disabledEndTime(index)}
                    disabledMinutes={() => [0]}
                    minuteStep={60}
                    onChange={(time, timeString) => {
                      this.endTimeChange(time, timeString, index, record.id);
                    }}
                  />
                )}
              </FormItem>
            </div>
          );
        },
      },
      {
        title: "时长（小时）",
        dataIndex: "workOverDuration",
        key: "workOverDuration",
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "操作",
        dataIndex: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <a
              onClick={() => {
                this.handleRemove(record.id, index);
              }}
            >
              删除
            </a>
          );
        },
      },
    ];

    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    };

    // 显示审核结果，判断右侧记录第一个的状态operStatus，有的话就显示第一个，没有的话显示第二个
    let log = {};
    if (logList.length > 1) {
      if (logList[0].operStatus) {
        log = logList[0];
      } else {
        log = logList[1];
      }
    }
    console.log(this.state.step3);

    return (
      <div className="monthlyAttendance-main">
        <div className="monthlyAttendance-title">
          <span>
            月度考勤确认
            {showType == 1 && <span>-员工确认</span>}
            {attendanceDetail.procStatus == "AUDIT" ? (
              attendanceDetail.processData ? (
                <span>{`-${attendanceDetail.processData.taskName}`}</span>
              ) : null
            ) : null}
          </span>
          <ProcessGuide title="月度考勤确认" temp="ATTE_PROC" />
        </div>
        <div className="monthlyAttendance-wrap">
          <div className="wrap-left" style={{ flex: 1 }}>
            {log && log.operDescription ? (
              <div>
                {log.operStatus == "PASS" && (
                  <Alert
                    message={`${log.taskName}通过`}
                    description={log.operDescription}
                    type="success"
                    showIcon
                    style={{ marginBottom: 12 }}
                  />
                )}
                {log.operStatus == "REFUSE" && (
                  <Alert
                    message={
                      <span className="alert-process-refuse">{`${log.taskName}不通过`}</span>
                    }
                    description={
                      <span className="alert-process-desc">
                        {log.operDescription}
                      </span>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="close-circle"
                        style={{
                          fontSize: 18,
                          color: "#E5537B",
                          top: "18px",
                        }}
                      />
                    }
                    style={{ marginBottom: 12 }}
                  />
                )}
              </div>
            ) : null}

            <Form {...formItemLayout} className="process-form-readonly">
              <Row className="wrap-block">
                <Alert
                  message={
                    <span className="alert-attent-message">
                      请务必确保上月的请假等缺勤记录已经提交流程
                    </span>
                  }
                  type="warning"
                  showIcon
                  icon={
                    <Icon
                      type="info-circle"
                      style={{
                        fontSize: 18,
                        color: "#F98919",
                        top: "10px",
                      }}
                    />
                  }
                  style={{ marginBottom: 14 }}
                />
                <Row>
                  <Col span={8}>
                    <FormItem label="员工">
                      <span>{staffDetail.staffName || "--"}</span>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="工号">
                      <span>{staffDetail.staffCode || "--"}</span>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="部门名称">
                      <span>{staffDetail.departmentName || "--"}</span>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <FormItem label="岗位">
                      <span>{staffDetail.postName || "--"}</span>
                    </FormItem>
                  </Col>
                  {attendanceDetail.showOvertime == 1 ? (
                    this.state.step2 ? (
                      <Col span={8}>
                        <FormItem label="应出勤">
                          {getFieldDecorator("shouldAttendance", {
                            initialValue:
                              attendanceDetail.shouldAttendance || "",
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "应出勤必填",
                              },
                              {
                                validator: this.validateDays,
                              },
                            ],
                          })(
                            <Input
                              placeholder="请输入"
                              allowClear
                              style={{ width: 100 }}
                            />
                          )}
                          <span>天</span>
                        </FormItem>
                      </Col>
                    ) : (
                      <Col span={8}>
                        <FormItem label="应出勤">
                          <span>
                            {attendanceDetail.shouldAttendance || "--"}天
                          </span>
                        </FormItem>
                      </Col>
                    )
                  ) : null}
                  {attendanceDetail.showOvertime == 1 && (
                    <Col span={8}>
                      <FormItem label="实际出勤">
                        <span>{this.state.actualAttendance || "0"}天</span>
                      </FormItem>
                    </Col>
                  )}
                </Row>
                <Row>
                  {showType == 2 ? (
                    attendanceDetail.showOvertime == 1 ? (
                      <Col span={8}>
                        <FormItem label="累计加班">
                          <span>
                            {attendanceDetail.accumulatedOvertime || "--"}h
                          </span>
                          <Overtime {...attendanceDetail} />
                        </FormItem>
                      </Col>
                    ) : null
                  ) : null}
                </Row>
                {showType == 1 && (
                  <Row style={{ marginBottom: 12 }}>
                    <Col span={8}>
                      <FormItem label="" wrapperCol={{ offset: 9 }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            this.props.history.push("/workbench/leave");
                          }}
                        >
                          新建请假
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                )}
                <Row>
                  <FormItem
                    label=""
                    wrapperCol={{ offset: 2 }}
                    style={{ marginTop: 10 }}
                  >
                    <Col span={10} style={{ marginLeft: 10 }}>
                      <div>
                        <div className="quit-table-wrap">
                          <span>累计请假{this.state.total}天</span>
                          <Quit
                            date={this.state.attendanceDate}
                            staffId={staffDetail.staffId}
                          />
                        </div>
                        {Object.keys(quitData).map((item) => {
                          return (
                            <div className="quit-table-line">
                              <span className="table-text-left">
                                {quitData[item]}
                              </span>
                              <span className="table-value-right">
                                {leaveData[item]}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </Col>
                    <Col span={10} style={{ marginLeft: 24 }}>
                      <div className="calendar-main-content">
                        <Calendar
                          key={new Date().getTime()}
                          fullscreen={false}
                          value={
                            attendanceDetail.attendanceProcId
                              ? moment().month(
                                  moment(
                                    attendanceDetail.attendanceMonth,
                                    "YYYY-MM-DD"
                                  ).month()
                                )
                              : moment().month(moment().month() - 1)
                          }
                          validRange={[
                            moment()
                              .month(moment().month() - 1)
                              .startOf("month"),
                            moment()
                              .month(moment().month() - 1)
                              .endOf("month"),
                          ]}
                          headerRender={({
                            value,
                            type,
                            onChange,
                            onTypeChange,
                          }) => {
                            return (
                              <div className="calender-head">
                                <div></div>
                                <div>
                                  <span
                                    className="calender-title"
                                    style={{ margin: 0 }}
                                  >{`${value.year()}年${value.month() +
                                    1}月`}</span>
                                </div>
                              </div>
                            );
                          }}
                          dateCellRender={this.dateCellRender}
                          onSelect={this.onSelect}
                          onPanelChange={this.onPanelChange}
                        />
                        <div className="calendar-footer">
                          <span className="footer-span footer-span-0">
                            节假日
                          </span>
                          <span className="footer-span footer-span-4">
                            工作日
                          </span>
                          <span className="footer-span footer-span-3">
                            考勤确认
                          </span>
                          <span className="footer-span footer-span-2">
                            请假
                          </span>
                        </div>
                      </div>
                    </Col>
                  </FormItem>
                </Row>
              </Row>
              {this.state.step2 && (
                <Row className="wrap-block">
                  <div className="block-title" style={{ marginBottom: 12 }}>
                    提交加班数据
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <Button type="primary" onClick={this.checkAdd}>
                      新增
                    </Button>
                  </div>
                  <Row>
                    <Table
                      rowKey="id"
                      columns={columns}
                      dataSource={tableData}
                      pagination={false}
                    />
                  </Row>
                </Row>
              )}
              {this.state.step2 && (
                <Row className="wrap-block" style={{ marginBottom: 0 }}>
                  <div className="block-title">流程审核</div>
                  <Row>
                    <Col span={24}>
                      <FormItem
                        label="审核意见"
                        labelCol={{ span: 2 }}
                        style={{ marginLeft: 16 }}
                      >
                        {getFieldDecorator("auditOpinion", {
                          initialValue: "",
                          rules: [
                            {
                              required: false,
                              message: "请输入审核意见",
                            },
                          ],
                        })(
                          <TextArea
                            rows={4}
                            maxLength={128}
                            placeholder="请输入"
                            allowClear
                            style={{ width: 600 }}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Row>
              )}
            </Form>
          </div>
          <div className="wrap-right">
            <AuditRecord data={logList || []} />
          </div>
        </div>
        <div className="footer-fixed-wrap" style={{ marginTop: 0 }}>
          <div className="footer-fixed-btn">
            {showType == 1 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // if (getQueryVariable("attendanceProcId")) {
                    //   window.close();
                    // }
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => this.handleSubmit("INITIATE")}
                  disabled={this.state.submitDisabled}
                >
                  提交
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => this.handleSubmit("STORAGE")}
                  disabled={this.state.submitDisabled}
                >
                  保存
                </Button>
                {(attendanceDetail.procStatus == "STORAGE" ||
                  attendanceDetail.procStatus == "REFUSE") && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={this.handleDelete}
                  >
                    删除
                  </Button>
                )}
              </div>
            )}
            {showType == 2 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // window.close();
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                {/* {this.state.showWithdraw && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      this.setState(
                        {
                          visible: true
                        },
                        () => {
                          this.refs.withdrawRef.handleShow();
                        }
                      );
                    }}
                  >
                    撤回
                  </Button>
                )} */}
                {this.state.step2 && (
                  <span>
                    <Button
                      type="primary"
                      style={{ marginRight: 16 }}
                      onClick={() => this.handlePass("pass")}
                      disabled={this.state.submitDisabled}
                    >
                      通过
                    </Button>
                    <Button
                      type="danger"
                      style={{ marginRight: 16 }}
                      onClick={() => this.handlePass("refuse")}
                    >
                      不通过
                    </Button>
                  </span>
                )}
                {this.state.step3 && (
                  <Button
                    type="primary"
                    style={{ marginRight: 16 }}
                    onClick={() => this.handlePass("file")}
                    disabled={this.state.submitDisabled}
                  >
                    归档
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        {this.state.visible && (
          <Withdraw
            ref="withdrawRef"
            form={this.props.form}
            data={{
              attendanceProcId: attendanceDetail.attendanceProcId,
              taskId: attendanceDetail.processData.taskId,
            }}
            url="attendanceConfirWithdraw"
            handleOk={this.handleWithdraw}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Form.create()(MainManagement));
