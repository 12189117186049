/**
 * @fileoverview 系统管理---账号设置 -- 新增弹框
 * @author  蒋西园
 */
import React, { Component } from "react";
import "./index.less";
import { Modal, Form, Input, Select, Button } from "antd";
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class addAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }
  // 点击确定
  handleOk = () => {
    const { form, data, url } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.$http[url]({
          ...data,
          reason: values["reason"]
        })
          .then(res => {
            if (res.code === 200) {
              this.handleCancel();
              this.props.handleOk();
            } else {
            }
          })
          .catch(err => {});
      }
    });
  };
  // 点击取消关闭弹框
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };

  handleShow = () => {
    const { form } = this.props;
    this.setState(
      {
        visible: true
      },
      () => {
        form.resetFields();
      }
    );
  };

  validatorReason = (rule, value, callback) => {
    if (value.trim().length > 128) {
      callback("长度限制128");
    } else {
      callback();
    }
  };

  render() {
    const { visible } = this.state;
    const { form, accountItem } = this.props;
    const { getFieldDecorator } = form;
    return (
      <span>
        <Modal
          title="撤回流程"
          visible={visible}
          maskClosable={false}
          width={500}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              取消
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              确定
            </Button>
          ]}
        >
          <div className="setUser-box">
            <Form>
              <FormItem label="撤回原因">
                {getFieldDecorator("reason", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "撤回原因必填"
                    },
                    { validator: this.validatorReason }
                  ]
                })(
                  <TextArea
                    rows={4}
                    maxLength={200}
                    placeholder="请输入撤回原因"
                    allowClear
                  />
                )}
              </FormItem>
            </Form>
          </div>
        </Modal>
      </span>
    );
  }
}
export default addAccountModal;
