import React, { Component } from "react";
import { Table, Modal, Icon } from "antd";
import "./index.less";
const { confirm } = Modal;

class tableList extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "标题",
        dataIndex: "title",
      },
      {
        title: "状态",
        dataIndex: "notificationStatus",
        render: (text) => {
          return (
            <span
              className={
                text === 1
                  ? "status-btn released"
                  : "status-btn no-released"
              }
            >
              {text === 1 ? "已发布" : "待发布"}
            </span>
          );
        },
      },
      {
        title: "创建时间",
        dataIndex: "gmtCreated",
        sorter: true,
      },
      {
        title: "发布时间",
        dataIndex: "releaseTime",
        sorter: true,
        render: (text) => (text ? text : "--"),
      },
      {
        title: "操作",
        render: (record) => {
          return (
            <span>
              <span
                onClick={() =>
                  this.props.handleEditSubmit(
                    record.notificationId
                  )
                }
                style={{
                  color: "#4D91FA",
                  cursor: "pointer",
                }}
              >
                编辑
              </span>
              <span
                onClick={this.handleDelete.bind(
                  this,
                  record.notificationId
                )}
                style={{
                  marginLeft: "16px",
                  color: "#4D91FA",
                  cursor: "pointer",
                }}
              >
                删除
              </span>
              <span
                onClick={this.handleReleased.bind(
                  this,
                  record
                )}
                style={{
                  marginLeft: "16px",
                  color: "#4D91FA",
                  cursor: "pointer",
                }}
              >
                {record.notificationStatus === 1
                  ? "取消发布"
                  : "发布"}
              </span>
            </span>
          );
        },
      },
    ];
  }
  /**
   * 函数描述: 取消发布/发布
   */
  handleReleased = (record) => {
    confirm({
      title:
        record.notificationStatus === 1
          ? "您确定要取消发布该公告吗？"
          : "您确定要发布该公告吗？",
      onOk: () => {
        this.props.handleReleased(record.notificationId);
      },
    });
  };
  /**
   * 函数描述: 禁用按钮事件
   * @param {object} record 该账号所有信息 -- 后期要精简参数更换为唯一标识
   * @return {Object} 二次确认后统一返回父组件处理请求
   */
  handleProhibit = (record) => {
    confirm({
      title: "您确定禁用该账号吗？",
      icon: (
        <Icon
          type="question-circle"
          style={{ color: "#4D91FA" }}
        />
      ),
      onOk: () => {
        this.props.handleProhibit(record);
      },
    });
  };
  /**
   * 函数描述: 删除按钮事件
   * @param {object} notificationId 公告id
   * @return {Object} 二次确认后统一返回父组件处理请求
   */
  handleDelete = (notificationId) => {
    confirm({
      title: "删除",
      content: "您确定要删除该账号吗？",
      onOk: () => {
        this.props.handleDelete(notificationId);
      },
    });
  };
  render() {
    return (
      <Table
        className="systemBulletin-table"
        rowKey={(record) => {
          return record.notificationId;
        }}
        loading={this.props.loading}
        columns={this.columns}
        dataSource={this.props.data}
        locale={{
          emptyText: (
            <div>
              <img
                style={{ width: 182, height: 94 }}
                src={require("../../../../../assets/image/no-data.png")}
                alt=""
              />
              <p
                style={{
                  fontSize: 14,
                  color: "#717E99",
                  lineHeight: "20px",
                }}
              >
                暂无数据
              </p>
            </div>
          ),
        }}
        pagination={{
          hideOnSinglePage: true,
          current: this.props.pageNo,
          total: this.props.total,
          pageSize: this.props.pageSize || 10,
        }}
        onChange={(pageNo, {}, sorter) =>
          this.props.handleChangePage(pageNo, sorter)
        }
      />
    );
  }
}
export default tableList;
