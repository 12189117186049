/**
* @fileoverview 人事管理---合同管理 -- 合同详情
* @author  蒋西园
*/
import React,{ Component } from 'react'
// import './index.less'

// import { Button, message } from 'antd'；
import { contractItem } from '../../../data'
import util from '../../../../../utils/utils'


class ContractDetail extends Component{
	constructor(props){
		super(props)
		this.state = {
			contractDetail: {}
		}
	}
	componentDidMount(){
		this.getStaffContractByContractId();
	}
	/**
     * 函数描述: 查询合同详情
     * @param {string} contractId 合同id--通过路由获取
     * @return 
     */
	getStaffContractByContractId = () => {

		const contractId = util.getSearchString('contractId',window.location.search)|| this.props.location.state.contractId;
		this.$http.getStaffContractByContractId({contractId}).then((res) => {
			if(res.code == 200){
				this.setState({
					contractDetail: res.data
				})
			}
		})
	}
	render(){
		const { contractDetail } = this.state;
		return (
		<div className="contract-detail">
			<div className="crumbs">合同管理-详情</div>
			<div className="contract-wrap">
				{
					contractItem.map((item) => {
						return <div className="if-card">
							<div className="if-card-tip">
								{item.value}
							</div>
							<div className="if-card-wrap">
								{
									item.children.map((list) => {
										return  <li className="item">
											<span className="item-label" key={list.key}>
												{`${list.value}：`}
											</span>
											<span className="item-value">
												{
													list.key == 'isOtherCompanyTransfer'
													? {true: '是',false:'否'}[contractDetail[list.key]]
													: contractDetail[list.key] ? contractDetail[list.key] : '--'
												}
											</span>
										</li>
									})
								}
							</div>
						</div>
					})
				}
				
			</div>
		</div>
		)
	}
}

export default ContractDetail
