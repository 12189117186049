import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Tree,
  Icon,
  Tooltip,
  Modal,
  Table,
  message,
} from "antd";
import "./index.less";
import Api from "../../api/apis";
import { duration } from "moment";
import { map, values } from "lodash";
const { TreeNode } = Tree;

const FormItem = Form.Item;
const Option = Select.Option;

class SearchUserlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deptList: [],
      currentId: -1,
      tableData: [],
      organizationalItemS: null,
      selectedRows1: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        onChange: this.pageChange,
      },
      selectedRowKeys: [],
      selectedRows: [],
      modalVisible: false,
      checkedData: "",
      allTree: [],
      dataRightsList: [],
    };
  }

  /**
   * @method 获取右侧列表
   */
  getRightTableList = (key) => {
    this.listStaffForChoose(1, 10, key[0]);
  };

  /***
   * @method 获取下拉树
   */
  getAllTree = () => {
    this.$http
      .getTreeData({
        level: null,
        departmentId: null,
        departmentPrincipalId: null,
        type: null,
      })
      .then((res) => {
        if (res.code == 200) {
          const treeDataSource = this.getTreeData(
            res.data,
            []
          );
          console.log("treeDataSource ->", treeDataSource);
          this.setState({
            dataRightsList: treeDataSource,
          });
        }
      });
  };

  /**
   * @method 处理树数据
   */
  getTreeData = (data, treeData) => {
    treeData = map(data, (item) => {
      const {
        departmentName,
        departmentId,
        chirdren,
        level,
      } = item;
      return {
        title: departmentName,
        value: departmentId,
        key: departmentId,
        level,
        children:
          chirdren && Array.isArray(chirdren)
            ? this.getTreeData(chirdren, treeData)
            : null,
      };
    });
    return treeData;
  };

  pageChange = (page, pageSize) => {
    const { pagination } = this.state;
    pagination.current = page;
    console.log("page ->", page);
    this.setState(
      {
        pagination,
      },
      () => {
        this.listStaffForChoose(
          page,
          pageSize,
          this.props.departmentSonId || this.state.currentId
        );
      }
    );
  };
  //
  handleOk = () => {
    const { selectedRowKeys, selectedRows } = this.state;
    if (!selectedRowKeys.length) {
      message.destroy();
      message.warning("请选择员工");
      return;
    }
    console.log("------------>selectedRows", selectedRows);
    this.props.handleOk(selectedRows);
    // this.handleCancel();
    this.setState({ modalVisible: false });
  };
  componentDidMount() {
    this.getAllTree();
    if (
      this.props.organizationalItem &&
      this.props.organizationalItem.departmentPrincipalId
    ) {
      this.setState({
        selectedRows: [
          {
            staffId: this.props.organizationalItem
              .departmentPrincipalId,
            staffName: this.props.organizationalItem
              .departmentPrincipalName,
          },
        ],
      });
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { organizationalItem } = nextProps;
    if (
      organizationalItem != prevState.organizationalItemS
    ) {
      if (
        organizationalItem != null &&
        organizationalItem.departmentPrincipalId
      ) {
        return {
          organizationalItemS: organizationalItem,
          selectedRows1: [
            {
              staffId:
                organizationalItem.departmentPrincipalId,
              staffName:
                organizationalItem.departmentPrincipalName,
            },
          ],
        };
      } else {
        return {
          selectedRows1: [],
        };
      }
    } else {
      if (
        prevState.organizationalItemS != null &&
        prevState.organizationalItemS.departmentPrincipalId
      ) {
        return {
          selectedRows1: [
            {
              staffId:
                prevState.organizationalItemS
                  .departmentPrincipalId,
              staffName:
                prevState.organizationalItemS
                  .departmentPrincipalName,
            },
          ],
        };
      }
    }
    return null;
  }

  handleCancel = () => {
    const { form } = this.props;
    form.resetFields(["staffName", "postId"]);
    this.setState({
      modalVisible: false,
      selectedRowKeys: [],
      selectedRows: [],
    });
  };

  handleShow = () => {
    if (this.props.checkValid) {
      let valid = this.props.checkValid();
      if (!valid) {
        message.destroy();
        message.warning("请选择离职员工");
        return;
      }
    }
    const { pagination } = this.state;
    pagination.current = 1;
    pagination.total = 0;
    this.setState(
      {
        modalVisible: true,
        currentId: -1,
        tableData: [],
        pagination,
      },
      () => {
        console.log(
          "this.props.departmentSonId ->",
          this.props.departmentSonId
        );
        if (!this.props.departmentSonId) {
          this.listAll();
        } else {
          this.listStaffForChoose(
            1,
            10,
            this.props.departmentSonId
          );
        }
      }
    );
  };

  // 岗位下拉框
  queryPost = (open, key) => {
    if (open) {
      this.$http["selectPost"]({}).then((res) => {
        if (res.code === 200) {
          this.setState({
            [key]: res.data || [],
          });
        }
      });
    }
  };

  handleDeptList = (item) => {
    const { form } = this.props;

    // function setDepartmentId(value) {
    //     const departmentIds = []
    //     departmentIds.push(value)
    //     items.map(item => {
    //       if (item.departmentSonId === value && item.dsTree) {
    //         getChildDepartmentId(item.dsTree)
    //       } else if (item.dsTree) {
    //         mapItem(item.dsTree)
    //       }
    //     })

    //   function mapItem(item) {
    //     item.map(c_item => {
    //       if (c_item.departmentSonId === value) {
    //         if (c_item.dsTree) {
    //           getChildDepartmentId(c_item.dsTree)
    //         }
    //       } else if (item.dsTree) {
    //         mapItem(item.dsTree)
    //       }
    //     })
    //   }

    //   function getChildDepartmentId(item) {
    //     if (item.departmentSonId) {
    //       departmentIds.push(item.departmentSonId)
    //     }
    //     item.map(c_item => {
    //       departmentIds.push(c_item.departmentSonId)
    //       if (c_item.dsTree) {
    //         getChildDepartmentId(c_item.dsTree)
    //       }
    //     })
    //   }
    //   return departmentIds
    // }

    this.setState(
      {
        currentId: item.departmentSonId,
      },
      () => {
        form.resetFields(["staffName", "postId"]);
        this.listStaffForChoose(
          1,
          10,
          item.departmentSonId
        );
      }
    );
  };

  listAll = () => {
    this.$http["departmentPermissions"]({})
      .then((res) => {
        if (res.code === 200) {
          this.setState(
            {
              deptList: res.data || [],
            },
            () => {
              if (this.state.deptList.length) {
                this.setState({
                  currentId: this.state.deptList[0]
                    .departmentSonId,
                });

                this.listStaffForChoose(
                  1,
                  10,
                  this.state.deptList[0].departmentSonId
                );
              }
            }
          );
        } else {
        }
      })
      .catch((err) => {});
  };

  listStaffForChoose = (
    pageNo = 1,
    pageSize = 10,
    departmentSonId = "",
    staffName = "",
    postId = ""
  ) => {
    const { pagination } = this.state;
    const params = {
      departmentId: departmentSonId,
      staffName: staffName.trim(),
      postId: postId,
      pageNo: pageNo,
      pageSize: pageSize,
    };
    if (this.props.isProbation) {
      params.isProbation = true;
    }
    if (this.props.staffId) {
      params.staffId = this.props.staffId;
    }
    this.$http["listStaffForChoose"](params)
      .then((res) => {
        if (res.code === 200) {
          pagination.total = res.data.total || 0;
          this.setState({
            tableData: res.data.list || [],
            pagination,
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  handleReset = () => {
    const { form } = this.props;
    form.resetFields(["staffName", "postId"]);
    this.listStaffForChoose(
      1,
      10,
      this.props.departmentSonId || this.state.currentId
    );
  };

  handleQuery = () => {
    const { form } = this.props;
    const values = form.getFieldsValue();
    this.listStaffForChoose(
      1,
      10,
      this.props.departmentSonId || this.state.currentId,
      values["staffName"] || "",
      values["postId"] || ""
    );
  };

  // 树状
  renderTreeNodes = (item) => {
    return (
      <TreeNode
        key={item.departmentSonId}
        value={item.departmentSonId}
        title={item.departmentSonName}
      >
        {item.dsTree
          ? item.dsTree.map((c_item) => {
              return this.renderTreeNodes(c_item);
            })
          : ""}
      </TreeNode>
    );
  };
  select = (data) => {
    const { deptList, checkedData } = this.state;

    let DpId = "";
    getDpId(deptList);

    this.handleDeptList(DpId);

    function getDpId(item) {
      item.map((c_item) => {
        if (data[0] === c_item.departmentSonId) {
          DpId = c_item;
        } else if (c_item.dsTree) {
          getDpId(c_item.dsTree);
        }
      });
    }
  };
  render() {
    const columns = [
      {
        title: "工号",
        dataIndex: "staffCode",
        key: "staffCode",
      },
      {
        title: "姓名",
        dataIndex: "staffName",
        key: "staffName",
      },
      {
        title: "部门",
        dataIndex: "departmentName",
        key: "departmentName",
      },
      {
        title: "岗位",
        dataIndex: "postName",
        key: "postName",
      },
    ];

    const rowSelection = {
      type: "radio",
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(
        //   `selectedRowKeys: ${selectedRowKeys}`,
        //   "selectedRows: ",
        //   selectedRows
        // );
        this.setState({
          selectedRowKeys,
          selectedRows,
        });
      },
    };

    const {
      modalVisible,
      deptList,
      currentId,
      tableData,
      pagination,
      selectedRows,
      selectedRows1,
      dataRightsList,
    } = this.state;
    const {
      form,
      disabled,
      departmentSonId,
      isAllShowTree,
      onSelectAllTree,
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div className="">
        <Button
          disabled={disabled ? disabled : false}
          icon="search"
          onClick={this.handleShow}
        >
          {!selectedRows1.length
            ? "选择人员"
            : selectedRows1[0].staffName}
        </Button>
        <Modal
          visible={modalVisible}
          maskClosable={false}
          title="选择人员"
          width="880px"
          className="modal-select-staff"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              取消
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleOk}
            >
              确定
            </Button>,
          ]}
        >
          <Row className="select-staff-wrap">
            {!departmentSonId && (
              // <div className="wrap-left">
              //   {deptList.map((item) => {
              //     let str = "";
              //     if (item.departmentSonName.length > 10) {
              //       str = item.departmentSonName.substring(0, 10) + "...";
              //     } else {
              //       str = item.departmentSonName;
              //     }
              //     return (
              //       <div
              //         key={item.departmentSonId}
              //         className={
              //           currentId == item.departmentSonId
              //             ? "dept-item dept-item-active"
              //             : "dept-item"
              //         }
              //         onClick={() => this.handleDeptList(item)}
              //       >
              //         <Tooltip
              //           title={
              //             item.departmentSonName == str ? "" : item.departmentSonName
              //           }
              //         >
              //           <span>{str}</span>
              //         </Tooltip>
              //       </div>
              //     );
              //   })}
              // </div>isAllShowTree
              <div className="wrap-left">
                {isAllShowTree ? (
                  <Tree
                    treeData={dataRightsList}
                    onSelect={this.getRightTableList}
                  />
                ) : (
                  <Tree onSelect={this.select}>
                    {/* {this.renderTreeNodes(this.state.deptList)} */}
                    {(this.state.deptList || []).map(
                      (item) => {
                        return this.renderTreeNodes(item);
                      }
                    )}
                  </Tree>
                )}
              </div>
            )}
            <div className="wrap-right">
              <Form
                layout="inline"
                style={{ marginBottom: 20 }}
              >
                <FormItem label="姓名">
                  {getFieldDecorator("staffName")(
                    <Input
                      placeholder="请输入"
                      allowClear
                      style={{ width: 130 }}
                    />
                  )}
                </FormItem>
                <FormItem label="岗位">
                  {getFieldDecorator("postId")(
                    <Select
                      placeholder="请选择"
                      allowClear
                      onDropdownVisibleChange={(value) =>
                        this.queryPost(value, "position")
                      }
                      style={{ width: 130 }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                    >
                      {this.state["position"] &&
                        this.state["position"].map(
                          (item) => {
                            return (
                              <Option
                                key={item.postId}
                                value={item.postId}
                              >
                                {item.postName}
                              </Option>
                            );
                          }
                        )}
                    </Select>
                  )}
                </FormItem>
                <FormItem>
                  <Button onClick={this.handleReset}>
                    重置
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginLeft: 14 }}
                    onClick={this.handleQuery}
                  >
                    查询
                  </Button>
                </FormItem>
              </Form>
              <Table
                rowSelection={rowSelection}
                columns={columns}
                rowKey="staffId"
                dataSource={tableData}
                pagination={pagination}
                locale={{
                  emptyText: (
                    <div>
                      <img
                        style={{ width: 182, height: 94 }}
                        src={require("../../assets/image/no-data.png")}
                        alt=""
                      />
                      <p
                        style={{
                          fontSize: 14,
                          color: "#717E99",
                          lineHeight: "20px",
                        }}
                      >
                        暂无数据
                      </p>
                    </div>
                  ),
                }}
              />
            </div>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default withRouter(SearchUserlist);
