import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row, Table, Tree, Input, Tooltip } from "antd";
import { getQueryVariable } from "../../../utils/common";
import "./index.less";
import NoData from "../../../components/no-data";

const { TreeNode } = Tree;
const { Search } = Input;

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      selectedKeys: [],
      expandedKeys: [],
      departmentId: "",
      staffId: "",
      statusList: [
        {
          id: -1,
          name: "全部",
          value: -1,
        },
        {
          id: "0",
          name: "未归档",
          value: "0",
        },
        {
          id: "1",
          name: "已归档",
          value: "1",
        },
      ],
      currentStatus: -1,
      processList: [
        {
          procTemplateName: "全部",
          procTemplate: 0,
        },
      ],
      currentProcess: 0,
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        onChange: (page, pageSize) => {
          this.paginationChange(page, pageSize);
        },
      },
      tableLoading: false,
    };
  }
  componentDidMount() {
    if (sessionStorage.getItem("currentStatus") !== "") {
      this.setState({ currentStatus: sessionStorage.getItem("currentStatus") });
    }
    if (sessionStorage.getItem("currentProcess") !== "") {
      this.setState({
        currentProcess: sessionStorage.getItem("currentProcess"),
      });
    }
    if (sessionStorage.getItem("staffId")) {
      this.setState({ selectedKeys: [sessionStorage.getItem("staffId")] });
    }
    this.queryDeptDoneList();
    this.queryTaskDoneList();
    this.queryDoneProcTemplate();
  }

  // queryDoneProcTemplate
  queryDoneProcTemplate = (staffId = "", departmentId = "") => {
    const arr = [
      {
        procTemplateName: "全部",
        procTemplate: 0,
      },
    ];
    this.$http["queryDoneProcTemplate"]({
      staffId: staffId || sessionStorage.getItem("staffId"),
      departmentId: departmentId || sessionStorage.getItem("getDepartmentId"),
    })
      .then((res) => {
        if (res.code === 200) {
          this.setState({
            processList: res.data ? arr.concat(res.data) : arr,
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  paginationChange = (page, pageSize) => {
    const { pagination } = this.state;
    pagination.current = page;
    pagination.pageSize = pageSize;
    this.setState(
      {
        pagination,
      },
      () => {
        this.queryTaskDoneList(page, pageSize);
      }
    );
  };

  queryTaskDoneList = (pageNo = 1, pageSize = 10, select) => {
    this.setState({
      tableLoading: true,
    });
    const { pagination, currentStatus, currentProcess } = this.state;
    this.$http["queryTaskDoneList"]({
      departmentId:
        this.state.departmentId || sessionStorage.getItem("getDepartmentId"),
      staffId: this.state.staffId || sessionStorage.getItem("staffId"),
      procStatus:
        currentStatus === -1
          ? sessionStorage.getItem("currentStatus")
            ? sessionStorage.getItem("currentStatus")
            : ""
          : currentStatus,
      procTemplate:
        select == "select"
          ? ""
          : currentProcess
          ? currentProcess
          : sessionStorage.getItem("currentProcess") &&
            sessionStorage.getItem("currentProcess") !== "0"
          ? sessionStorage.getItem("currentProcess")
          : "",
      pageNo: pageNo,
      pageSize: pageSize,
    })
      .then((res) => {
        if (res.code === 200) {
          pagination.total = res.data.total || 0;
          this.setState({
            tableLoading: false,
            tableData: res.data.list || [],
            pagination,
          });
        } else {
          this.setState({
            tableLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          tableLoading: false,
        });
      });
  };

  queryDeptDoneList = (staffName = "") => {
    this.$http["queryDeptDoneList"]({
      staffName: staffName,
    })
      .then((res) => {
        if (res.code === 200) {
          let arr = [];
          res.data.map((j) => {
            let children = [];
            if (j.processTaskTodoList) {
              j.processTaskTodoList.map((k) => {
                children.push({
                  title: this.showTitle(
                    k.staffName || "",
                    k.postName || "",
                    k.processNum || ""
                  ),
                  key: k.staffId,
                  departmentId: j.departmentId,
                  type: "staff",
                  // isLeaf: true
                });
              });
            }
            arr.push({
              title: j.departmentName,
              key: j.departmentId,
              children: children,
              type: "dept",
            });
          });
          let expandedKeys = [];
          if (getQueryVariable("departmentId")) {
            expandedKeys = [getQueryVariable("departmentId")];
          } else {
            expandedKeys = arr[0] ? [arr[0].key] : [];
          }
          this.setState({
            treeData: arr,
            expandedKeys,
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  handleSearch = (value) => {
    this.queryDeptDoneList(value);
    console.log(value);
  };

  showTitle = (name, post, num) => {
    return (
      <div className="tree-node-wrap">
        <div className="tree-node-left">
          <span className="tree-node-value1">
            {name.length > 4 ? (
              <Tooltip title={name}>
                <span>{name.substr(0, 4) + "..."}</span>
              </Tooltip>
            ) : (
              <span>{name}</span>
            )}
          </span>
          <span className="tree-node-value2">
            {" "}
            -{" "}
            {post.length > 4 ? (
              <Tooltip title={post}>
                <span>{post.substr(0, 4) + "..."}</span>
              </Tooltip>
            ) : (
              <span>{post}</span>
            )}
          </span>
        </div>
        <div className="tree-node-right">
          <span className="tree-node-value3">{num}</span>
        </div>
      </div>
    );
  };

  onSelect = (selectedKeys, info) => {
    const { node } = info;
    let keys = [];
    let departmentId = "";
    let staffId = "";
    if (!selectedKeys.length) {
      keys = this.state.selectedKeys;
    } else {
      keys = selectedKeys;
    }
    if (node.props.dataRef.type === "dept") {
      departmentId = node.props.dataRef.key + "";
      staffId = "";
    } else if (node.props.dataRef.type === "staff") {
      departmentId = node.props.dataRef.departmentId + "";
      staffId = node.props.dataRef.key + "";
    }
    const { pagination } = this.state;
    pagination.current = 1;
    pagination.total = 0;
    this.setState(
      {
        selectedKeys: keys,
        departmentId,
        staffId,
        currentProcess: 0,
        pagination,
      },
      () => {
        if (node.props.dataRef.type === "dept") {
          // this.queryTaskDoneList();
          // this.queryDoneProcTemplate(staffId, departmentId);
        } else if (node.props.dataRef.type === "staff") {
          this.queryTaskDoneList(1, 10, "select");
          this.queryDoneProcTemplate(staffId, departmentId);
          this.queryDoneProcTemplate();
          sessionStorage.setItem("staffId", staffId);
          sessionStorage.setItem("getDepartmentId", departmentId);
        }
      }
    );
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
    });
  };

  onLoadData = (treeNode) =>
    new Promise((resolve) => {
      if (treeNode.props.children) {
        resolve();
        return;
      }
      setTimeout(() => {
        treeNode.props.dataRef.children = [
          {
            title: this.showTitle("张笑", "视觉设计师", "1"),
            key: `${treeNode.props.eventKey}-0`,
            isLeaf: true,
          },
          {
            title: this.showTitle("张笑笑", "视觉设计师", "1"),
            key: `${treeNode.props.eventKey}-1`,
            isLeaf: true,
          },
        ];
        this.setState({
          treeData: [...this.state.treeData],
        });
        resolve();
      }, 1000);
    });

  renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} dataRef={item} />;
    });

  handleLink = (record) => {
    if (record.procTemplate === "OFFER_PROC") {
      this.props.history.push(
        `/workbench/offerApproval?offerProcId=${record.businessKey}&procTemplate=${record.procTemplate}&copyToFlag=${record.copyToFlag}`
      );
    } else if (record.procTemplate === "ENTRY_PROC") {
      this.props.history.push(
        `/workbench/checkin?inductionProcId=${record.businessKey}&procTemplate=${record.procTemplate}&copyToFlag=${record.copyToFlag}`
      );
    } else if (record.procTemplate === "BECOME_PROC") {
      this.props.history.push(
        `/workbench/becomeWorker?positiveProcId=${record.businessKey}&procTemplate=${record.procTemplate}&copyToFlag=${record.copyToFlag}`
      );
    } else if (record.procTemplate === "DIMI_PROC") {
      this.props.history.push(
        `/workbench/quit?dimissionProcId=${record.businessKey}&procTemplate=${record.procTemplate}&copyToFlag=${record.copyToFlag}`
      );
    } else if (record.procTemplate === "SPON_DIMI_PROC") {
      this.props.history.push(
        `/workbench/autoQuit?dimissionProcId=${record.businessKey}&procTemplate=${record.procTemplate}&copyToFlag=${record.copyToFlag}`
      );
    } else if (record.procTemplate === "LEAVE_PROC") {
      this.props.history.push(
        `/workbench/leave?leaveProcId=${record.businessKey}&procTemplate=${record.procTemplate}&copyToFlag=${record.copyToFlag}`
      );
    } else if (record.procTemplate === "ATTE_PROC") {
      this.props.history.push(
        `/workbench/monthlyAttendance?attendanceProcId=${record.businessKey}&procTemplate=${record.procTemplate}&copyToFlag=${record.copyToFlag}`
      );
    }
  };

  handleTableByStatus = (item) => {
    const { pagination } = this.state;
    pagination.current = 1;
    this.setState(
      {
        currentStatus: item.value,
        pagination,
      },
      () => {
        sessionStorage.setItem("currentStatus", item.value);
        this.queryTaskDoneList();
      }
    );
  };

  handleTableByTemplate = (item) => {
    const { pagination } = this.state;
    pagination.current = 1;
    this.setState(
      {
        currentProcess: item.procTemplate,
        pagination,
      },
      () => {
        sessionStorage.setItem("currentProcess", item.procTemplate);
        this.queryTaskDoneList();
      }
    );
  };

  render() {
    const {
      tableData,
      pagination,
      tableLoading,
      statusList,
      currentStatus,
      processList,
      currentProcess,
      selectedKeys,
      expandedKeys,
      treeData,
    } = this.state;

    const columns = [
      {
        title: "标题",
        dataIndex: "",
        key: "",
        width: "45%",
        render: (text, record) => {
          const title = `${record.procTemplateName}-${record.entryStaff}-${
            record.departmentName
          }(单号：${record.businessKey})${record.copyToFlag ? "-抄送" : ""}`;
          return (
            <Tooltip title={title}>
              <a
                style={{
                  width: "90%",
                  display: "inline-block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                onClick={() => this.handleLink(record)}
              >
                {title}
              </a>
            </Tooltip>
          );
        },
      },
      {
        title: "流程",
        width: 120,
        dataIndex: "procTemplateName",
        key: "procTemplateName",
      },
      {
        title: "当前节点",
        width: 160,
        dataIndex: "taskDefName",
        key: "taskDefName",
        render: (text, record) => {
          return (
            <span className={`status-item item-color-${record.taskDefKey}`}>
              {text || "待批"}
            </span>
          );
        },
      },
      {
        title: "发起人",
        width: 120,
        dataIndex: "staffName",
        key: "staffName",
      },
      {
        title: "接收时间",
        width: 200,
        dataIndex: "lastUpdatedTimeStr",
        key: "lastUpdatedTimeStr",
      },
      {
        title: "处理人",
        width: 120,
        dataIndex: "assigneeName",
      },
    ];

    return (
      <div className="completedItems-main">
        <div className="completedItems-title">
          <span>已办事项</span>
        </div>
        <div className="completedItems-wrap">
          <div className="wrap-left" style={{ width: 260 }}>
            <Row className="wrap-block" style={{ padding: 10 }}>
              <Search placeholder="员工姓名" onSearch={this.handleSearch} />
              {treeData.length > 0 ? (
                <Tree
                  defaultExpandAll
                  selectedKeys={selectedKeys}
                  onSelect={this.onSelect}
                  // expandedKeys={expandedKeys}
                  onExpand={this.onExpand}
                >
                  {this.renderTreeNodes(treeData)}
                </Tree>
              ) : (
                <NoData height="200px" />
              )}
            </Row>
          </div>
          <div className="wrap-right" style={{ flex: 1 }}>
            <div className="content-top">
              <div className="top-status">
                <span className="value-label">状态：</span>
                {statusList.map((item) => {
                  return (
                    <span
                      className={
                        item.value == currentStatus
                          ? "value-text value-active"
                          : "value-text"
                      }
                      key={item.value}
                      onClick={() => this.handleTableByStatus(item)}
                    >
                      {item.name}
                    </span>
                  );
                })}
              </div>
              <div className="top-process">
                <span className="value-label">流程：</span>
                {processList.map((item) => {
                  return (
                    <span
                      className={
                        item.procTemplate == currentProcess
                          ? "value-text value-active"
                          : "value-text"
                      }
                      key={item.procTemplate}
                      onClick={() => this.handleTableByTemplate(item)}
                    >
                      {item.procTemplateName}
                      {/* {item.procTemplate
                        ? `${item.procTemplateName}(${item.procTemplateNum})`
                        : `${item.procTemplateName}`} */}
                    </span>
                  );
                })}
              </div>
            </div>
            <Table
              rowKey="id"
              pagination={pagination}
              loading={tableLoading}
              columns={columns}
              dataSource={tableData}
              locale={{
                emptyText: (
                  <div>
                    <img
                      style={{ width: 182, height: 94 }}
                      src={require("../../../assets/image/no-data.png")}
                      alt=""
                    />
                    <p
                      style={{
                        fontSize: 14,
                        color: "#717E99",
                        lineHeight: "20px",
                      }}
                    >
                      暂无数据
                    </p>
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MainManagement);
