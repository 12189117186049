/**
* @fileoverview 系统管理---账号设置 -- 搜索条件
* @author  蒋西园
*/
import React,{ Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Select, Row, Col, DatePicker } from 'antd';
import moment from 'moment';
import './index.less'

const FormItem = Form.Item;
const Option = Select.Option;


class SearchAttendancelist extends Component{
	constructor(props){
		super(props)
		this.state = {
			startValue: null,
            endValue: null,
            endOpen: false,
            postList: []
		}
	}
    componentDidMount() {
        this.listForDropDownPost();
    }
    // 查询岗位
    listForDropDownPost = () => {
        this.$http.listForDropDownPost().then(res => {
            if (res.code === 200) {
                this.setState({
                    postList: res.data
                })
            }
        })
    }
    // 提交表单
    handleSubmit = () => {
        const { startValue, endValue } = this.state;
        this.props.form.validateFieldsAndScroll((err,values)=>{
            if(err){
                return false
            }
            
            const data = {};
            // 去除含有undefined的item
            Object.keys(values).forEach(res=>{
                if (values[res]) {
                    data[res] = values[res]
                }
            })
            if(startValue) data['contractStartTime'] = moment(Date.parse(startValue)).format('YYYY-MM-DD')
            if(endValue) data['contractEndTime'] = moment(Date.parse(endValue)).format('YYYY-MM-DD')
            this.props.handleSearchBtn(data);
        })
    }
    handleReset = () => {
        this.props.form.resetFields();
        this.setState({
			startValue: null,
            endValue: null,
        })
        this.props.handleSearchBtn({});
    }
    disabledStartDate = startValue => {
        const { endValue } = this.state;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    };
    disabledEndDate = endValue => {
        const { startValue } = this.state;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };
    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };
    onStartChange = value => {
        this.onChange('startValue', value);
    };

    onEndChange = value => {
        this.onChange('endValue', value);
    };
    handleStartOpenChange = open => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };
    handleEndOpenChange = open => {
        this.setState({ endOpen: open });
    };
    onDropdownVisiblePost = (open) => {
        if (open) {
            this.listForDropDownPost();
        }
    }
	render(){
		const { getFieldDecorator } = this.props.form;
        const { startValue, endValue, endOpen, postList } = this.state;
		return (
            <div className="search-form contract">
                <Form>
                    <Row>
                        <Col style={{marginLeft:20}}>
                            <FormItem label="姓名" style={{marginLeft:20}}>
                                {
                                    getFieldDecorator('staffName',{
                                        rules: [
                                            { max: 32, message: '请输入32字以内的名字'}
                                        ],
                                    })(
                                        <Input placeholder="请输入" allowClear autoComplete="new-password" />
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col >
                            <FormItem label="合同编号" style={{marginLeft:20}}>
                                {
                                    getFieldDecorator('contractCode',{
                                        rules: [
                                            { max: 32, message: '请输入32字以内的名字'}
                                        ],
                                    })(
                                        <Input placeholder="请输入" allowClear autoComplete="new-password" />
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col className="contract-time" style={{marginLeft:20}}>
                            <FormItem label="合同到期日" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
                                {
                                    getFieldDecorator('time')(
                                       <Row gutter={24} style={{marginTop:"6px"}}>
                                            <Col span={11}>
                                                <DatePicker
                                                    disabledDate={this.disabledStartDate}
                                                    format="YYYY-MM-DD"
                                                    value={startValue}
                                                    placeholder="起"
                                                    onChange={this.onStartChange}
                                                    onOpenChange={this.handleStartOpenChange}
                                                />
                                            </Col>~<Col span={11}>
                                                <DatePicker
                                                    disabledDate={this.disabledEndDate}
                                                    format="YYYY-MM-DD"
                                                    value={endValue}
                                                    placeholder="止"
                                                    onChange={this.onEndChange}
                                                    open={endOpen}
                                                    onOpenChange={this.handleEndOpenChange}
                                                />
                                            </Col>
                                        </Row>
                                    )
                                }
                            </FormItem>
                        </Col>
                    </Row>
                    <Row  style={{marginLeft:20}}>
                        <Col style={{marginLeft:20}}>
                            <FormItem label="岗位" >
                                {
                                    getFieldDecorator('postId')(
                                        <Select 
                                            placeholder="请选择" 
                                            allowClear
                                            onDropdownVisibleChange={this.onDropdownVisiblePost}
                                            showSearch
                                            optionFilterProp="children"
                                             style={{width:193}}
                                        >
                                            {
                                                postList.map(item => {
                                                    return <Option key={item.postId} value={item.postId}>{item.postName}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col style={{marginTop:'-6px',marginLeft:20}}>
                            <Button onClick={this.handleReset}>重置</Button>
                            <Button type="primary"  onClick={this.handleSubmit} style={{ marginLeft: 14 }}>查询</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
		)
	}
}

export default  withRouter( Form.create()(SearchAttendancelist))
