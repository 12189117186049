/**
* @fileoverview 系统管理---账号设置 -- 数据列表
* @author  蒋西园
*/
import React, { Component } from 'react';
import { Table } from 'antd';
import './index.less'
import Overtime2 from "../../../../../components/overtime2"; // 加班记录
import QuitList from "../../../../../components/quitList"; // 加班记录

class tableList extends Component {
    constructor(props) {
        super(props)
        this.columns = [{
                title: '工号',
                dataIndex: 'staffCode',
                width: 120
            },{
                title: '姓名',
                dataIndex: 'staffName',
                width: 150
            },{
                title: '部门',
                dataIndex: 'departmentName',
                render: (text) => text ? text : '--'
            },{
                title: '岗位',
                dataIndex: 'jobsName',
            },{
                title: '考勤月',
                dataIndex: 'cycleMonth',
                width: 100
            },{
                title: '入职时间',
                dataIndex: 'inductionTime',
                width: 120
            },{
                title: '离职时间',
                dataIndex: 'departureTime',
                width: 120
            },{
                title: '应出勤（天）',
                dataIndex: 'monthShouldDays',
                width: 130
            },{
                title: '实际出勤（天）',
                dataIndex: 'monthDays',
                width: 130,
                render: (text) => text ? text : '--'
            },{
                title: '事假（天）',
                dataIndex: 'privateAffairLeave',
                width: 130,
                render: (text) => text ? text : '--'
            },{
                title: '病假（天）',
                dataIndex: 'sickLeave',
                width: 130,
                render: (text) => text ? text : '--'
            },{
                title: '年假（天）',
                dataIndex: 'annualLeave',
                width: 130,
                render: (text) => text ? text : '--'
            },{
                title: '婚假（天）',
                dataIndex: 'marriageLeave',
                width: 130,
                render: (text) => text ? text : '--'
            },{
                title: '产假（天）',
                dataIndex: 'maternityLeave',
                width: 130,
                render: (text) => text ? text : '--'
            },{
                title: '产检假（天）',
                dataIndex: 'prenatalLeave',
                width: 130,
                render: (text) => text ? text : '--'
            },{
                title: '陪产假（天）',
                dataIndex: 'paternityLeave',
                width: 130,
                render: (text) => text ? text : '--'
            },{
                title: '丧假（天）',
                dataIndex: 'funeralLeave',
                width: 130,
                render: (text) => text ? text : '--'
            },{
                title: '加班（h）',
                dataIndex: 'overtime',
                width: 130,
                render: (text) => text ? text : '--'
            },{
                title: '员工确认',
                dataIndex: 'employeeConfirmationDay',
                width: 120,
                render: (text) => text ? text : '--'
            },{
                title: '备注',
                dataIndex: 'remarks',
                width: 120,
                render: (text) => text ? text : '--'
            },{
                title: '操作',
                width: 200,
                render: (record) => {
                    return (
                        <div className="attendance-option"> 
                           <QuitList date={record.cycleMonth} staffId={record.staffId}/>
                           <Overtime2 date={record.cycleMonth} staffId={record.staffId}/>
                        </div>
                    )
                }
            },
        ]
    }
    
    render() {
        console.log(this.props.data)
        return (
            <Table
                rowKey={(record,index) => { return index }}
                loading={this.props.loading}
                columns={this.columns} 
                dataSource={this.props.data}
                scroll={{ x: 2720 }}
                locale={{
                    emptyText: (
                      <div>
                        <img
                          style={{ width: 182, height: 94 }}
                          src={require("../../../../../assets/image/no-data.png")}
                          alt=""
                        />
                        <p
                          style={{
                            fontSize: 14,
                            color: "#717E99",
                            lineHeight: "20px"
                          }}
                        >
                          暂无数据
                        </p>
                      </div>
                    )
                  }}
                pagination={{
                    hideOnSinglePage: true,
                    current: this.props.pageNo,
                    total: this.props.total,
                    pageSize: this.props.pageSize || 10,
                    onChange: (pageNo, pageSize) => this.props.handleChangePage(pageNo, pageSize)
                }}
                
            />
        )
    }
}
export default tableList