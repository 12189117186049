/**
* @fileoverview 系统管理---账号设置 -- 数据列表
* @author  蒋西园
*/
import React, { Component } from 'react';
import { Button, Table } from 'antd';
import './index.less'
import  history  from "../../../../../api/history"

class tableList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            taday:""    // 今天的日期
        }

        // 列表
        this.columns = [{
                title: '姓名',
                dataIndex: 'staffName',
                width: 100
            },{
                title: '手机号',
                dataIndex: 'phone',
                width: 100
            },{
                title: '入职部门',
                dataIndex: 'departmentName',
                width: 100
            },{
                title: '岗位',
                dataIndex: 'postName',
                width: 100
            },{
                title: '应入职时间',
                dataIndex: 'shouldOnonboardingTime',
                width: 100,
                render: (text,record) => {
                    return text? (<p style={{margin:0,padding:0}}>
                        <span>{text}</span>
                        {record.isExpired ?
                            (<span className="dated">过期</span>)
                            : ("")}
                    </p>):"--"
                }
            },{
                title: '实际入职时间',
                dataIndex: 'actualOnonboardingTime',
                width: 100,
                render:(text)=> text?text:"--"
            },{
                title: 'offer状态',
                dataIndex: 'offerStatus',
                width: 100,
                render: (text) => {
                    return (<div className={text==="入职中"?"offer_type1":text==="已入职"?"offer_type2":text==="未入职"?"offer_type3":"offer_type3"}>{text}</div>)
                }
            },{
                title: '操作',
                dataIndex: 'withHoldingTax',
                width: 60,
                render: (text,record) => {
                    return (
                    <Button
                        className="salary_file_edit"
                        type="link"
                        onClick={this.checkOfferApproval.bind(this,record)}
                    >查看offer
                    </Button>)
                }
            }
        ]
    }

    componentDidMount() {
        this.setState({ taday: this.getDate()})   
    }

    // 获取今天日期
    getDate = () => {
        const day = new Date()
        return `${day.getFullYear()}/${day.getMonth()+1}/${day.getDate()}`
    }

    // 查看offer
    checkOfferApproval = (record, e) => {
        console.log(this.props.searchVale);
       history.push(
           { pathname: "/workbench/offerApproval",search: `?offerProcId=${record.offerProcId}&procTemplate=OFFER_PROC`,searchVale:this.props.searchVale}
       );
    }

    render() {
        return (
        <div className="entrant_table">
            <Table
                rowKey={(record) => { return `${Math.random(10)}` }}
                loading={this.props.loading}
                columns={this.columns} 
                dataSource={this.props.data}
                locale={{
                    emptyText: (
                      <div>
                        <img
                          style={{ width: 182, height: 94 }}
                          src={require("../../../../../assets/image/no-data.png")}
                          alt=""
                        />
                        <p
                          style={{
                            fontSize: 14,
                            color: "#717E99",
                            lineHeight: "20px"
                          }}
                        >
                          暂无数据
                        </p>
                      </div>
                    )
                  }}
                pagination={{
                    hideOnSinglePage: true,
                    current: this.props.pageNo,
                    total: this.props.total,
                    pageSize: this.props.pageSize || 10,
                    onChange: (pageNo, pageSize) => this.props.handleChangePage(pageNo, pageSize)
                }}
                />
            </div>
        )
    }
}
export default tableList