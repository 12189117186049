/**
 * @fileoverview 人事管理---员工花名册---员工详情
 * @author  蒋西园
 */
import React, { Component } from 'react';
import './index.less';
import { Table, Modal, Upload } from 'antd';
const columns = [
  {
    title: '时间',
    dataIndex: 'startWorkTime',
    render: (text, record) => record.startWorkTime + '~' + record.endWorkTime,
  },
  {
    title: '所在公司',
    dataIndex: 'company',
  },
  {
    title: '工作岗位',
    dataIndex: 'job',
  },
  {
    title: '薪资',
    dataIndex: 'salary',
    render: text => (text ? '￥' + text : ''),
  },
  {
    title: '证明人',
    dataIndex: 'witness',
  },
  {
    title: '联系方式',
    dataIndex: 'contactWay',
  },
];
class StaffDeatail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false, // 预览弹框
      previewUrl: '', // 预览地址
    };
  }
  renderCard = data =>
    data.map((item, index) => {
      const html = !['uploadDocuments', 'other', 'supplement'].includes(
        item.key
      ) ? (
        <div className="if-card" key={`${item.key}${index}`}>
          <div className="if-card-tip">{item.value}</div>
          <div className="if-card-wrap">
            {item.children ? (
              this.renderItem(item.children)
            ) : item.key === 'workExperience' ? ( // 工作经验
              <Table
                className="card-table"
                rowKey={record => {
                  return `${Math.random()}`;
                }}
                columns={columns}
                dataSource={this.props.workExperience}
                pagination={false}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      ) : null;
      return html;
    });
  // 合同上传
  renderUploadContract = () => {
    const { staffDetail } = this.props;
    return (
      <div className="contract-image">
        {staffDetail.annexInfos ? (
          <Upload
            name="avatar"
            accept=".pdf,.docx"
            fileList={staffDetail.annexInfos}
            disabled
          ></Upload>
        ) : (
          // (staffDetail.annexInfos || []).map((item, index) => {
          //     return (
          //       <div
          //         className="image-item"
          //         style={{ marginRight: '20px' }}
          //         key={index}
          //       >
          //         <div className="image flex flex-align-center flex-justify-center">
          //           <img
          //             className="upload-image"
          //             src={item.annexUrl}
          //             onClick={() => this.handlePreview(item.annexUrl)}
          //             alt="upload"
          //           />
          //         </div>
          //       </div>
          //     );
          //   })
          '--'
        )}
      </div>
    );
  };

  renderItem = data =>
    data.map((list, index) => {
      const { staffDetail } = this.props;
      return (
        <li
          className={`${
            list.onlyLine
              ? 'item only-line'
              : list.inputTyle === 'upload'
              ? 'item only-line upload-line'
              : 'item'
          }  ${list.show ? 'none' : null}`}
          key={`${list.key}${index}`}
        >
          <span className="item-label">{list.value}：</span>
          <span className="item-value">
            {list.money &&
              this.renderValue(list.detailKey, staffDetail) != '--' &&
              '￥'}
            {list.key === 'uploadContract'
              ? this.renderUploadContract()
              : this.renderValue(list.detailKey, staffDetail)}
            {list.suffix &&
              this.renderValue(list.detailKey, staffDetail) != '--' && (
                <span>{list.suffix}</span>
              )}
          </span>
        </li>
      );
    });
  renderValue = (key, staffDetail) => {
    let text = '';
    switch (key) {
      case 'socialSecurityfundPayLocal': // 社保公积金缴纳地
      case 'work': // 工作城市
      case 'nativePlace': // 籍贯
        text = staffDetail[`${key}ProvinceName`]
          ? staffDetail[`${key}ProvinceName`] + staffDetail[`${key}CityName`]
          : '';
        break;
      case 'isSchoolRecruitment': // 是否校招
      case 'isProbation': // 是否试用期
      case 'isLeave': // 是否离职
      case 'isOtherCompanyTransfer': // 是否为他公司转签
        text = {
          true: '是',
          false: '否',
        }[staffDetail[key]];
        break;
      case 'turnTime1': // 转正时间
        text = staffDetail.turnTime;
        break;

      case 'staffName1': // 姓名
        text = staffDetail.staffName;
        break;
      default:
        text = staffDetail[key] ? staffDetail[key] : '--';
    }
    return text;
  };
  // 打开预览弹框
  handlePreview = url => {
    this.setState({
      previewVisible: true,
      previewUrl: url,
    });
  };
  // 取消预览弹框
  handleCancel = () => {
    this.setState({
      previewVisible: false,
    });
  };
  render() {
    const { staffItem, staffAnnexList, isActive, staffDetail } = this.props;
    const { previewVisible, previewUrl } = this.state;
    return (
      <div className="content-wrap">
        {this.renderCard(staffItem)}
        {/* 附件 */}
        {isActive == 1 && (
          <div className="if-card">
            <div className="if-card-tip">附件（{staffAnnexList.length}）</div>
            <div className="if-card-wrap">
              {staffAnnexList.map((item, index) => {
                return (
                  <div className="image-item">
                    <div className="image flex flex-align-center flex-justify-center">
                      <img
                        className="upload-image"
                        src={item.annexUrl}
                        onClick={() => this.handlePreview(item.annexUrl)}
                        alt="upload"
                      />
                    </div>
                    <p className="image-name">
                      {item.annexType == 1
                        ? '毕业证'
                        : item.annexType == 2
                        ? '学位证'
                        : item.annexType == 3
                        ? '身份证正面'
                        : item.annexType == 4
                        ? '身份证反面'
                        : item.annexType == 5
                        ? '其他附件'
                        : ''}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {isActive == 1 && (
          <div className="if-card">
            <div className="if-card-tip">补充说明</div>
            <div class="p-x">
              <p>有否传染病或慢性病史，请具体说明:</p>
              <span>{staffDetail.isDisease === 0 ? '是' : '否'}</span>
              {staffDetail.isDisease === 0 && <p>{staffDetail.diseaseDesc}</p>}
              <p>
                有否接受过行政或者刑事处分，或与原公司存在劳动/财务纠纷，或因违章违纪遭辞退/开除等情况请具体说明:
              </p>
              <span>{staffDetail.isWarning === 0 ? '是' : '否'}</span>
              {staffDetail.isWarning === 0 && <p>{staffDetail.warningDesc}</p>}
            </div>
          </div>
        )}
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img
            style={{ width: '100%', minHeight: 200 }}
            src={previewUrl}
            alt="example"
          />
        </Modal>
      </div>
    );
  }
}
export default StaffDeatail;
